import React from 'react';
import { connect } from "react-redux";
import { left, select } from "../../../api/actions";

function mapStateToProps(state) {
    return { 
        unit: state.unit
    }
}

function mapDispatchToProps(dispatch) {
    return {
        select: record => dispatch(select(record)),
        left: currentLeft => dispatch(left(currentLeft))
    }
}

class Analytics extends React.PureComponent {

    componentDidMount() {
        this.props.select(null)
        this.props.left("analytics")
    }

    render() {

        const { location, config: { BUILD, HTTPS, HTTPPORT, SERVER, DEBUG, ANALYTICS } } = window
        const { unit: { proxyKey} } = this.props

        const protocol = BUILD ? (location.protocol + "//") : (HTTPS ? "https://" : "http://");
        const server = BUILD ? location.hostname : SERVER;
        const port = BUILD ? (location.port ? (":" + location.port) : "") : (HTTPPORT ? (":" + HTTPPORT) : "");
        const path = proxyKey ? ("/unit/file/" + proxyKey) : "";
        const analytics = ANALYTICS ?? "/analytics";

        const analyticsConfig = proxyKey ? protocol + server + port + path + analytics : analytics;

        DEBUG && console.log("analyticsConfig", analyticsConfig)

        return (
            <div style={{
                height: "100%",
                width: "100%",
                overflow: "hidden"
            }}>
                <iframe
                    src={analyticsConfig}
                    style={{ 
                        width: '100%', 
                        height: '100%',
                        border: "none"
                    }}
                    title="Analytics"
                  ></iframe>
            </div>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Analytics);