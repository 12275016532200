import React from 'react';
import Status from '../../Status';
import Highlighter from 'react-highlight-words';

import { Table, Button, Icon, BackTop, Input, Tag, Divider, Popconfirm } from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ALARMFORM, ALARMS } from '../../../constants/routes';
import { select, left } from "../../../api/actions";

// dispatch actions select and left to Redux store to set record and currentLeft
function mapDispatchToProps(dispatch) {
    return {
        select: record => dispatch(select(record)),
        left: currentLeft => dispatch(left(currentLeft))
    };
}

// map socket state from Redux store to props
function mapStateToProps(state) {
    return { 
        processMessage: state.processMessage,
        sortMessage: state.sortMessage
    };
}

class Alarms extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            error: null,
            isLoaded: false,
            searchText: "",
            searchedColumn: ""
        };

        this.show = this.show.bind(this);
    }

    componentDidMount() {
        this.props.select(null)
        this.props.left("alarms"); // set initials in redux store
        this.setState({ isLoaded: false }, () => this.show()); // show devices table
    }

    show() {
        this.props.processMessage([
            { 
                request: "devicesnosecrets",
                method: "get",
                params: [{ id: 0 }]
            },
            { 
                request: "events",
                method: "get",
                params: [{ id: 0 }]
            },
            { 
                request: "simpleconditions",
                method: "get",
                params: [{ id: 0 }]
            }
        ])
        .then(res => {
            const data = [];
            const devices = res["devicesnosecrets"].params;
            const events = res["events"].params;
            const conditions = res["simpleconditions"].params;
            const nvr = devices.filter(device => !device.type); // device with type 0 is NVR
            const digitalInputs = nvr[0].digital_inputs;

            digitalInputs.forEach(digitalInput => {

                const eventId = {};
                const actions = {};
                const conditionId = {};
                const conditionState = {};
                const conditionDuration = {};
                const digitalInputConditions = conditions.filter(condition => condition.name.includes("nvrdi" + digitalInput + "_"));

                digitalInputConditions.forEach(digitalInputCondition => {
                    conditionId[digitalInput] = digitalInputCondition.id;
                    conditionState[digitalInput] = digitalInputCondition.params.state;
                    conditionDuration[digitalInput] = digitalInputCondition.params.duration;
                });

                const digitalInputEvents = events.filter(event => event.conditions.some(condition => condition.includes("nvrdi" + digitalInput + "_" + conditionState[digitalInput])));

                digitalInputEvents.forEach(digitalInputEvent => {
                    if (digitalInputEvent.actions.length) {
                        eventId[digitalInput] = digitalInputEvent.id;
                        actions[digitalInput] = digitalInputEvent.actions;
                    }
                });

                data.push({
                    eventId: eventId[digitalInput],
                    port: Number(digitalInput),
                    actions: actions[digitalInput],
                    nvrId: nvr[0].id,
                    conditionId: conditionId[digitalInput],
                    state: conditionState[digitalInput],
                    duration: conditionDuration[digitalInput]
                })
            });

            this.setState({ data: data.sort((a,b) => a.port - b.port) });
        })
        .catch(error => {
            console.error(error);
            this.setState({ error });
        })
        .finally(() => this.setState({ isLoaded: true }));
    }

    edit(record) {
        this.props.select(record); // set record object in redux store by selected row
    }

    delete(record) {
        this.setState({ isLoaded: false}, () => {
            this.props.processMessage([
                {
                    request: "simpleconditions",
                    method: "del",
                    params: [{ id: record.conditionId }]
                },
                {
                    request: "events",
                    method: "del",
                    params: [{ id: record.eventId }]
                }
            ])
            .then(() => this.show())
            .catch(error => {
                console.error(error);
                this.setState({ 
                    isLoaded: true,
                    error 
                });
            })
        })
    }

    getColumnSearchProps = dataIndex => ({
        
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => { this.searchInput = node; }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />

                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>

                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),

        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#EE3350' : undefined }} />
        ),

        onFilter: (value, record) => {
            if (record[dataIndex])
                return record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
        },

        onFilterDropdownVisibleChange: visible => {
            if (visible) setTimeout(() => this.searchInput.select());
        },

        render: (text, record) => {
            if (text) { 
                if (this.state.searchedColumn === dataIndex) { 
                    text = text.toString();
                    if (dataIndex === "state") {
                        text = text.charAt(0).toUpperCase() + text.slice(1);
                    }
                    return <Highlighter
                            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                            searchWords={[this.state.searchText]}
                            autoEscape
                            textToHighlight={text}
                        />
                 } else {
                    if (dataIndex === "duration")
                        text = text + "s";
                    if (dataIndex === "state")
                        text = text.charAt(0).toUpperCase() + text.slice(1);
                    return text;
                }
            } else if (dataIndex === "port") { 
                return 0
            } else if (dataIndex === "duration" && record?.state && record?.actions?.length) {
                return "0s"
            } else {
                return "-"
            }
        }
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    render() {

        const { error, data, isLoaded } = this.state;

        // show table when isLoaded is true or show status when error
        if (!error) {
            return <>
                <Table
                    style={{ height: data.length ? "100%" : "unset" }}
                    rowKey={record => record.port} 
                    dataSource={data}
                    loading={!isLoaded}
                    pagination={false}
                    size="small"
                    scroll={{ x: 'max-content' }}
                    columns={[
                        {
                            title: 'Port',
                            dataIndex: 'port',
                            sorter: (a, b) => this.props.sortMessage({ a, b, value: "port", numeric: true }),
                            ...this.getColumnSearchProps('port')
                        },
                        {
                            title: 'State',
                            dataIndex: 'state',
                            sorter: (a, b) => this.props.sortMessage({ a, b, value: "state" }),
                            ...this.getColumnSearchProps('state')
                        },
                        {
                            title: 'Duration',
                            dataIndex: 'duration',
                            sorter: (a, b) => this.props.sortMessage({ a, b, value: "duration", numeric: true }),
                            ...this.getColumnSearchProps('duration')
                        },
                        {
                            title: 'Actions',
                            dataIndex: 'actions',
                            render: actions => actions ? actions.map(action => <Tag key={action}>{action}</Tag>) : "-"
                        },
                        {
                            title: 'Controls',
                            render: record => <>
                                <Link to={ALARMFORM}>
                                    <Button 
                                        type="link" 
                                        onClick={this.edit.bind(this, record)}
                                        disabled={!isLoaded}
                                    >
                                        <Icon type="edit" />Edit
                                    </Button>
                                </Link>

                                <Divider type="vertical" />

                                <Popconfirm 
                                    title="Are you sure?"
                                    okText='Yes'
                                    okType='danger'
                                    cancelText='No'
                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                    onConfirm={this.delete.bind(this, record)}
                                >
                                    <Button 
                                        type="link"
                                        disabled={!isLoaded || !record.eventId || !record.conditionId}
                                    >
                                        <Icon type="delete" />Clear
                                    </Button>
                                </Popconfirm>
                            </>
                        }
                    ]}
                />

                <BackTop
                    style={{
                        right: "5px",
                        bottom: "unset",
                        top: "52px",
                    }}
                />
            </>
        } else {
            return <Status is={error} back={ALARMS} />
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Alarms);