import 'ant-design-pro/dist/ant-design-pro.min.css';
import React from 'react';
import { Empty } from 'antd';
import { NoticeIcon } from "ant-design-pro";

class NotificationIcon extends React.PureComponent {

   // prevents to click NoticeIcon before user logged in
    preventClick(e) {
        e.stopPropagation();
    }

    // show notice icon, disable when not logged in
    render() {

        const { data, disabled, onClick, onClear } = this.props

        return (          
            <span onClickCapture={disabled ? this.preventClick : null}>

                <NoticeIcon
                    className={disabled ? "notice-icon-disabled" : "notice-icon"} 
                    count={disabled ? 0 : data.length} 
                    onItemClick={onClick} 
                    onClear={onClear}
                >
                    <NoticeIcon.Tab
                        list={data}
                        title="Notifications"
                        emptyText={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    />

                </NoticeIcon>

            </span>
        )
    }
}

export default NotificationIcon;