import React from 'react';
import Status from '../../Status';
import ConditionlistDynamic from "./ConditionlistDynamic";
import ConfirmCancelButtons from '../../controls/ConfirmCancelButtons';
import { Form, Select, Input, Radio, Divider } from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { EVENTS } from '../../../constants/routes';
import { left, select } from "../../../api/actions";
import { NAME, NAME_MSG } from '../../../constants/regex';

function hasErrors(fieldsError, conditionError) {
    if (conditionError) fieldsError.condition = undefined;
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

function mapDispatchToProps(dispatch) {
    return {
        select: record => dispatch(select(record)),
        left: currentLeft => dispatch(left(currentLeft))
    };
}

function mapStateToProps(state) {
    return { 
        record: state.record,
        processMessage: state.processMessage
    };
}

class EventForm extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            conditions: [],
            actions: [],
            error: null,
            isLoaded: false,

            state: 1,
            condition: null,
            action: null,
        };

        this.show = this.show.bind(this);
        this.debug = window.config.DEBUG
    }

    stateChange = value => this.setState({ state: value.target.value });
    actionChange = value => this.setState({ action: value });
    nameChange = value => this.setState({ name: value.target.value });

    componentDidMount() {
        this.props.left("events"); // set initials in redux store
        this.props.form.validateFields(); // disable submit button at the beginning
        this.setState({ isLoaded: false }, () => this.show()); // show record entries
    }

    show() {

        this.props.processMessage([
            {
                request: "simpleconditions",
                method: "get",
                params: [{ id: 0 }] 
            },
            {
                request: "simpleactions",
                method: "get",
                params: [{ id: 0 }]
            }
        ])
        .then(res => { 

            const simpleconditions = res["simpleconditions"].params;
            const conditionsWithoutDi = simpleconditions.filter(condition => !condition.name.includes("nvrdi"));
            const simpleactions = res["simpleactions"].params;

            for (let i=0; i<conditionsWithoutDi.length; i++)
                this.state.conditions.push(<Select.Option key={conditionsWithoutDi[i].id} value={conditionsWithoutDi[i].name}>{conditionsWithoutDi[i].name}</Select.Option>);

            for (let i=0; i<simpleactions.length; i++) 
                this.state.actions.push(<Select.Option key={simpleactions[i].id} value={simpleactions[i].name}>{simpleactions[i].name}</Select.Option>);

            if (this.props.record) {

                // set states and field values for selected record
                this.setState({ 
                    state: this.props.record.state,
                    name: this.props.record.name,
                    condition: this.props.record.conditions,
                    action: this.props.record.actions,
                });
    
                this.props.form.setFieldsValue({ 
                    state: this.props.record.state,
                    name: this.props.record.name,
                    condition: this.props.record.conditions,
                    action: this.props.record.actions
                });
            }

        })
        .catch(error => {
            console.error(error);
            this.setState({ error });
        })
        .finally(() => this.setState({ isLoaded: true }));
    }    

    confirm() {
        
        this.props.form.validateFields((error, values) => {

            if (!error) {

                var tmp=[];
                let j=0;

                for (let i=0; i<values.condition.length; i++) {
                    if (values.condition[i]) {
                        tmp[j] = values.condition[i];
                        j++;
                    }
                }

                const params = [{   
                    id: this.props.record ? this.props.record.id : 0, // id of edited record or 0 for new record
                    state: this.state.state ? this.state.state : 0,
                    name: this.state.name ? this.state.name : "",
                    conditions: tmp ? tmp : null,
                    actions: this.state.action ? this.state.action : null,
                }]

                this.debug && console.log("events set", params);

                this.setState({ isLoaded: false }, () => {
                    this.props.processMessage([{
                        request: "events",
                        method: "set",
                        params
                    }])
                    .then(() => { 
                        this.props.history.push(EVENTS); // push Devices path to history props to call Devices
                        this.props.select(null); // reset record props in redux store
                    })
                    .catch(error => {
                        console.error(error);
                        this.setState({ error });
                    })
                });

            } else {
                console.error(error);
                this.setState({ error });
            }
        });
    }

    cancel() {
        this.props.history.push(EVENTS); // link to Events with Router history
    }

    componentWillUnmount() {
        this.props.select(null); // reset record props in redux store
    }

    render() {
        const { error, state, isLoaded, conditions, actions, name } = this.state;
        const { getFieldDecorator, getFieldError, getFieldsError, isFieldTouched, getFieldValue } = this.props.form;

        // only show error after a field is touched
        const actionError = isFieldTouched('action') && getFieldError('action');
        var conditionError = getFieldValue('condition[0]');

        if (conditionError && conditionError[0] && !conditionError[0].length) conditionError = true;
        
        // set form layout, show form when isLoaded is true, set conditions[] as select options in ConditionlistDynamic, call confirm() on submit or show status when error
        if (!error) {
            return <>
                <Form labelCol={{ xs: { span: 24 }, sm: { span: 5 } }} wrapperCol={{ xs: { span: 24 }, sm: { span: 15 } }} style={{ margin: "1.5em" }}>

                    <Form.Item label="State" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('state', {
                            onChange: this.stateChange,
                            initialValue: state
                        })(
                            <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded}>
                                <Radio.Button value={1} style={{ width: "50%", textAlign: "center" }}>Active</Radio.Button>
                                <Radio.Button value={0} style={{ width: "50%", textAlign: "center" }}>Inactive</Radio.Button>
                            </Radio.Group>
                        )}
                    </Form.Item>

                    <Form.Item label="Name" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('name', {
                            onChange: this.nameChange,
                            initialValue: name,
                            rules: [{ pattern: NAME, message: NAME_MSG }]
                        })(
                            <Input placeholder="Please enter an event name" disabled={!isLoaded} />           
                        )}
                    </Form.Item>

                    <Divider style={{ margin: "4px 0" }} />

                    <Form.Item label={<span className="ant-form-item-required">Conditions</span>} style={{ marginBottom: 0 }}>
                        <ConditionlistDynamic 
                            {...this.props.form} 
                            name="condition"
                            fields={{ 
                                name: "condition",
                                validation: {
                                    rules: [{ required: true, message: "Please select a condition!" }]
                                },
                                field: () =>
                                    <Select mode="multiple" placeholder="Please select a condition" disabled={!isLoaded}>
                                        {conditions}
                                    </Select>    
                            }}
                        />
                    </Form.Item>

                    <Divider style={{ margin: "4px 0" }} />

                    <Form.Item label="Actions" style={{ marginBottom: 0 }} validateStatus={actionError ? 'error' : ''} help={actionError || ''}>
                        {getFieldDecorator('action', {
                            rules: [{ required: true, message: "Please select an action!" }],
                            onChange: this.actionChange
                        })(
                            <Select placeholder="Please select an action" mode="multiple" disabled={!isLoaded}>
                                {actions}
                            </Select>
                        )}
                    </Form.Item>

                </Form>

                <ConfirmCancelButtons 
                    loading={!isLoaded} 
                    disabled={hasErrors(getFieldsError(),conditionError)}
                    onConfirm={this.confirm.bind(this)} 
                    onCancel={this.cancel.bind(this)}
                />
            </>
        } else {
            return <Status is={error} back={EVENTS} />
        }
    }
}

export default Form.create() (connect(mapStateToProps,mapDispatchToProps)(withRouter(EventForm)));