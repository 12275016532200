import React from 'react';
import Status from '../../Status';
import Highlighter from 'react-highlight-words';
import ByteConverter from '@wtfcode/byte-converter'
import AddButton from '../../controls/AddButton';
import { Table, Button, Icon, Divider, Popconfirm, BackTop, Input, Badge } from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { DEVICEFORM, DEVICES, DEVICEACCESS } from '../../../constants/routes';
import { select, left } from "../../../api/actions";
import { stringToHex } from '../../Utilities';
import { withRouter } from "react-router-dom";

const byteConverter = new ByteConverter();

function mapDispatchToProps(dispatch) {
    return {
        select: record => dispatch(select(record)),
        left: currentLeft => dispatch(left(currentLeft))
    };
}

function mapStateToProps(state) {
    return { 
        unit: state.unit,
        processMessage: state.processMessage,
        sortMessage: state.sortMessage
    };
}

class Devices extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            error: null,
            isLoaded: false,
            searchText: "",
            searchedColumn: ""
        };

        this.show = this.show.bind(this);
        this.isRemote = this.props.unit.proxyKey
        this.debug = window.config.DEBUG
        this.deviceAccessViaIframe = true // cam head access via iframe instead new window
    }

    componentDidMount() {
        this.props.select(null)
        this.props.left("devices")
        this.show()
    }
    
    show() {
        this.setState({ isLoaded: false }, () => { 
            this.props.processMessage([
                { 
                    request: "devices",
                    method: "get",
                    params: [{ id: 0 }]
                }
            ])
            .then(res => {
                
                const devices = res["devices"].params;

                for (let i=0; i<devices.length; i++) {

                    const reservedAutoScaled = byteConverter.autoScale((devices[i].reserved*8), 'b') // byte to bit
                    const reservedAutoScaledFormat = reservedAutoScaled.dataFormat.replace('b', 'B') // bit to byte
                    const reservedConvertedRounded = Math.round(((byteConverter.convert(reservedAutoScaled.value, reservedAutoScaled.dataFormat, reservedAutoScaledFormat)) + Number.EPSILON) * 100) / 100;
                    const availableConvertedRounded = Math.round(((byteConverter.convert(devices[i].available, 'B', reservedAutoScaledFormat)) + Number.EPSILON) * 100) / 100;
                    
                    var usedConvertedRounded = 0;

                    if (devices[i].reserved) {
                        usedConvertedRounded = Math.round(((byteConverter.convert(devices[i].used, 'B', reservedAutoScaledFormat)) + Number.EPSILON) * 100) / 100;
                        devices[i].format = reservedAutoScaledFormat;
                    } else {
                        const usedAutoScaled = byteConverter.autoScale((devices[i].used*8), 'b'); 
                        const usedAutoScaledFormat = usedAutoScaled.dataFormat.replace('b', 'B')
                        
                        usedConvertedRounded = Math.round(((byteConverter.convert(usedAutoScaled.value, usedAutoScaled.dataFormat, usedAutoScaledFormat)) + Number.EPSILON) * 100) / 100;
                        devices[i].format = usedAutoScaledFormat;
                    }

                    devices[i].reservedConverted = reservedConvertedRounded;
                    devices[i].usedConverted = usedConvertedRounded;
                    devices[i].availableConverted = availableConvertedRounded;
                }

                this.setState({ data: devices.filter(elem => elem.type)}) // filter NVR itself by type 0
            })
            .catch(error => {
                console.error(error);
                this.setState({ error });
            })
            .finally(() => this.setState({ isLoaded: true }));
        });
    }

    edit(record) {
        this.props.select(record);
    }

    delete(id) {

        this.setState({ isLoaded: false }, () => {
            this.props.processMessage([{ 
                request: "devices",
                method: "del",
                params: [{ id: id }]
            }])
            .then(() => this.show())
            .catch(error => {
                console.error(error);
                this.setState({ 
                    error,
                    isLoaded: true
                });
            })
        });
    }

    access(record) {

        const { location: { hostname, host }, config: { BUILD, SERVER, REVERSEPROXYPROTOCOL } } = window
        const { history, select, processMessage } = this.props

        var req = [{ 
            request: "forward_device",
            method: "get",
            params: [{ id: record.id }]
        }]

        if (this.isRemote) {
            req.push({
                request: "liveparams",
                method: "get",
                params: []
            })
        }
        
        this.setState({ isLoaded: false }, () => {
            processMessage(req)
            .then(res => {
                const forwardDevice = res["forward_device"].params[0]
                const protocol = forwardDevice.protocol
                const server = BUILD ? hostname : SERVER
                let destinationPath = null

                if (this.isRemote) {
                    const vpnAddress = res["liveparams"] && JSON.parse(res["liveparams"].params[0])?.vpn?.ipv4
                    
                    if (vpnAddress) {
                        const vpnPath = `${protocol}://${vpnAddress}/chf-${record.id}`
                        const vpnPathHex = stringToHex(vpnPath)
                        const vpnPathBase64 = Buffer.from(vpnPathHex).toString('base64')
                        const remoteProtocol = REVERSEPROXYPROTOCOL ?? "https"
                        const remoteAddress = host;

                        destinationPath = `${remoteProtocol}://${remoteAddress}/camhead/${vpnPathBase64}/`

                        this.debug && console.log(`[Remote] Forwarding from ${vpnPath} to ${destinationPath}`)
                    } else {
                        const msg = "VPN address not found"
                        console.error(`[Remote] Forwarding not possible - ${msg}`)
                        this.setState({
                            isLoaded: true,
                            error: msg
                        })
                        return
                    }
                } else {
                    destinationPath = `${protocol}://${server}/chf-${record.id}`
                    this.debug && console.log(`[Local] Forwarding from ${record.address} to ${destinationPath}`)
                }

                if (this.deviceAccessViaIframe) {
                    select({ src: destinationPath, title: record.name })
                    history.push(DEVICEACCESS)
                } else {
                    window.open(destinationPath, record.name, "noopener noreferrer")
                    this.setState({ isLoaded: true })
                }
            })
            .catch(error => {
                console.error(error)
                this.setState({ error })
            })
        })
    }

    getColumnSearchProps = dataIndex => ({
        
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => { this.searchInput = node; }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />

                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>

                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),

        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#EE3350' : undefined }} />
        ),

        onFilter: (value, record) => {
            if (record[dataIndex])
                return record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
        },

        onFilterDropdownVisibleChange: visible => {
            if (visible) setTimeout(() => this.searchInput.select());
        },

        render: (text, record) => {
            if (text) { 
                if (this.state.searchedColumn === dataIndex && this.state.searchedColumn !== "name") {
                    return (
                        <Highlighter
                            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                            searchWords={[this.state.searchText]}
                            autoEscape
                            textToHighlight={text.toString()}
                        />
                    )
                } else {
                    if (dataIndex === "name") {
                        return (
                            <Button 
                                type="link" 
                                onClick={this.access.bind(this, record)}
                            >
                                {text}
                            </Button>
                        )
                    } else {
                        return text;
                    }
                }
            } else { 
                return "-" 
            }
        }
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    render() {

        const { error, data, isLoaded } = this.state

        if (error) {
            return <Status is={error} back={DEVICES} />
        }

        return (<>
            <Table 
                style={{ height: data.length ? "100%" : "unset" }}
                rowKey={record => record.id} 
                dataSource={data}
                loading={!isLoaded}
                pagination={false}
                size="small"
                scroll={{ x: 'max-content' }}
                columns={[
                    {
                        title: 'State',
                        dataIndex: 'state',
                        sorter: (a, b) => this.props.sortMessage({ a, b, value: "state", numeric: true }),
                        render: state => <Badge status={state ? "success" : "error"} key={state ? "Active" : "Inactive"} text={state ? "Active" : "Inactive"} />
                    },
                    {
                        title: 'Stream',
                        dataIndex: 'stream_id',
                        sorter: (a, b) => this.props.sortMessage({ a, b, value: "stream_id", numeric: true }),
                        ...this.getColumnSearchProps('stream_id')
                    },
                    {
                        title: 'Name',
                        dataIndex: 'name',
                        sorter: (a, b) => this.props.sortMessage({ a, b, value: "name" }),
                        ...this.getColumnSearchProps('name')
                    },
                    {
                        title: 'Address',
                        dataIndex: 'address',
                        sorter: (a, b) => this.props.sortMessage({ a, b, value: "address" }),
                        ...this.getColumnSearchProps('address')
                    },
                    {
                        title: 'Brand',
                        dataIndex: 'brand',
                        sorter: (a, b) => this.props.sortMessage({ a, b, value: "brand" }),
                        ...this.getColumnSearchProps('brand')
                    },
                    {
                        title: 'Model',
                        dataIndex: 'model',
                        sorter: (a, b) => this.props.sortMessage({ a, b, value: "model" }),
                        ...this.getColumnSearchProps('model')
                    },
                    {
                        title: "Used space",
                        dataIndex: "usedConverted",
                        render: (elem, item) => (elem && item.format) || (item.reservedConverted && item.format) ? elem + item.format : "-"
                    },
                    {
                        title: "Reserved space",
                        dataIndex: "reservedConverted",
                        render: (elem, item) => elem && item.format ? elem + item.format : "-"
                    },
                    {
                        title: 'Controls',
                        render: record => <>
                            <Link to={DEVICEFORM}>
                                <Button type="link" onClick={this.edit.bind(this, record)}>
                                    <Icon type="edit" />Edit
                                </Button>
                            </Link>

                            <Divider type="vertical" />

                            <Popconfirm 
                                title="Are you sure?"
                                okText='Yes'
                                okType='danger'
                                cancelText='No'
                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                onConfirm={this.delete.bind(this, record.id)}
                            >
                                <Button type="link">
                                    <Icon type="delete" />Delete
                                </Button>
                            </Popconfirm>
                        </>,
                    },
                ]}
            />

            <AddButton 
                linkTo={DEVICEFORM} 
                loading={!isLoaded}
            />

            <BackTop 
                style={{
                    right: "5px",
                    bottom: "unset",
                    top: "52px",
                }}
            />
        </>)
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Devices));
