import './index.css';
import React from 'react';
import ReactDOM from 'react-dom'; // native instead of dom for apps like android
import Store from "./api/store";
import Wrapper from './components/Wrapper';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";

class App extends React.PureComponent {
    render() {
        return (
            // <React.StrictMode>
                <HashRouter basename={window.location.pathname}>
                    <Provider store={Store}>
                        <Wrapper />
                    </Provider>
                </HashRouter>
            // </React.StrictMode>
        )
    }
}

// renders React element into the DOM in the supplied container
ReactDOM.render(<App />, document.getElementById('root'));

// change to register before build: https://facebook.github.io/create-react-app/docs/making-a-progressive-web-app
// read before build: https://reactjs.org/docs/optimizing-performance.html#use-the-production-build
serviceWorker.unregister();