import React, { createRef } from 'react';
import Status from './Status';
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Icon, Input, Button, Layout } from 'antd';
import { LIVE, LOGIN } from '../constants/routes';
import { NAME, NAME_MSG } from '../constants/regex';
import { setLoggedOut, top, left, select } from "../api/actions";
import { setCookie } from './Utilities';

function mapDispatchToProps(dispatch) {
    return {
        setLoggedOut: loggedOut => dispatch(setLoggedOut(loggedOut)),
        left: currentLeft => dispatch(left(currentLeft)),
        top: currentTop => dispatch(top(currentTop)),
        select: record => dispatch(select(record))
    };
}

function mapStateToProps(state) {
    return {
        processMessage: state.processMessage,
        access: state.access
    };
}

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Login extends React.PureComponent {

    constructor(props) {

        super(props)

        this.state = {
            error: null,
            isLoadedLogin: true,
            isLoadedChangePassword: true,
            name: "",
            password: "",
            recaptcha: "none",
            requestNewPassword: false
        }

        this.recaptchaRef = createRef()
        this.passwordRef = createRef()
        this.debug = window.config.DEBUG
    }

    componentDidMount() {
        this.props.select(null)
        this.props.top("login")
        this.props.left(null)
        this.props.form.validateFields() // disable submit button at the beginning
    }

    handleConfirmLogin() {
        this.setState({ isLoadedLogin: false }, () => {
            this.props.processMessage([{
                request: "login",
                method: "get",
                params: [{   
                    id: 0,
                    name: this.state.name || null,
                    password: this.state.password || null,
                    recaptcha: this.state.recaptcha || null,
                    timestamp: Date.now()
                }]
            }])
            .then(res => {
                const login = res["login"]
                const token = login.params[0]
                const requestNewPassword = login.params[1]

                this.props.setLoggedOut(false) // reset manual logout

                setCookie("__session", token)

                if (requestNewPassword) {
                    this.changePassword(true)
                } else {
                    this.changePassword(false)
                }  
            })
            .catch(error => {
                console.error(error)
                this.setState({ 
                    error,
                    isLoadedLogin: true
                })
            })
        })
    }

    handleConfirmPasswordChange() {
        this.setState({ isLoadedChangePassword: false }, () => {
            this.props.processMessage([{
                request: "users",
                method: "set",
                params: [{
                    name: this.state.name || null,
                    password: this.state.password || null,
                    role: this.props.access || "administrator"
                }]
            }])
            .then(() => {
                this.changePassword(false)
                this.props.history.push(LIVE)
            })
            .catch(error => {
                console.error(error)
                this.setState({
                    error,
                    isLoadedChangePassword: true
                })
            })
        })
    }

    changePassword(requestNewPassword = false) {
        if (requestNewPassword) {
            window.localStorage.setItem('requestNewPassword', true)
            this.setState({ 
                password: "",
                requestNewPassword: true,
                isLoadedLogin: true
            })
            this.props.form.setFieldsValue({ 
                password: ""
            })
            this.props.form.validateFields()
        } else {
            window.localStorage.removeItem('requestNewPassword')
            this.setState({ requestNewPassword: false })
        }
    }

    compareToFirstPassword = (rule, value, callback) => {
        if (value && value !== this.props.form.getFieldValue('password')) {
            callback('The entered password does not match!')
        } else {
            callback()
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        if (value && this.state.confirmDirty) {
            this.props.form.validateFields(['confirm'], { force: true })
        }
        callback()
    }

    handleConfirmBlur = e => {
        this.setState({ confirmDirty: this.state.confirmDirty || !!e.target.value })
    }

    componentWillUnmount() {
        this.recaptchaRef.current?.reset()
    }

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form
        const { error, name, password, recaptcha, isLoadedLogin, requestNewPassword, isLoadedChangePassword } = this.state

        // only show error after a field is touched
        const usernameError = isFieldTouched('username') && getFieldError('username')
        const passwordError = isFieldTouched('password') && getFieldError('password')
        const confirmError = isFieldTouched('confirm') && getFieldError('confirm')
        const recaptchaSitekey = window.config.RECAPTCHA_SITEKEY
        const disableSubmit = recaptchaSitekey ? (!name || !password || !recaptcha) : hasErrors(getFieldsError())

        return (
            <Layout>
                <Layout.Content style={{ 
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center", 
                    height: "100%",
                    marginTop: "48px"
                }}>
                    {!error ?
                    <Form>

                        <Form.Item style={{ 
                            marginBottom: 16,
                            display: "flex",
                            justifyContent: "center",
                            fontWeight: 200,
                            fontSize: "33.33px",
                            lineHeight: 1.35,
                            color: "rgba(0, 0, 0, 0.65)",
                            userSelect: "none",
                            textAlign: "center"
                        }}>
                            {requestNewPassword ? "PASSWORD CHANGE" : "LOGIN"}
                        </Form.Item>

                        <Form.Item 
                            style={{ marginBottom: 0, width: 302 }} 
                            validateStatus={usernameError ? 'error' : ''}
                            help={usernameError || ''}
                            onKeyDown={
                                e => e.key === "Enter" && 
                                !usernameError && 
                                this.passwordRef.current.focus()
                            }
                        >
                            {getFieldDecorator('username', {
                                rules: [{ required: true, message: 'Please enter your name!' }, { pattern: NAME, message: NAME_MSG }],
                                onChange: value => this.setState({ name: value.target.value }),
                            })(
                                <Input 
                                    placeholder="Username" 
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} 
                                    disabled={!isLoadedLogin || requestNewPassword}
                                    autoComplete="username"
                                />
                            )}
                        </Form.Item>

                        <Form.Item 
                            style={{ marginBottom: 0, width: 302 }} 
                            validateStatus={passwordError ? 'error' : ''} 
                            help={passwordError || ''}
                            onKeyDown={
                                    e => e.key === "Enter" && 
                                    !disableSubmit && 
                                    (requestNewPassword ? this.confirmRef.current.focus() : this.handleConfirmLogin())
                                }
                        >
                            {getFieldDecorator('password', {
                                rules: [
                                    { required: true, message: requestNewPassword ? 'Please enter a new password!' : 'Please enter your password!' }, 
                                    { pattern: NAME, message: NAME_MSG },
                                    { validator: requestNewPassword && this.validateToNextPassword }
                                ],
                                onChange: value => this.setState({ password: value.target.value })
                            })(
                                <Input.Password 
                                    placeholder={requestNewPassword ? "New password" : "Password"}
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} 
                                    disabled={!isLoadedLogin || !isLoadedChangePassword}
                                    autoComplete={requestNewPassword ? "new-password" : "current-password"}
                                    ref={this.passwordRef}
                                />
                            )}
                        </Form.Item>

                        {requestNewPassword &&
                        <Form.Item 
                            style={{ marginBottom: 0, width: 302 }}
                            validateStatus={confirmError ? 'error' : ''} 
                            help={confirmError || ''}
                            onKeyDown={
                                e => e.key === "Enter" && 
                                !disableSubmit && 
                                this.handleConfirmPasswordChange()
                            }
                        >
                            {getFieldDecorator('confirm', {
                                rules: [
                                    { required: true, message: 'Please confirm your password!' }, 
                                    { pattern: NAME, message: NAME_MSG },
                                    { validator: this.compareToFirstPassword }
                                ]
                            })(
                                <Input.Password 
                                    placeholder='Confirm new password'
                                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} 
                                    autoComplete={"new-password"}
                                    onBlur={this.handleConfirmBlur}
                                    disabled={!isLoadedChangePassword}
                                    ref={this.confirmRef}
                                />
                            )}
                        </Form.Item>}

                        {!!recaptchaSitekey &&
                        !requestNewPassword &&
                        <Form.Item style={{ marginTop: 3, marginBottom: 1 }}>
                            <ReCAPTCHA
                                sitekey={recaptchaSitekey}
                                onChange={value => this.setState({ recaptcha: value })} 
                                asyncScriptOnLoad={() => this.setState({ recaptcha: "" })}
                                ref={this.recaptchaRef}
                            />
                        </Form.Item>}

                        <Form.Item>
                            <Button 
                                style={{ width: 302 }} 
                                type="primary" 
                                disabled={disableSubmit}
                                onClick={requestNewPassword ? this.handleConfirmPasswordChange.bind(this) : this.handleConfirmLogin.bind(this)}
                                loading={!isLoadedChangePassword || !isLoadedLogin}          
                            >
                                {requestNewPassword ? "Set new password" : "Submit"}
                            </Button>
                        </Form.Item>
                        
                    </Form> :
                    <Status is={error} back={LOGIN} />}
                </Layout.Content>
            </Layout>
        )
    }
}

export default Form.create() (connect(mapStateToProps,mapDispatchToProps)(withRouter(Login)));
