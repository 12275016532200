import React from 'react';
import ConnectivityForm from './system/ConnectivityForm';
import Devices from './devices/Devices';
import DeviceAccess from './devices/DeviceAccess';
import DeviceForm from './devices/DeviceForm';
import DeviceDiscover from './devices/DeviceDiscover';
import Events from './events/Events';
import EventForm from './events/EventForm';
import Users from './system/Users';
import UserForm from './system/UserForm';
import Conditions from './conditions/Conditions';
import ConditionForm from './conditions/ConditionForm';
import Actions from './actions/Actions';
import ActionForm from './actions/ActionForm';
import Alarms from './alarms/Alarms';
import AlarmForm from './alarms/AlarmForm';
import Analytics from './system/Analytics';
import * as ROUTES from '../../constants/routes';
import { withRouter } from "react-router-dom";
import { Layout } from 'antd';
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

function mapStateToProps(state) {
    return { 
        currentLeft: state.currentLeft
    }
}

class Content extends React.PureComponent {

    render() {
        const { location, isSmallLayout, currentLeft } = this.props
        const isAlarms = location.pathname === "/alarms" || currentLeft === "alarms" 
        const isAnalytics = location.pathname === "/analytics" || currentLeft === "analytics"

        return (
            <Layout.Content 
                style={{
                    marginTop: "48px",
                    marginLeft: isSmallLayout ? 0 : "160px",
                    marginBottom: isAlarms ? 0 : "32px",
                    height: 
                        isAlarms ? "unset" : 
                            isAnalytics ? 'calc(100% - 48px)' :
                                'calc(100% - 80px)'
                }}
            >
                <Switch>
                    <Route path={ROUTES.RELOAD} />
                    <Route path={ROUTES.ANALYTICS}>
                        <Analytics />
                    </Route>
                    <Route path={ROUTES.CONNECTIVITY}>
                        <ConnectivityForm />
                    </Route>
                    <Route path={ROUTES.DEVICES}>
                        <Devices />
                    </Route>
                    <Route path={ROUTES.DEVICEACCESS}>
                        <DeviceAccess />
                    </Route>
                    <Route path={ROUTES.DEVICEDISCOVER}>
                        <DeviceDiscover />
                    </Route>
                    <Route path={ROUTES.DEVICEFORM}>
                        <DeviceForm />
                    </Route>
                    <Route path={ROUTES.EVENTS}>
                        <Events />
                    </Route>
                    <Route path={ROUTES.EVENTFORM}>
                        <EventForm />
                    </Route>
                    <Route path={ROUTES.CONDITIONS}>
                        <Conditions />
                    </Route>
                    <Route path={ROUTES.CONDITIONFORM}>
                        <ConditionForm />
                    </Route>
                    <Route path={ROUTES.ACTIONS}>
                        <Actions />
                    </Route>
                    <Route path={ROUTES.ACTIONFORM}>
                        <ActionForm />
                    </Route>
                    <Route path={ROUTES.USERS}>
                        <Users />
                    </Route>
                    <Route path={ROUTES.USERFORM}>
                        <UserForm />
                    </Route>
                    <Route path={ROUTES.ALARMS}>
                        <Alarms />
                    </Route>
                    <Route path={ROUTES.ALARMFORM}>
                        <AlarmForm />
                    </Route>
                    <Route path={ROUTES.SETUP}>
                        <Devices />
                    </Route>
                </Switch>
            </Layout.Content>
        );
    }
}

export default connect(mapStateToProps,null)(withRouter(Content));