import React from 'react';
import { Button, Icon, Affix } from 'antd';
import { Link } from "react-router-dom";

class AddButton extends React.PureComponent {

    render() {

        const { linkTo, loading } = this.props

        return (
            <>
                <div className='mediumLayout'>
                    <Affix offsetBottom="0" className="affixWithNav">
                        <Link to={linkTo}>
                            <Button disabled={loading} type="primary" style={{ width: "100%", borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                                <Icon type="plus" />Add
                            </Button>
                        </Link>
                    </Affix>
                </div>

                <div className='smallLayout'>
                    <Affix offsetBottom="0" className="affix">
                        <Link to={linkTo}>
                            <Button disabled={loading} type="primary" style={{ width: "100%", borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                                <Icon type="plus" />Add
                            </Button>
                        </Link>
                    </Affix>
                </div>
            </>
        )
    }
}
 
export default AddButton;