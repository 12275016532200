import React from 'react';
import Status from '../../Status';
import ConfirmCancelButtons from '../../controls/ConfirmCancelButtons';
import { Form, Select, Input } from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ALARMS } from '../../../constants/routes';
import { left, select } from "../../../api/actions";
import { NUMBER, NUMBER_MSG } from '../../../constants/regex';


function mapDispatchToProps(dispatch) {
    return {
        select: record => dispatch(select(record)),
        left: currentLeft => dispatch(left(currentLeft))
    };
}

function mapStateToProps(state) {
    return { 
        record: state.record,
        processMessage: state.processMessage
    };
}

class AlarmForm extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            actions: [],
            duration: 0,
            durationUnit: "seconds",
            state: "active"
        };

        this.show = this.show.bind(this);
    }

    stateChange = value => this.setState({ state: value })
    durationChange = value => this.setState({ duration: value.target.value })
    durationUnitChange = value =>  this.setState({ durationUnit: value })
    actionChange = value => this.setState({ action: value })

    componentDidMount() {
        this.props.left("alarms"); // set initials in redux store
        this.props.form.validateFields(); // disable submit button at the beginning
        this.setState({ isLoaded: false }, () => this.show()); // show record entries
    }

    show() {

        this.props.processMessage([{
            request: "simpleactions",
            method: "get",
            params: [{ id: 0 }]
        }])
        .then(res => { 

            const simpleactions = res["simpleactions"].params
            const record = this.props.record

            for (let i=0; i<simpleactions.length; i++) 
                this.state.actions.push(<Select.Option key={simpleactions[i].id} value={simpleactions[i].name}>{simpleactions[i].name}</Select.Option>);

            if (record) {

                // set states and field values for selected record
                this.setState({ 
                    state: record.state,
                    duration: record.duration,
                    action: record.actions,
                })
    
                this.props.form.setFieldsValue({ 
                    state: record.state || "active",
                    duration: record.duration || 0,
                    action: record.actions
                })
            }

        })
        .catch(error => {
            console.error(error);
            this.setState({ error });
        })
        .finally(() => this.setState({ isLoaded: true }));
    }    

    confirm() {

            var req = []
            const { action, duration, durationUnit } = this.state
            const record = this.props.record
    
            if (record) {

                if (action?.length) {
        
                    var state = this.state.state || "active";
                    var newDuration = duration

                    if (duration && durationUnit === "minutes") {
                        newDuration = parseFloat(duration)*60
                    }
        
                    req.push({
                        request: "simpleconditions",
                        method: "set",
                        params: [{  
                            id: record.conditionId || 0,
                            name: "nvrdi" + record.port + "_" + state,
                            device: record.nvrId || 1,
                            type: "DigitalInput",
                            neg: 0,
                            params: {
                                state,
                                port: record.port.toString(),
                                duration: parseInt(newDuration)
                            }
                        }]
                    })
                
                    req.push({
                        request: "events",
                        method: "set",
                        params: [{   
                            id: record.eventId || 0,
                            state: 1, // event always active
                            conditions: [[ "nvrdi" + record.port + "_" + state ]],
                            actions: action || null,
                        }]
                    })
        
                } else {
        
                    if (record.conditionId) {
                        req.push({
                            request: "simpleconditions",
                            method: "del",
                            params: [{ id: record.conditionId }]
                        })
                    }
        
                    if (record.eventId) {
                        req.push({
                            request: "events",
                            method: "del",
                            params: [{ id: record.eventId }]
                        })
                    }
                }
            }
    
            if (req.length) {
                this.setState({ isLoaded: false}, () => {
                    this.props.processMessage(req)
                    .then(() => { 
                        this.props.history.push(ALARMS);
                        this.props.select(null);
                    })
                    .catch(error => {
                        console.error(error);
                        this.setState({ error });
                    })
                    .finally(() => this.setState({ isLoaded: true }))
                })
            } else {
                this.props.history.push(ALARMS);
                this.props.select(null);
            }
    }

    cancel() {
        this.props.history.push(ALARMS); // link to Events with Router history
    }

    componentWillUnmount() {
        this.props.select(null); // reset record props in redux store
    }

    render() {
        const { error, isLoaded, actions, duration, durationUnit, state } = this.state
        const { getFieldDecorator } = this.props.form
        const { port } = this.props.record || { port: 0 }
        
        // set form layout, show form when isLoaded is true, set conditions[] as select options in ConditionlistDynamic, call confirm() on submit or show status when error
        if (!error) {
            return <>
                <Form labelCol={{ xs: { span: 24 }, sm: { span: 5 } }} wrapperCol={{ xs: { span: 24 }, sm: { span: 15 } }} style={{ margin: "1.5em" }}>

                    <Form.Item label="Port" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('port', {
                            initialValue: port
                        })(
                            <Input disabled />                                        
                        )}
                    </Form.Item>

                    <Form.Item label="State" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('state', {
                            onChange: this.stateChange,
                            initialValue: state
                        })(
                            <Select 
                                placeholder="Please select a state"
                                disabled={!isLoaded}
                            >
                                <Select.Option key={"active"} value={"active"}>Active</Select.Option>
                                <Select.Option key={"inactive"} value={"inactive"}>Inactive</Select.Option>     
                                <Select.Option key={"short_circuit"} value={"short_circuit"}>Short circuit</Select.Option>
                                <Select.Option key={"cable_break"} value={"cable_break"}>Cable break</Select.Option>                       
                            </Select>       
                        )}
                    </Form.Item>

                    <Form.Item label="Duration" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('duration', {
                            onChange: this.durationChange,
                            initialValue: duration,
                            rules: [{ pattern: NUMBER, message: NUMBER_MSG }]
                        })(  
                            <Input 
                                placeholder="Please enter a duration" 
                                style={{ width: "100%" }} 
                                disabled={!isLoaded}
                                addonAfter={(
                                    <Select 
                                        onChange={this.durationUnitChange}
                                        value={durationUnit}
                                        style={{ width: "100px" }}
                                    >
                                        <Select.Option value="seconds">seconds</Select.Option>
                                        <Select.Option value="minutes">minutes</Select.Option>
                                    </Select>
                                )}
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="Actions" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('action', {
                            onChange: this.actionChange
                        })(
                            <Select 
                                placeholder="Please select an action" 
                                mode="multiple" 
                                disabled={!isLoaded}
                            >
                                {actions}
                            </Select>
                        )}
                    </Form.Item>

                </Form>

                <ConfirmCancelButtons 
                    loading={!isLoaded} 
                    onConfirm={this.confirm.bind(this)} 
                    onCancel={this.cancel.bind(this)}
                />
            </>
        } else {
            return <Status is={error} back={ALARMS} />
        }
    }
}

export default Form.create() (connect(mapStateToProps,mapDispatchToProps)(withRouter(AlarmForm)));