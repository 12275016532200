import React, { Fragment } from 'react';
import Player from './Player';
import Status from '../Status';
import { Row, Col, Spin } from 'antd';

class Viewer extends React.PureComponent {

    render() {
        const { data, loading, selected } = this.props
        const isSplitscreen = !selected?.id
        const devices = isSplitscreen ? [...data] : data?.filter(elem => elem.id === selected.id)
        const error = !devices.length

        if (loading) {
            return (
                <Row type="flex" style={{ alignItems: "center", justifyContent: "center", height: "100%", backgroundColor: "black" }}>
                    <Spin size="large" />
                </Row>
            )
        }

        if (error) {
            return <Status is={{ type: "errorLive", msg: "No stream id found" }} />
        }

        switch (devices.length) {
            case 1:
                return (
                    <Row gutter={[1,1]} className="playerRow" style={{ height: "100%" }} key={devices[0].id}>
                        <Col span={24} className="playerCol">
                            <Player
                                data={devices[0]} 
                            />
                        </Col>
                    </Row>
                )
            case 2:
                return (
                    <Row gutter={[1,1]} className="playerRow" style={{ height: "100%" }} key={devices}>
                        <Col span={12} className="playerColMulti">
                            <Player
                                data={devices[0]} 
                            />
                        </Col>
                        <Col span={12} className="playerColMulti">
                            <Player 
                                data={devices[1]} 
                            />
                        </Col>
                    </Row>
                )
            case 3:
                return (
                    <Fragment key={devices}>
                        <Row gutter={[1,1]} className="playerRowMulti" style={{ height: "50%" }}>
                            <Col span={12} className="playerColMulti">
                                <Player 
                                    data={devices[0]} 
                                />
                            </Col>
                            <Col span={12 } className="playerColMulti">
                                <Player 
                                    data={devices[1]} 
                                />
                            </Col>
                        </Row>
                        <Row gutter={[1,1]} className="playerRowMulti" style={{ height: "50%" }}>
                            <Col span={24} className="playerCol">
                                <Player 
                                    data={devices[2]} 
                                />
                            </Col>
                        </Row>
                    </Fragment>
                )
            case 4:
                return ( 
                    <Fragment key={devices}>
                        <Row gutter={[1,1]} className="playerRowMulti" style={{ height: "50%"}}>
                            <Col span={12} className="playerColMulti">
                                <Player 
                                    data={devices[0]} 
                                />
                            </Col>
                            <Col span={12} className="playerColMulti">
                                <Player 
                                    data={devices[1]} 
                                />
                            </Col>
                        </Row>
                        <Row gutter={[1,1]} className="playerRowMulti"  style={{ height: "50%" }}>
                            <Col span={12} className="playerColMulti">
                                <Player 
                                    data={devices[2]} 
                                />
                            </Col>
                            <Col span={12} className="playerColMulti">
                                <Player 
                                    data={devices[3]} 
                                />
                            </Col>
                        </Row>
                    </Fragment>
                )
            default:
                return <Row type="flex" style={{ alignItems: "center", justifyContent: "center", height: "100%" }} />
        }
    }
}

export default Viewer;
