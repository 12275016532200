export function top(payload) {
    return { type: "TOP", payload }
};

export function left(payload) {
    return { type: "LEFT", payload }
};

export function select(payload) {
    return { type: "SELECT", payload }
};

export function onvif(payload) {
    return { type: "ONVIF", payload }
};

export function setProcess(payload) {
    return { type: "SETPROCESS", payload }
};

export function setSort(payload) {
    return { type: "SETSORT", payload }
};

export function set(payload) {
    return { type: "SET", payload }
};

export function setTime(payload) {
    return { type: "SETTIME", payload }
};

export function setAccess(payload) {
    return { type: "SETACCESS", payload }
};

export function setLoggedOut(payload) {
    return { type: "SETLOGGEDOUT", payload }
};

export function setSelectedDevice(payload) {
    return { type: "SETSELECTEDDEVICE", payload }
};