import React from 'react';
import Highlighter from 'react-highlight-words';
import Status from '../../Status';
import AddButton from '../../controls/AddButton';
import { Link } from "react-router-dom";
import { Table, Icon, Button, Input, Popconfirm, BackTop, Divider } from 'antd';
import { connect } from "react-redux";
import { USERFORM, USERS } from '../../../constants/routes';
import { left, select } from "../../../api/actions";

// dispatch actions select and left to Redux store to set record and currentLeft
function mapDispatchToProps(dispatch) {
    return {
        select: record => dispatch(select(record)),
        left: currentLeft => dispatch(left(currentLeft)),
    };
}

// map socket state from Redux store to props
function mapStateToProps(state) {
    return { 
        processMessage: state.processMessage,
        sortMessage: state.sortMessage,
        access: state.access
    };
}

class Users extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            data: [],
            isLoaded: false,
            error: null,
            searchText: "",
            searchedColumn: "",
            selectedRowKeys: []
        };

        this.show = this.show.bind(this);
    }

    componentDidMount() {
        this.props.select(null)
        this.props.left("users"); // set currentLeft to users in Redux store
        this.show(); // call show() when isLoaded was unset
    }

    show() {

        this.setState({ isLoaded: false }, () => {
            this.props.processMessage([
                {
                    request: "users",
                    method: "get",
                    params: []
                }
            ])
            .then(res => this.setState({ data: res["users"].params }))
            .catch(error => {
                console.error(error);
                this.setState({ error });
            })
            .finally(() => {
                this.setState({ isLoaded: true })
            });
        });
    }

    edit(record) {
        
        this.props.select(record); // set record object in redux store by selected row
    }

    delete = userid => {

        this.setState({ isLoaded: false }, () => {
            this.props.processMessage([
                {
                    request: "users",
                    method: "del",
                    params: [{ id: userid }],
                }
            ])
            .then(() => this.show())
            .catch(error => {
                console.error(error);
                this.setState({ error, isLoaded: true });
            })
        });
    }

    getColumnSearchProps = dataIndex => ({

        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => { this.searchInput = node; }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />

                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>

                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),

        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),

        onFilter: (value, record) => {
            if (record[dataIndex])
                return record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
        },

        onFilterDropdownVisibleChange: visible => {
            if (visible) setTimeout(() => this.searchInput.select());
        },

        render: text => {
            if (text) { 
                if (this.state.searchedColumn === dataIndex) { 
                    text = text.toString();
                    if (dataIndex === "role")
                        text = text.charAt(0).toUpperCase() + text.slice(1);
                    return <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[this.state.searchText]}
                        autoEscape
                        textToHighlight={text}
                    />
                } else {
                    if (dataIndex === "role")
                        text = text.charAt(0).toUpperCase() + text.slice(1);
                    return text; 
                }
            } else { 
                return "-" 
            }
        }
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    render() {
        
        const { error, data, isLoaded } = this.state;
        const { sortMessage, access } = this.props;
        const isMaintainer = access === "maintainer"

        if (!error) {
            return <>
                <Table
                    style={{ height: data.length ? "100%" : "unset" }}
                    dataSource={data}
                    size="small"
                    rowKey={record => record.id}
                    loading={!isLoaded}
                    pagination={false}
                    scroll={{ x: 'max-content' }}
                    columns={[
                        { 
                            dataIndex: "name",
                            title: "Name",
                            sorter: (a, b) => sortMessage({ a, b, value: "name" }),
                            ...this.getColumnSearchProps('name'),
                        },
                        { 
                            dataIndex: "email",
                            title: "Email",
                            sorter: (a, b) => sortMessage({ a, b, value: "email" }),
                            ...this.getColumnSearchProps('email'),
                        },
                        { 
                            dataIndex: "role",
                            title: "Role",
                            sorter: (a, b) => sortMessage({ a, b, value: "role" }),
                            ...this.getColumnSearchProps('role'),
                        },
                        {
                            title: 'Controls',
                            render: record => <>
                                <Link to={USERFORM}>
                                    <Button type="link" onClick={this.edit.bind(this, record)} disabled={record.role === "administrator" && isMaintainer}>
                                        <Icon type="edit" />Edit
                                    </Button>
                                </Link>

                                <Divider type="vertical" />

                                <Popconfirm 
                                    title="Are you sure?"
                                    okText='Yes'
                                    okType='danger'
                                    cancelText='No'
                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                    onConfirm={this.delete.bind(this, record.id)}
                                    disabled={record.role === "administrator" && isMaintainer}
                                >
                                    <Button 
                                        type="link"
                                        disabled={record.role === "administrator" && isMaintainer}
                                    >
                                        <Icon type="delete" />Delete
                                    </Button>
                                </Popconfirm>
                            </>
                        },
                    ]}
                />

                <AddButton 
                    linkTo={USERFORM} 
                    loading={!isLoaded}
                />

                <BackTop 
                    style={{
                        right: "5px",
                        bottom: "unset",
                        top: "52px",
                    }}
                />
            </>
        } else {
            return <Status is={error} back={USERS} />
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Users);