
import React from 'react';
import Status from '../Status';
import dayjs from 'dayjs';
import videojs from 'video.js'
import Core from '@any-touch/core';
import pan from '@any-touch/pan';
import pinch from '@any-touch/pinch';
import PictureInPicture from './PictureInPicture';
import { connect } from "react-redux";
import { checkIOS, saveFile, debounce, getCookie, sendDataToMobileApp, isMobileApp, roundToTwoDecimals } from '../Utilities';
import { isMobile, isSafari } from 'react-device-detect';
import { WebRTCAdaptor } from './webrtc/webrtc_adaptor';
import { Row, Spin } from 'antd';
import { customAlphabet } from 'nanoid';

// map processMessage state from Redux store to props
function mapStateToProps(state) {
    return {
        processMessage: state.processMessage,
        unit: state.unit
    }
}

class Player extends React.PureComponent
{
    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            error: null,
            isControlbarUsed: false,
            isPipVisible: false,
            pipSrc: null
        }
        
        // Debugging
        this.testLocal = false
        this.testRemote = false
        this.debug = window.config.DEBUG
        this.debugDeep = false
        this.staticSource = false
        this.isRemote = this.testRemote || this.props.unit.proxyKey

        // WebRTC
        this.player = null
        this.webRTCAdaptor = null
        this.timeoutConnectWebRTC = null
        this.isReconnecting = false
        this.fpsCounterLimit = window.webrtc?.RECONNECT_NOFRAMES || 10
        this.timeoutInitialMeasurement = window.webrtc?.RECONNECT_NOINITIALBITMEASUREMENT*1000 || 9000
        this.timeoutMeasurement = window.webrtc?.RECONNECT_BITMEASUREMENTFAILED*1000 || 6000
        this.timeoutErrorCallback = window.webrtc?.RECONNECT_ERRORCALLBACK*1000 || 3000
        this.firstRun = true
        this.streamId = this.props.data?.streamId
        this.webRTCStreamId = this.isRemote 
            ? this.props.data?.hostName 
                ? `${this.props.data.hostName}_${this.streamId}`
                : `NVR-${customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 8)()}_${this.streamId}` // generates random nvr identifier if there is no hostnome
            : this.streamId.toString()
        this.isMobileIOS = checkIOS()
        this.iceServers = window.webrtc?.ICESERVERS.map(elem => {
            var urls = elem.urls.split('/').join('') // remove slashes e.g. from stun://abc.def:1234 to stun:abc.def:1234
            var isTURN = urls.indexOf("turn") !== -1

            // force tcp usage for TURN server on mobile iOS
            if (this.isMobileIOS && isTURN) {
                urls = urls + "?transport=tcp"
            }

            return {
                ...elem,
                urls
            }
        })
        this.vpnAddress = this.props.data?.vpnAddress
        this.webRTCServer = window.webrtc?.SERVER

        // Stats
        this.lastStatisticValid = false
        this.lastStatisticTime = 0
        this.lastStatisticFrames = 0
        this.lastStatisticDropped = 0
        this.lastStatisticCorrupt = 0
        this.lastStatisticBytes = 0
        this.bitrate = 0
        this.fpsCounter = 0
        this.newFps = 1
        this.oldFps = 0

        // PTZ
        this.ptzRef = React.createRef()
        this.ptzContainer = null
        this.intervalPtzByButton = 0
        this.intervalPtzByButtonSpeed = 200
        this.timeoutPtz = 0
        this.intervalPtz = 0
        this.speed = 0.4
        this.step = 0.02
        this.touchGestures = false
        this.startX = null
        this.startY = null
        this.left = null
        this.top = null
        this.width = null
        this.height = null
        this.maxLeft = null
        this.maxTop = null
        this.minLeft = null
        this.minTop = null
        this.deltaMinLeft = null
        this.deltaMaxLeft = null
        this.deltaMinTop = null
        this.deltaMaxTop = null
        this.scale = null
        this.x = null
        this.y = null
        this.z = 0.5
        this.isDragging = false
        this.isPinching = false
        this.isIOS = this.isMobileIOS || isSafari // also detect Safari Desktop (macOS)
        this.zoomDirection = null
        this.zoomSpeed = 0.5
        this.zoomStep = 0.1
        this.zoomTimeout = 0
        this.hasPtz = this.props.data?.ptz
    }

    componentDidMount() {

        if (this.isRemote) {
            this.createRemoteStream()
        }

        if (this.videoNode) {

            const options = {
                autoplay: true,
                responsive: true,
                preload: true,
                nativeControlsForTouch: false,
                loop: true
            }

            this.player = videojs(this.videoNode, options, () => { // instantiate Video.js

                const controlBar = this.player.getChild("controlBar");
                const fullscreenToggle = controlBar.getChild("fullscreenToggle")
                const hasWebview = window?.ReactNativeWebView

                // remove unnecessary elements from controlbar
                this.player.removeChild("bigPlayButton");
                controlBar.removeChild("playToggle");
                controlBar.getChild("volumePanel").removeChild("volumeControl")
                controlBar.removeChild("currentTimeDisplay");
                controlBar.removeChild("timeDivider");
                controlBar.removeChild("durationDisplay");
                controlBar.getChild("progressControl").hide(); // error if player disposed when progresscontrol removed
                controlBar.removeChild("remainingTimeDisplay");
                controlBar.removeChild("customControlSpacer");
                controlBar.removeChild("playbackRateMenuButton");
                controlBar.removeChild("chaptersButton");
                controlBar.removeChild("descriptionsButton");
                controlBar.removeChild("subsCapsButton");
                controlBar.removeChild("audioTrackButton");
                controlBar.removeChild("pictureInPictureToggle");
                controlBar.removeChild("liveDisplay");
                controlBar.removeChild("fullscreenToggle")

                this.player.volume(1) // set max volume

                // create custom elements for controlbar
                const vjsButtonComponent = videojs.getComponent('Button');
                const vjsMenuButtonComponent = videojs.getComponent('MenuButton');
                const vjsMenuItemComponent = videojs.getComponent('MenuItem');

                videojs.registerComponent('leftButton', videojs.extend(vjsButtonComponent, {
                    constructor: function () {
                        vjsButtonComponent.apply(this, arguments);
                        
                        this.on('mousedown', () => this.options_.onPanLeftByButton())
                        this.on('mouseup', () => this.options_.onMoveEndByButton())
                        this.controlText("Pan left");
                    },
                    buildCSSClass() {
                        return "icon-left vjs-control vjs-button";
                    },
                }));

                videojs.registerComponent('rightButton', videojs.extend(vjsButtonComponent, {
                    constructor: function() {
                        vjsButtonComponent.apply(this, arguments);

                        this.on('mousedown', () => this.options_.onPanRightByButton())
                        this.on('mouseup', () => this.options_.onMoveEndByButton())
                        this.controlText("Pan right");
                    },
                    buildCSSClass() {
                        return 'icon-right vjs-control vjs-button';
                    }
                }));

                videojs.registerComponent('upButton', videojs.extend(vjsButtonComponent, {
                    constructor: function () {
                        vjsButtonComponent.apply(this, arguments);

                        this.on('mousedown', () => this.options_.onTiltUpByButton())
                        this.on('mouseup', () => this.options_.onMoveEndByButton())
                        this.controlText("Tilt up");
                    },
                    buildCSSClass() {
                        return 'icon-up vjs-control vjs-button';
                    }
                }));

                videojs.registerComponent('downButton', videojs.extend(vjsButtonComponent, {
                    constructor: function () {
                        vjsButtonComponent.apply(this, arguments);

                        this.on('mousedown', () => this.options_.onTiltDownByButton())
                        this.on('mouseup', () => this.options_.onMoveEndByButton())
                        this.controlText("Tilt down");
                    },
                    buildCSSClass() {
                        return 'icon-down vjs-control vjs-button';
                    }
                }));

                videojs.registerComponent('zoomInButton', videojs.extend(vjsButtonComponent, {
                    constructor: function() {
                        vjsButtonComponent.apply(this, arguments);

                        this.on('mousedown', () => this.options_.onZoomInByButton())
                        this.on('mouseup', () => this.options_.onMoveEndByButton())
                        this.controlText("Zoom in");
                    },
                    buildCSSClass() {
                        return 'icon-zoomin vjs-control vjs-button';
                    }
                }));

                videojs.registerComponent('zoomOutButton', videojs.extend(vjsButtonComponent, {
                    constructor: function() {
                        vjsButtonComponent.apply(this, arguments);

                        this.on('mousedown', () => this.options_.onZoomOutByButton())
                        this.on('mouseup', () => this.options_.onMoveEndByButton())
                        this.controlText("Zoom out");
                    },
                    buildCSSClass() {
                        return 'icon-zoomout vjs-control vjs-button';
                    }
                }));

                videojs.registerComponent('addPresetMenuItem', videojs.extend(vjsMenuItemComponent, {
                    constructor: function () {
                        vjsMenuItemComponent.apply(this, arguments);

                        this.on(['click','tap'], () => {

                            this.options_.processMessage([{
                                request: "ptz_preset",
                                method: "set",
                                params: [{ 
                                    id: this.options_.device_id, 
                                    name: "preset_" + dayjs().format("YYYYMMDD_HHmmss")
                                }]
                            }])
                            .then(res => {
                                var preset = res["ptz_preset"].params[0];
                                var presets = this.options_.presets;
                                const hasWebview = window?.ReactNativeWebView

                                presets.unshift({
                                    name: preset.name,
                                    token: preset.value
                                })

                                this.options_.controlBar.removeChild("presetMenu")
                                this.options_.controlBar.addChild("presetMenu", { 
                                    processMessage: this.options_.processMessage,
                                    processMessageEncapsulated: this.options_.processMessageEncapsulated,
                                    device_id: this.options_.device_id, 
                                    presets,
                                    controlBar: this.options_.controlBar
                                }, hasWebview || isMobile ? 3 : 9);
                            })
                        })
                    },
                }));

                videojs.registerComponent('presetMenuItem', videojs.extend(vjsMenuItemComponent, {
                    constructor: function () {
                        vjsMenuItemComponent.apply(this, arguments);
 
                        this.on(['click','tap'], () => {

                            this.options_.processMessageEncapsulated(
                                this.options_.device_id, 
                                this.options_.label,
                                this.options_.value
                            )
                        })
                    }
                }));

                var presetMenuItem = videojs.getComponent('presetMenuItem');
                var addPresetMenuItem = videojs.getComponent('addPresetMenuItem');

                videojs.registerComponent('presetMenu', videojs.extend(vjsMenuButtonComponent, {
                    constructor: function () {
                        vjsMenuButtonComponent.apply(this, arguments);
                        this.controlText("Presets");
                    },
                    buildCSSClass() {
                        return 'icon-menu vjs-control vjs-menu-button vjs-menu-button-popup';
                    },
                    createItems(items = [
                        new addPresetMenuItem(this.player_, { 
                            processMessage: this.options_.processMessage, 
                            processMessageEncapsulated: this.options_.processMessageEncapsulated,
                            device_id: this.options_.device_id,
                            presets: this.options_.presets,
                            controlBar: this.options_.controlBar,
                            label: "create new"
                        }),
                        new presetMenuItem(this.player_, { 
                            processMessageEncapsulated: this.options_.processMessageEncapsulated, 
                            device_id: this.options_.device_id,
                            label: "replace old"
                        })
                    ]) {
                        var presets = this.options_.presets;

                        presets.forEach(element => items.push(new presetMenuItem(this.player_, { 
                            processMessageEncapsulated: this.options_.processMessageEncapsulated, 
                            device_id: this.options_.device_id,
                            value: element.token, 
                            label: element.name
                        })));

                        return items;
                    }
                }));

                const vjsSpacerComponent = videojs.getComponent('Spacer')

                videojs.registerComponent('spacer', videojs.extend(vjsSpacerComponent, {
                    constructor: function () {
                        vjsSpacerComponent.apply(this, arguments);
                    }
                }))

                videojs.registerComponent('snapshotButton', videojs.extend(vjsButtonComponent, {
                    constructor: function () {
                        vjsButtonComponent.apply(this, arguments);

                        this.on(['click','tap'], () => {
                        
                            const video = this.options_.video
                            const canvas = document.createElement('canvas')

                            canvas.width = video.videoWidth
                            canvas.height = video.videoHeight

                            // canvas.getContext('2d').drawImage(video, 0, 0)
                            // const dataUri = canvas.toDataURL('image/jpeg')
                            // const data = dataUri.split(',')[1]
                            // const buf = Buffer.from(data, 'base64')
                            // const blob = new Blob([buf])
                    
                            const context = canvas.getContext('2d')
                            context.drawImage(video, 0, 0, canvas.width, canvas.height)
                            const url = canvas.toDataURL('image/jpeg', 1.0)
                            
                            if (isMobileApp) {
                                sendDataToMobileApp(url)
                            } else {
                                saveFile(url, "snapshot_" + dayjs().format("YYYYMMDD_HHmmss") + ".jpg")
                            }
                        })

                        this.controlText("Snapshot");
                    },
                    buildCSSClass() {
                        return 'icon-snapshot vjs-control vjs-button';
                    }
                }))

                videojs.registerComponent('pipButton', videojs.extend(vjsButtonComponent, {
                    constructor: function () {
                        vjsButtonComponent.apply(this, arguments);

                        this.on(['click','tap'], () => {
                            this.options_.toggleIsPipVisible()
                        })

                        this.controlText("Analytics")
                    },
                    buildCSSClass() {
                        return 'icon-pip vjs-control vjs-button';
                    }
                }))

                // add ptz buttons to controlbar
                if (this.hasPtz) {

                    if (!hasWebview && !isMobile) {
                        controlBar.addChild("leftButton", { onPanLeftByButton: this.onPanLeftByButton, onMoveEndByButton: this.onMoveEndByButton }, 3);
                        controlBar.addChild("upButton", { onTiltUpByButton: this.onTiltUpByButton, onMoveEndByButton: this.onMoveEndByButton }, 4);
                        controlBar.addChild("downButton", { onTiltDownByButton: this.onTiltDownByButton, onMoveEndByButton: this.onMoveEndByButton }, 5);
                        controlBar.addChild("rightButton", { onPanRightByButton: this.onPanRightByButton, onMoveEndByButton: this.onMoveEndByButton }, 6);
                        controlBar.addChild("zoomInButton", { onZoomInByButton: this.onZoomInByButton, onMoveEndByButton: this.onMoveEndByButton }, 7);
                        controlBar.addChild("zoomOutButton", { onZoomOutByButton: this.onZoomOutByButton, onMoveEndByButton: this.onMoveEndByButton }, 8);
                    }

                    this.props.processMessage([{
                        request: "ptz_presets",
                        method: "get",
                        params: [{ id: this.props.data?.id }]
                    }])
                    .then(res => { 
                        const presets = res["ptz_presets"].params;

                        controlBar.addChild("presetMenu", { 
                            processMessage: this.props.processMessage, 
                            processMessageEncapsulated: this.processMessageEncapsulated, 
                            device_id: this.props.data?.id, 
                            presets,
                            controlBar
                        }, hasWebview || isMobile ? 3 : 9);
                    })
                    .catch(error => {
                        console.error(`[PTZ ${this.webRTCStreamId}] ${error}`)
                        // error.type = "errorLive";
                        // this.setState({ error });
                    })

                    this.initGestures()
                } 
                
                controlBar.addChild(
                    "spacer",
                    {},
                    this.hasPtz
                    ? hasWebview || isMobile 
                        ? 4 
                        : 10
                    : 3
                )

                controlBar.addChild(
                    "snapshotButton",
                    { 
                        video: this.videoNode // this.videoNode.firstChild for iOS?
                    },
                    this.hasPtz
                    ? hasWebview || isMobile 
                        ? 5 
                        : 11
                    : 4
                )

                controlBar.addChild(
                    "pipButton",
                    { 
                        toggleIsPipVisible: this.toggleIsPipVisible
                    },
                    this.hasPtz
                    ? hasWebview || isMobile 
                        ? 6 
                        : 12
                    : 5
                )

                controlBar.addChild(fullscreenToggle)

                // prevent gestures while controlbar is open/in use
                // controlbar entered/touched
                controlBar.on(["mouseenter", "touchstart"], (e) => {
                    e.stopPropagation()
                    if (!this.state.isControlbarUsed) {
                        this.setState({ isControlbarUsed: true }, () => this.disableGestures())
                    }
                })

                // controlbar left on desktop
                controlBar.on("mouseleave", () => {
                    if (this.state.isControlbarUsed) {
                        this.setState({ isControlbarUsed: false }, () => this.initGestures())
                    }
                })

                // controlbar left on mobile 1/2
                this.player.on("touchstart", () => {
                    if (this.state.isControlbarUsed) {
                        this.setState({ isControlbarUsed: false })
                    }
                })

                // controlbar left on mobile 2/2
                this.player.on("touchend", () => {
                    if (!this.state.isControlbarUsed
                        && !this.touchGestures
                        && !this.state.isPipVisible) {
                        this.initGestures()
                    }
                })

                // prevent Safari Mobile from pause stream after leaving fullscreen
                this.player.on("pause", () => {
                    this.connectWebRTC(this.webRTCStreamId)
                })

                // configure pipeline
                if (!this.staticSource) {
                    this.connectWebRTC(this.webRTCStreamId)
                }
            })

            this.enableFpsMeasurementIOS()
        }
    }

    toggleIsPipVisible = () => {

        const { isPipVisible, pipSrc } = this.state
        const { processMessage, unit: { proxyKey} } = this.props
        const { location, config: { SERVER } } = window

        this.setState({ isPipVisible: !isPipVisible }, 
            debounce(() => {
                if (isPipVisible) {
                    processMessage([{
                        request: "streaminfo",
                        method: "get",
                        params: [{ 
                            streamid: this.streamId
                        }]
                    }])
                    .then(res => {
                        var analytics = res["streaminfo"].params.find(elem => elem.streamid === this.streamId)?.analyticsChannel || null

                        if (!!analytics) {
                            const path = proxyKey ? ("/unit/file/" + proxyKey) : "";
                    
                            this.setState({ pipSrc: `${location.protocol}//${this.testLocal ? SERVER : location.host}${path}/snapshot/${analytics}/latest.jpeg` })
                        } else {
                            this.setState({ isPipVisible: false })
                        }
                    })
                    .catch(error => {
                        console.error(`[Analytics ${this.streamId}]`, error.msg || error )
                        if (isPipVisible) {
                            this.setState({ isPipVisible: false })
                        }
                    })
                } else {
                    if (!!pipSrc) {
                        this.setState({ pipSrc: null })
                    }
                }
            }, 250)
        )
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }

        if (this.hasPtz) {
            clearInterval(this.intervalPtzByButton)
            clearInterval(this.intervalPtz)
            clearTimeout(this.timeoutPtz)
        }

        clearInterval(this.timeoutConnectWebRTC)

        if (this.webRTCAdaptor) {
            this.webRTCAdaptor.stop(this.webRTCStreamId)
            this.webRTCAdaptor.closePeerConnection(this.webRTCStreamId)
            this.webRTCAdaptor.closeWebSocket()
            this.webRTCAdaptor = null
        }
    }

    createRemoteStream = () => {
        var server = this.webRTCServer
        var domain = getCookie("__uri") // use cookie from cloud as domain
        var port = window.config?.RTSPPORT

        if (server) {
            try {
                server = new URL(server, "http://" + server)
                server = server.host ? server.host : server.href // get antmedia.wcctv.net:5443 from https://antmedia.wcctv.net:5443/test
            } catch (error) {
                console.error(`[AMS ${this.webRTCStreamId}] ${error}`)
            }
        } else {
            console.error(`[AMS ${this.webRTCStreamId}] Server ${server} not available.`)
        }

        if (domain) {
            try {
                domain = new URL(domain)
                domain = domain?.hostname // get nvrdev.netco.de from wss://nvrdev.netco.de/wsock
            } catch (error) {
                console.error(`[AMS ${this.webRTCStreamId}] ${error}`)
            }
        } else if (this.vpnAddress) {
            domain = this.vpnAddress // use vpn address from liveparams as domain
        } else {
            console.error(`[AMS ${this.webRTCStreamId}] Domain ${domain} not available.`)
        }

        if (this.testRemote) {
            domain = "nvrdev.netco.de"
        }

        if (server && domain) {
            this.debug && console.log(`[AMS ${this.webRTCStreamId}] Server ${server} connecting to ${domain}`)

            this.setState({ loading: true }, () => {
                fetch(`https://${server}/LiveApp/rest/v2/broadcasts/create?autoStart=true`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "name": this.webRTCStreamId,
                        "streamUrl": this.staticSource
                            ? "rtsp://wowzaec2demo.streamlock.net/vod/mp4:BigBuckBunny_115k.mp4"
                            : `rtsp://user${this.streamId}:pw${this.streamId}@${domain}:${port}/${this.streamId}`, 
                        "streamId": this.webRTCStreamId,
                        "type": "streamSource"
                    })
                })
                .then(res => res.json())
                .then(json => {
                    var msg = `[AMS ${this.webRTCStreamId}] `
        
                    if (json?.success) {
                        msg = msg + "stream created"
                        this.debug && console.log(msg)
                    }
        
                    if (json?.message) {
                        msg = msg + json.message
                        this.debug && console.warn(msg)
                    }
        
                    if (!json?.success && !json?.message) {
                        msg = msg + "stream creation failed"
                        console.error(msg)
                    }
                })
                .catch(error => console.error(`[AMS ${this.webRTCStreamId}] ${error}`))
                .finally(() => this.setState({ loading: false }))
            })
        }
    }

    processMessageEncapsulated = (device_id, label, value) => {
        
        var param = { id: device_id };
        var method;

        if (label === "replace old" && this.state.lastLabel && this.state.lastValue) { // replace preset

            param.name = this.state.lastLabel;
            param.value = this.state.lastValue;
            method = "ptz_preset";

        } else { // move to preset

            if (value) {
                param.value = value;
            }

            method = "ptz_presets";

            this.setState({
                lastLabel: label,
                lastValue: value
            })
        }

        if (Object.keys(param)?.length > 1) { // when old preset selected before
            this.props.processMessage([{
                request: method,
                method: "set",
                params: [param]
            }])
            .catch(error => {
                console.error(`[PTZ ${this.webRTCStreamId}] ${error}`)
                error.type = "errorLive";
                this.setState({ error });
            })
        }
    }

    connectWebRTC(streamId) {
        const { location, config: { SOCKETPROTOCOL, SERVER, WEBRTC } } = window

        var port = location.port ? (":" + location.port) : "";
        var server = `${SOCKETPROTOCOL}://${this.testLocal ? SERVER : (location.hostname + port)}${WEBRTC}`

        if (this.isRemote) {
            try {
                server = this.webRTCServer
                server = new URL(server, "http://" + server)
                server = server.host ? server.host : server.href
                server = `${SOCKETPROTOCOL}://${server}/LiveApp/websocket`
            } catch (error) {
                console.error(`[WebRTC ${streamId}] ${error}`)

                this.setState({ 
                    error: {
                        type: "errorLive",
                        msg: "Stream failed - check configuration"
                    }
                })
            }
        }

        this.debug && console.log(`[WebRTC ${streamId}] Using ICE servers`, this.iceServers)

        if (this.videoNode) {
            this.webRTCAdaptor = new WebRTCAdaptor({
                websocket_url: server,
                mediaConstraints: {
                    video: false,
                    audio: false
                },
                peerconnection_config: {
                    'iceServers': this.iceServers
                },
                sdp_constraints: {
                    OfferToReceiveAudio: true,
                    OfferToReceiveVideo: true
                },
                remoteVideoId: this.videoNode.id,
                isPlayMode: true,
                debug: this.debugDeep,
                myDebug: this.debug,
                callback: (command, data) => {
                    switch (command) {
                        case "initialized":
                            this.debug && console.log(`[WebRTC ${streamId}] ${command} - try to get streamInformation`)
                            this.webRTCAdaptor.getStreamInfo(streamId)
                            break;
                        case "streamInformation":
                            this.debug && console.log(`[WebRTC ${streamId}] ${command} - try to play`)
                            this.isReconnecting = false
                            this.webRTCAdaptor.play(streamId, "", "", [], "", "")
                            break;
                        case "play_started":
                            this.debug && console.log(`[WebRTC ${streamId}] ${command} - try to get bitrateMeasurement`)

                            if (this.state.error) {
                                this.setState({ error: null })
                            }

                            clearTimeout(this.timeoutConnectWebRTC)
                            !this.isReconnecting && this.reconnectOrGetStreamInfo(this.timeoutInitialMeasurement, false, `[WebRTC ${streamId}] no initial bit measurement - try to reconnect`)

                            break;
                        case "play_stopped":
                            if (!this.state.error) {
                                this.setState({ 
                                    error: {
                                        type: "errorLive",
                                        msg: "Stream will start playing automatically when it is live"
                                    }
                                })
                            }
                            break;
                        case "closed":
                            if (typeof data != "undefined") {
                                this.debug && console.log(`[WebRTC ${streamId}] ${command}`, data)
                            }
                            break;
                        case "bitrateMeasurement":
                            this.debug && console.log(`[WebRTC ${streamId}] ${command} - playing with videoBitrate ${data.videoBitrate}, audioBitrate ${data.audioBitrate}, targetBitrate ${data.targetBitrate}`) // TODO: Statistics for Marco
                            this.bitrate = data.videoBitrate
                            
                            this.updateStatistics()
    
                            clearTimeout(this.timeoutConnectWebRTC)
                            !this.isReconnecting && this.reconnectOrGetStreamInfo(this.timeoutMeasurement, false, `[WebRTC ${streamId}] bit measurement failed - try to reconnect`)
    
                            break;
                        case "ice_connection_state_changed":
                            this.debug && console.log(`[WebRTC ${streamId}] ${command} to ${data.state}`)
                            break;
                        case "updated_stats":
                            this.debug && console.log(`[WebRTC ${streamId}] updated_stats - `, data)
                            break;
                        default:
                            this.debugDeep && console.log(`[WebRTC ${streamId}] ${command}`)
                            break;
                    }
                },
                callbackError: (error) => {
    
                    var message = `[WebRTC ${streamId}] ${error}`
                    var type = "errorLive"
    
                    if (error !== "already_playing") {

                        var msg = "Stream will start playing automatically when it is live"
        
                        if (error === "no_stream_exist") {
                            msg = "Stream loading..."
                            type = "infoLive"
                        }

                        if (error === "protocol_not_supported") {
                            msg = "Protocol not supported"
                        }

                        this.setState({ 
                            error: {
                                type,
                                msg
                            }
                        })

                        message += " - try to reconnect"
                        
                        this.reconnectOrGetStreamInfo(this.timeoutErrorCallback, true, message)
                    }
                }
            })
        }
    }

    reconnectOrGetStreamInfo(delay = 3000, byError = false, msg = "") {
        this.isReconnecting = byError
        this.timeoutConnectWebRTC = setTimeout(() => {
            if (msg) {
                console.error(msg)
            }
            if (this.webRTCAdaptor) {
                this.webRTCAdaptor.stop(this.webRTCStreamId)
                this.webRTCAdaptor.getStreamInfo(this.webRTCStreamId)
            } else {
                this.connectWebRTC(this.webRTCStreamId)
            }
            if (this.isRemote) {
                this.createRemoteStream()
            }
        }, delay)
    }

    enableFpsMeasurementIOS = () => {
        if (this.isIOS && 'requestVideoFrameCallback' in HTMLVideoElement.prototype) {

            let counter = 0
            let startTime = 0.0
    
            var ticker = (now, metadata) => {
    
                if (startTime === 0.0) {
                    startTime = now
                }
    
                const elapsed = (now - startTime) / 1000.0
                const fps = ++counter / elapsed
    
                if (isFinite(fps)) {
                    this.newFps = fps
                }
    
                this.videoNode.requestVideoFrameCallback(ticker)
            }
            
            this.videoNode.requestVideoFrameCallback(ticker)
        }
    }

    updateStatistics = () => {

        var tech__ = this.player?.tech(true)
        var quality = tech__.getVideoPlaybackQuality()
        var currentCreationTime = quality?.creationTime
        var currentFrames = this.videoNode?.mozPaintedFrames ?? quality?.totalVideoFrames // https://bugzilla.mozilla.org/show_bug.cgi?id=1586505
        var currentBytes = this.player?.children_[0]?.webkitVideoDecodedByteCount

        if (this.lastStatisticValid) {
            var diff = currentCreationTime - this.lastStatisticTime
            var framediff = currentFrames - this.lastStatisticFrames
            var bytesdiff = currentBytes - this.lastStatisticBytes

            if (diff) {
                var statisticText = this.hasPtz ? "PTZ enabled, " : ""
                var kbps = bytesdiff / diff
                var fps = this.isIOS ? this.newFps : framediff * 1000 / diff

                if (kbps) {    
                    statisticText = statisticText + kbps.toFixed() + " kBps"
                } else {
                    statisticText = statisticText + (this.bitrate * 0.001).toFixed() + " kBps"
                }

                if (fps > 0 && this.newFps !== this.oldFps) {
                    statisticText = statisticText + ", " + fps.toFixed() + " fps" 
                    this.fpsCounter = 0
                } else {
                    statisticText = statisticText + ", 0 fps"
                    this.fpsCounter += 1
                }

                if (this.isIOS) {
                    this.oldFps = this.newFps
                }

                if (this.fpsCounter > this.fpsCounterLimit) {

                    console.error(`[WebRTC ${this.webRTCStreamId}] no frames for ${this.fpsCounterLimit}s - try to reconnect`)
                    // console.error(`[WebRTC ${this.webRTCStreamId}] no frames for ${this.fpsCounterLimit}s - check TURN server`)
                    // checkTURNServer(this.iceServers)
                    // .then(active => {
                    //     if (active) {
                            this.setState({ 
                                error: {
                                    type: "infoLive",
                                    msg: "Stream will start playing automatically when it is live"
                                }
                            })
                    //         console.info(`[WebRTC ${this.webRTCStreamId}] TURN server active - try to reconnect`)
                    //     } else {
                    //         this.setState({ 
                    //             error: {
                    //                 type: "errorLive",
                    //                 msg: "Stream failed - check configuration"
                    //             }
                    //         })
                    //         console.error(`[WebRTC ${this.webRTCStreamId}] TURN server not available - try to reconnect`)
                    //     }
                    // })
                    // .catch(error => console.error(`[WebRTC ${this.webRTCStreamId}] ${error}`))
                    // .finally(() => {
                        this.reconnectOrGetStreamInfo()
                        this.fpsCounter = 0
                    // })
                }

                if (this.debug) {
                    if (window.selectedCandidate || this.isRemote) {
                        statisticText = statisticText + " ["
                    }
                    if (window.selectedCandidate) {
                        statisticText = statisticText + "Candidate " + window.selectedCandidate
                    }
                    if (window.selectedCandidate && this.isRemote) {
                        statisticText = statisticText + ", "
                    }
                    if (this.isRemote) {
                        statisticText = statisticText + "AMS " + this.webRTCServer
                    }
                    if (window.selectedCandidate || this.isRemote) {
                        statisticText = statisticText + "]"
                    }
                }

                this.setState({ statistics: statisticText })

            } else {
                this.setState({ statistics: this.hasPtz ? "PTZ enabled" : "" })
                this.lastStatisticValid = false
            }
        }

        this.lastStatisticBytes = currentBytes
        this.lastStatisticTime = currentCreationTime
        this.lastStatisticFrames = currentFrames
        this.lastStatisticBytes = currentBytes
        this.lastStatisticValid = true
    }

    myPan = (e) => {

        if (e.type === "pan" && !this.isPinching) {
    
            const container = this.ptzRef.current

            this.isDragging = true
            this.startX = e.startInput.x
            this.startY = e.startInput.y - 48 // without header
            this.left = e.displacementX
            this.top = e.displacementY
            this.width = container.offsetWidth
            this.height = container.offsetHeight
            this.minLeft = -this.width/2
            this.maxLeft = this.width/2
            this.deltaMinLeft = -(this.left/this.minLeft)
            this.deltaMaxLeft = this.left/this.maxLeft
            this.minTop = -this.height/2
            this.maxTop = this.height/2
            this.deltaMinTop = -(this.top/this.minTop)
            this.deltaMaxTop = this.top/this.maxTop

            const scaleX = Math.max(0.5, Math.min(1, Math.pow(this.width/1920, 0.4)))
            const scaleY = Math.max(0.5, Math.min(1, this.height/1080))

            if (this.left < this.minLeft) {
                this.x = roundToTwoDecimals(-1 * scaleX)
            } else if (this.left > this.maxLeft) {
                this.x = roundToTwoDecimals(1 * scaleX)
            } else if (this.left < 0) {
                this.x = roundToTwoDecimals(this.deltaMinLeft * scaleX)
            } else {
                this.x = roundToTwoDecimals(this.deltaMaxLeft * scaleX)
            }

            if (this.top < this.minTop) {
                this.y = roundToTwoDecimals(1 * scaleY)
            } else if (this.top > this.maxTop) {
                this.y = roundToTwoDecimals(-1 * scaleY)
            } else if (this.top < 0) {
                this.y = -roundToTwoDecimals(this.deltaMinTop * scaleY)
            } else {
                this.y = -roundToTwoDecimals(this.deltaMaxTop * scaleY)
            }

            if (!this.intervalPtz) {
                this.ptz({ x: this.x, y: this.y, z: 0 })
                this.intervalPtz = setInterval(() => this.ptz({ x: this.x, y: this.y, z: 0 }), 200)
            }

            if (e.phase === "end") {
                clearInterval(this.intervalPtz)
                this.intervalPtz = null
                this.ptz({ x: 0, y: 0, z: 0 })
                this.timeoutPtz = setTimeout(() => this.isDragging = false, 200)
            }
        }
    }

    myPinch = (e) => {
        if (e.type === "pinch" && !this.isDragging) {
    
            this.isPinching = true
            this.scale = e.deltaScale

            if (this.scale === 1) {
                // this.z = 0
            } else if (this.scale < 1) {
                this.z = -0.5
            } else {
                this.z = 0.5
            }

            if (!this.intervalPtz) {
                this.ptz({ x: 0, y: 0, z: this.z })
                this.intervalPtz = setInterval(() => this.ptz({ x: 0, y: 0, z: this.z }), 200)
            }

            if (e.phase === "end") {
                clearInterval(this.intervalPtz)
                this.intervalPtz = null
                this.ptz({ x: 0, y: 0, z: 0 })
                this.timeoutPtz = setTimeout(() => this.isPinching = false, 200)
            }
        }
    }

    initGestures = () => {
        if (this.ptzRef.current 
            && this.hasPtz) {
                this.ptzContainer = new Core(this.ptzRef.current)
                this.ptzContainer.use(pan)
                this.ptzContainer.use(pinch)
                this.enableGestures()
        }
    }

    enableGestures = () => {
        if (!this.touchGestures) {
            this.touchGestures = true
            this.ptzContainer.on('pan', this.myPan)
            this.ptzContainer.on('pinch', this.myPinch)
        }
    }

    disableGestures = () => {
        if (this.touchGestures) {
            this.touchGestures = false
            this.ptzContainer.off('pan', this.myPan)
            this.ptzContainer.off('pinch', this.myPinch)
            this.ptzContainer.destroy()
        }
    }

    ptz = (params) => {
        const { x, y, z } = params
        this.props.processMessage([{
            request: "ptz",
            method: "set",
            params: [{ 
                device_id: this.props.data?.id, 
                x: x,
                y: y,
                zoom: z
            }]
        }])
        .then(() => this.debug && console.log(`[PTZ ${this.webRTCStreamId}] moved to x ${x}, y ${y}, z ${z}`))
        .catch(error => console.error(`[PTZ ${this.webRTCStreamId}] ${error}`))
    }

    onPanLeftByButton = () => {
        var newSpeed = -this.speed
        this.ptz({ x: newSpeed, y: 0, z: 0 })
        this.intervalPtzByButton = setInterval(() => {
            this.ptz({ x: newSpeed, y: 0, z: 0 })
            newSpeed = newSpeed > -1 ? Math.round(((newSpeed - this.step) + Number.EPSILON) * 100) / 100 : -1
        }, this.intervalPtzByButtonSpeed)
    }

    onPanRightByButton = () => {
        var newSpeed = this.speed
        this.ptz({ x: newSpeed, y: 0, z: 0 })
        this.intervalPtzByButton = setInterval(() => {
            this.ptz({ x: newSpeed, y: 0, z: 0 })
            newSpeed = newSpeed < 1 ? Math.round(((newSpeed + this.step) + Number.EPSILON) * 100) / 100 : 1
        }, this.intervalPtzByButtonSpeed)
    }

    onTiltUpByButton = () => {
        var newSpeed = this.speed
        this.ptz({ x: 0, y: newSpeed, z: 0 })
        this.intervalPtzByButton = setInterval(() => {
            this.ptz({ x: 0, y: newSpeed, z: 0 })
            newSpeed = newSpeed < 1 ? Math.round(((newSpeed + this.step) + Number.EPSILON) * 100) / 100 : 1
        }, this.intervalPtzByButtonSpeed)
    }

    onTiltDownByButton = () => {
        var newSpeed = -this.speed
        this.ptz({ x: 0, y: newSpeed, z: 0 })
        this.intervalPtzByButton = setInterval(() => {
            this.ptz({ x: 0, y: newSpeed, z: 0 })
            newSpeed = newSpeed > -1 ? Math.round(((newSpeed - this.step) + Number.EPSILON) * 100) / 100 : -1
        }, this.intervalPtzByButtonSpeed)
    }

    onZoomInByButton = () => {
        var newSpeed = 0.5
        this.ptz({ x: 0, y: 0, z: newSpeed })
        this.intervalPtzByButton = setInterval(() => {
            this.ptz({ x: 0, y: 0, z: newSpeed })
            newSpeed = newSpeed < 1 ? Math.round(((newSpeed + this.zoomStep) + Number.EPSILON) * 100) / 100 : 1
        }, this.intervalPtzByButtonSpeed)
    }

    onZoomOutByButton = () => {
        var newSpeed = -0.5
        this.ptz({ x: 0, y: 0, z: newSpeed })
        this.intervalPtzByButton = setInterval(() => {
            this.ptz({ x: 0, y: 0, z: newSpeed })
            newSpeed = newSpeed > -1 ? Math.round(((newSpeed - this.zoomStep) + Number.EPSILON) * 100) / 100 : -1
        }, this.intervalPtzByButtonSpeed)
    }

    onMoveEndByButton = () => {
        clearInterval(this.intervalPtzByButton)
        this.ptz({ x: 0, y: 0, z: 0 })
    }

    onZoomByMousewheel = (e) => {

        clearTimeout(this.zoomTimeout)

        var newDirection = e.nativeEvent.wheelDelta > 0

        // reset zoom speed if direction changes
        if (newDirection !== this.zoomDirection) {
            if (newDirection) {
                this.zoomSpeed = 0.5
            } else {
                this.zoomSpeed = -0.5
            }
        }

        // add zoom step to zoom speed depending on direction
        if (newDirection) {
            this.zoomSpeed = this.zoomSpeed < 1 ? Math.round(((this.zoomSpeed + this.zoomStep) + Number.EPSILON) * 100) / 100 : 1
        } else {
            this.zoomSpeed = this.zoomSpeed > -1 ? Math.round(((this.zoomSpeed - this.zoomStep) + Number.EPSILON) * 100) / 100 : -1
        }

        this.ptz({ x: 0, y: 0, z: this.zoomSpeed })

        // reset zoom speed if no mousewheel interaction for 1s
        this.zoomTimeout = setTimeout(() => {
            if (newDirection) {
                this.zoomSpeed = 0.5
            } else {
                this.zoomSpeed = -0.5
            }
        }, 1000)

        this.zoomDirection = newDirection
    }

    render() {

        const { error, statistics, isControlbarUsed, loading, isPipVisible, pipSrc } = this.state
        const { data } = this.props
        const isWebview = window?.ReactNativeWebView
        const isPtz = data?.ptz

        let playerProps = {
            autoPlay: true,
            muted: true,
            controls: true,
            playsInline: true,
            ref: node => this.videoNode = node // gives Video.js a reference to the video DOM element
        }

        return (
            <>
                {/* player */}
                <div
                    data-vjs-player
                    className="video-area"
                    ref={isPtz && !isControlbarUsed ? this.ptzRef : null}
                    onWheel={isPtz && !isControlbarUsed ? this.onZoomByMousewheel : () => {}}
                    style={{ 
                        cursor: isPtz && !isControlbarUsed ? "move" : "default",
                        display: error || loading ? 'none' : "block"
                    }}
                >
                    <video
                        {...playerProps}
                        className="video-js"
                        data-setup={isWebview || isMobile ? '{ "inactivityTimeout": 0 }' : null} // prevent the bar from being closed
                        id={data?.webrtc}
                        src={this.staticSource ? "https://jsoncompare.org/LearningContainer/SampleFiles/Video/MP4/sample-mp4-file.mp4" : null}
                    />
                    
                    {!!statistics && 
                    <p className="video-statistics">
                        {statistics}
                    </p>}

                    {/* picture in picture */}
                    {isPipVisible &&
                    pipSrc &&
                    <PictureInPicture imgSrc={pipSrc} />}
                </div>

                {/* loading spinner */}
                <Row style={{ display: loading ? "flex" : "none", alignItems: "center", justifyContent: "center", height: "100%", backgroundColor: "black" }}>
                    <Spin size="large" />
                </Row>

                {/* error message */}
                <Status is={error} />
            </>
        )
    }
}

export default connect(mapStateToProps,null)(Player);
