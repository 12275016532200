import React from 'react';
import { Radio, Icon, Divider } from 'antd';

class Controls extends React.PureComponent {

    render() {
        const { data, loading, onChange, selected } = this.props
        const id = selected?.stream_id
        
        return (
            <Radio.Group
                value={selected} 
                style={{ height: "100%", width: "100%", fontSize: "24px" }} 
                onChange={onChange}
            >
                <Radio.Button 
                    value={data?.[0]} 
                    className={id === 1 ? "specialRadioButtonChecked" : "specialRadioButton"}
                    disabled={!data?.[0] || loading}
                >
                    <div className='specialRadioLabel'>
                        <Icon type="video-camera" className='specialRadioIcon' />
                        <span className='specialRadioText'>
                            {data?.[0]?.name || "Not assigned"}
                        </span>
                        <span className='specialRadioTextSmall'>
                            1
                        </span>
                    </div>
                </Radio.Button>

                {id === 1 || id === 2
                ? <Divider type="vertical" className="specialDividerInvisible" />
                : <Divider type="vertical" className="specialDivider" />}

                <Radio.Button 
                    value={data?.[1]} 
                    className={id=== 2 ? "specialRadioButtonChecked" : "specialRadioButton"} 
                    disabled={!data?.[1] || loading}
                >                            
                    <div className='specialRadioLabel'>
                        <Icon type="video-camera" className='specialRadioIcon' />
                        <span className='specialRadioText'>
                            {data?.[1]?.name || "Not assigned"}
                        </span>
                        <span className='specialRadioTextSmall'>
                            2
                        </span>
                    </div>
                </Radio.Button>

                {id === 2 || id === 3
                ? <Divider type="vertical" className="specialDividerInvisible" />
                : <Divider type="vertical" className="specialDivider" />}
                
                <Radio.Button 
                    value={data?.[2]}
                    className={id === 3 ? "specialRadioButtonChecked" : "specialRadioButton"}
                    disabled={!data?.[2] || loading}
                >                            
                    <div className='specialRadioLabel'>
                        <Icon type="video-camera" className='specialRadioIcon' />
                        <span className='specialRadioText'>
                            {data?.[2]?.name || "Not assigned"}
                        </span>
                        <span className='specialRadioTextSmall'>
                            3
                        </span>
                    </div>
                </Radio.Button>

                {id === 3 || id === 4
                ? <Divider type="vertical" className="specialDividerInvisible" />
                : <Divider type="vertical" className="specialDivider" />}

                <Radio.Button 
                    value={data?.[3]}
                    className={id === 4 ? "specialRadioButtonChecked" : "specialRadioButton"}
                    disabled={!data?.[3] || loading}
                >                            
                    <div className='specialRadioLabel'>
                        <Icon type="video-camera" className='specialRadioIcon' />
                        <span className='specialRadioText'>
                            {data?.[3]?.name || "Not assigned"}
                        </span>
                        <span className='specialRadioTextSmall'>
                            4
                        </span>
                    </div>
                </Radio.Button>

                {id === 4 || id === 5
                ? <Divider type="vertical" className="specialDividerInvisible" />
                : <Divider type="vertical" className="specialDivider" />}

                <Radio.Button 
                    value={data?.[4] || loading}
                    className={id === 5 ? "specialRadioButtonChecked" : "specialRadioButton"}
                    disabled={data?.filter(elem => elem.id).length < 2 || loading} // at least 2 real cameras that have ids (splitscreen dont)
                >                      
                    <div className='specialRadioLabel'>
                        <Icon type="appstore" className='specialRadioIcon' />
                        <Icon type="appstore" className='specialRadioIconSmall' />
                        <span className='specialRadioText'>
                            {data?.[4]?.name || "Split screen"}
                        </span>
                    </div>
                </Radio.Button>
            </Radio.Group>
        )
    }
}

export default Controls;
