import React from 'react';
import Status from '../../Status';
import ConfirmCancelButtons from '../../controls/ConfirmCancelButtons';
import { USERS } from '../../../constants/routes';
import { left, select } from "../../../api/actions";
import { Form, Input, Select } from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { NAME, NAME_MSG } from '../../../constants/regex';

// check if field has errors
function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

// dispatch actions left and select to Redux store to set currentLeft and record
function mapDispatchToProps(dispatch) {
    return {
        select: record => dispatch(select(record)),
        left: currentLeft => dispatch(left(currentLeft))
    };
}

// map record and socket state from Redux store to props
function mapStateToProps(state) {
    return { 
        record: state.record,
        processMessage: state.processMessage,
        access: state.access
    };
}

class UserForm extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            error: null,
            isLoaded: false,

            role: "user",
            roleOptions: [
                <Select.Option key={"maintainer"} value={"maintainer"}>Maintainer</Select.Option>,
                <Select.Option key={"user"} value={"user"}>User</Select.Option>
            ],
            // notifications: ["Alarms", "Errors"] 
        };

        this.show = this.show.bind(this);
    }

    // set state if fields value changes
    nameChange = value => this.setState({ name: value.target.value });
    passwordChange = value => this.setState({ password: value.target.value });
    roleChange = value => this.setState({ role: value });
    // emailChange = value => this.setState({ email: value.target.value });
    // notificationsChange = value => this.setState({ notifications: value });

    componentDidMount() {
        this.props.left("users"); // set initials in redux store
        this.props.form.validateFields(); // disable confirm button at the beginning
        this.show(); // call show() when isLoaded was unset
    }

    show() {

        this.setState({ isLoaded: false }, () => {

            if (this.props.access === "administrator") {
                
                var roleOptions = this.state.roleOptions;
                roleOptions.push(<Select.Option key={"administrator"} value={"administrator"}>Administrator</Select.Option>);
                this.setState({ roleOptions });
            }

            if (this.props.record) { // if edited record available

                this.setState({ 
                    name: this.props.record.name,
                    role: this.props.record.role,
                    // email: this.props.record.email,
                    // notifications: this.props.record.notifications
                });

                this.props.form.setFieldsValue({ 
                    name: this.props.record.name,
                    role: this.props.record.role.charAt(0).toUpperCase() + this.props.record.role.slice(1),
                    // email: this.props.record.email,
                    // notifications: this.props.record.notifications
                });
            }
        
            this.setState({ isLoaded: true })
        });
    }

    confirm() {

        this.setState({ isLoaded: false }, () => {
            this.props.processMessage([{
                request: "users",
                method: "set",
                params: [{   
                        id: this.props.record ? this.props.record.id : 0, // id of edited record or 0 for new record
                        password: this.state.password ? this.state.password : "", // null instead of empty string e.g. if input was cleared
                        name: this.state.name ? this.state.name : null,
                        role: this.state.role ? this.state.role.charAt(0).toLowerCase() + this.state.role.slice(1) : null,
                        // email: this.state.email ? this.state.email : null,
                        // notifications: this.state.notifications ? this.state.notifications : null,
                }] 
            }])
            .then(() => { 
                this.props.history.push(USERS); // push Users path to history props to call Users
                this.props.select(null); // reset record props in redux store
            })
            .catch(error => {
                console.error(error);
                this.setState({ error });
            })
        });
    }

    cancel() {
        this.props.history.push(USERS); // link to Users with router
    }

    componentWillUnmount() {
        this.props.select(null); // reset record props in redux store
    }

    // show form and set layout when isLoaded is true or status when error
    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        const { error, role, isLoaded, roleOptions } = this.state;
        const { record } = this.props;

        // only show error after a field is touched
        const nameError = isFieldTouched('name') && getFieldError('name');
        const passwordError = isFieldTouched('password') && getFieldError('password');

        if (!error) {
            return <>
                <Form labelCol={{ xs: { span: 24 }, sm: { span: 5 } }} wrapperCol={{ xs: { span: 24 }, sm: { span: 15 } }} style={{ margin: "1.5em" }}>

                    <Form.Item label="Name" style={{ marginBottom: 0 }} validateStatus={nameError ? 'error' : ''} help={nameError || ''}>
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: 'Please enter a name!' }, { pattern: NAME, message: NAME_MSG }],
                            onChange: this.nameChange,
                        })(
                            <Input placeholder="Please enter a username" disabled={!isLoaded} />
                        )}
                    </Form.Item>

                    <Form.Item label="Password" style={{ marginBottom: 0 }} validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                        {getFieldDecorator('password', {
                            rules: [{ required: record ? false : true, message: 'Please enter a password!' }, { pattern: NAME, message: NAME_MSG }],
                            onChange: this.passwordChange,
                        })(
                            <Input.Password placeholder="Please enter a user password" disabled={!isLoaded} />
                        )}
                    </Form.Item>

                    <Form.Item label="Email" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('email', {
                            onChange: this.emailChange,
                            rules: [
                                { type: "email", message: "Please enter a valid Email!" },
                                { pattern: NAME, message: NAME_MSG }
                            ]      
                        })(
                            <Input placeholder="Please enter an email address" disabled />
                        )}
                    </Form.Item>

                    <Form.Item label="Role" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('role', {
                            onChange: this.roleChange,
                            initialValue: role.charAt(0).toUpperCase() + role.slice(1)
                        })(
                            <Select disabled={!isLoaded}>
                                {roleOptions}
                            </Select>
                        )}
                    </Form.Item>

                    {/* <Form.Item label="Notifications" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('notifications', {
                            onChange: this.notificationsChange,
                            initialValue: notifications
                        })(
                            <Select mode="multiple" disabled>
                                <Select.Option value="Alarms">Alarms</Select.Option>
                                <Select.Option value="Errors">Errors</Select.Option>
                                <Select.Option value="Infos">Infos</Select.Option>
                            </Select>
                        )}
                    </Form.Item> */}

                </Form>

                <ConfirmCancelButtons 
                    loading={!isLoaded} 
                    disabled={hasErrors(getFieldsError())}
                    onConfirm={this.confirm.bind(this)} 
                    onCancel={this.cancel.bind(this)}
                />
            </>
        } else {
            return <Status is={error} back={USERS} />
        }
    }
}

export default Form.create() (connect(mapStateToProps,mapDispatchToProps)(withRouter(UserForm)));