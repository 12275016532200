import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import Status from '../Status';
import Player from './Player';
import Timeline from './Timeline';
import MediaQuery from 'react-responsive'
import { Form, Select, DatePicker, Table, Button, Icon, Radio, Affix, Divider, Modal, List, Spin, Layout } from 'antd';
import { connect } from "react-redux";
import { select, left, top } from "../../api/actions";
import { ARCHIVE } from '../../constants/routes';
import { sleep } from '../Wrapper'
import { isMobileApp, saveFile, sendDataToMobileApp } from '../Utilities';

function mapStateToProps(state) {
    return { 
        access: state.access,
        unit: state.unit,
        record: state.record,
        processMessage: state.processMessage
    }
}

function mapDispatchToProps(dispatch) {
    return {
        select: record => dispatch(select(record)),
        left: currentLeft => dispatch(left(currentLeft)),
        top: currentTop => dispatch(top(currentTop))
    }
}

var isMounted = false

class Archive extends React.PureComponent {
    
    _expandedRowKeys = new Set();

    constructor(props) {

        super(props);

        this.currentTime = dayjs().add(1, 'minute')
        this.limit = window.config.NOTIFICATION_LIMIT || 1000
        this.debug = window.config.DEBUG

        this.state = {
            data: [],
            device: null,
            expandedRowKeys: Array.from(this._expandedRowKeys.values()),
            job: null,
            src: null,
            startTime: dayjs().startOf("day"),
            endTime: dayjs().endOf("day"),
            interval: "1d",
            intervalJs: [1, 'day'],
            isLoaded: false,
            isVisible: false,
            error: null,
            record: null,
            title: "",
            minStartTime: dayjs().startOf("day").subtract(1, "month"),
            maxEndTime: dayjs().endOf("day").add(1, "month")
        };
    }

    componentDidMount() {
        isMounted = true
        this.props.top("archive")
        this.props.left(null)
        this.show()
        this.currentTimeTimer = setInterval(() => this.currentTime = dayjs().add(1, 'minute'), 10 * 1000) // update currentTime every 10s
        this.autoRefreshTimer = setInterval(() => this.show(), 5 * 60 * 1000) // refresh archive every 5m
        window.addEventListener('resize', this.resetSelection)
    }

    componentWillUnmount() {
        isMounted = false
        this.stop()
        clearInterval(this.currentTimeTimer)
        clearInterval(this.autoRefreshTimer)
        window.removeEventListener('resize', this.resetSelection)
    }

    disabledStart = value => {
        if (!value || !this.state.endTime) 
            return false;

        var beforeUnixZero = value < dayjs(0)
        var afterEndTime = value > this.state.endTime

        return beforeUnixZero || afterEndTime;
    }
    
    disabledEnd = value => {
        if (!value || !this.state.startTime) 
            return false

        var beforeStartTime = value < this.state.startTime
        return beforeStartTime
    }

    startChange = value => {
        this.resetSelection()
        this.resetInterval()
        this.setState({ startTime: value })
    }

    endChange = value => {
        this.resetSelection()
        this.resetInterval()
        this.setState({ endTime: value })
    }
    
    intervalChange = value => {
        
        let elem = value.target?.type === "radio" ? value.target.value : value
        let start = null
        let end = null

        this.setState({ interval: elem })

        switch (elem) {

            case "1h": 

                this.setState({ intervalJs: [1, 'hour'] })

                start = dayjs().startOf("hour")
                end = dayjs().endOf("hour")

                this.resetSelection()
                this.resetStartEnd(start, end)

                break;

            case "1d":

                this.setState({ intervalJs: [1, 'day'] })

                start = dayjs().startOf("day")
                end = dayjs().endOf("day")

                this.resetSelection()
                this.resetStartEnd(start, end)

                break;

            case "7d": 

                this.setState({ intervalJs: [7, 'day'] })

                start = dayjs().startOf("week").add(1, "day")
                end = dayjs().endOf("week").add(1, "day")

                this.resetSelection()
                this.resetStartEnd(start, end)
                
                break;

            case "1m": 

                this.setState({ intervalJs: [1, 'month'] })

                start = dayjs().startOf("month")
                end = dayjs().endOf("month")

                this.resetSelection()
                this.resetStartEnd(start, end)

                break;

            case "all":

                this.show(true)

                break;

            default: 
                break;
        }
    }

    zoomIn = () => {

        var { record } = this.props

        var start = record.newStart
        var end = record.newEnd

        this.resetSelection()
        this.resetInterval()
        this.resetStartEnd(start, end)
    }

    zoomOut = () => {

        var { startTime, endTime } = this.state

        var diff = endTime.diff(startTime)
        var start = startTime.subtract(diff/2)
        var end = endTime.add(diff/2)

        this.resetSelection()
        this.resetInterval()
        this.resetStartEnd(start, end)
    }

    stepLeft = () => {

        var { startTime, endTime } = this.state

        var diff = endTime.diff(startTime)
        var start = startTime.subtract(diff/2)
        var end = endTime.subtract(diff/2)

        this.resetSelection()
        this.resetInterval()
        this.resetStartEnd(start, end)
    }

    stepRight = () => {

        var { startTime, endTime } = this.state

        var diff = endTime.diff(startTime)
        var start = startTime.add(diff/2)
        var end = endTime.add(diff/2)

        this.resetSelection()
        this.resetInterval()
        this.resetStartEnd(start, end)
    }

    resetSelection = () => {
        if (!this.props.record?.resetSelection) {
            this.props.select({ resetSelection: true })
        }
    }

    resetInterval = () => {
        isMounted && this.props.form.setFieldsValue({
            interval: "None"
        })

        this.setState({ 
            interval: null
        })
    }
    
    resetStartEnd = (start, end, showAll = false) => {

        isMounted && this.props.form.setFieldsValue({
            startTime: showAll ? dayjs(start*1000) : start,
            endTime: showAll ? dayjs(end*1000) : end,
        })

        this.setState({ 
            startTime: showAll ? dayjs.unix(start) : start,
            endTime: showAll ? dayjs.unix(end) : end,
        }, () => !showAll && this.show())
    }

    show(showAll = false) {

        this.setState({ isLoaded: false, isLoadedShow: false }, () => { 

            this.props.processMessage([
                {
                    request: "archive",
                    method: "get",
                    params: [{
                        device_id: this.state.device ? this.state.device : 0,
                        start: (this.state.startTime && !showAll) ? this.state.startTime.unix() : null,
                        end: (this.state.endTime && !showAll) ? this.state.endTime.unix() : null,
                    }] 
                },
                {
                    request: "notifications",
                    method: "get",
                    params: [{
                        triggered: (this.state.startTime && !showAll) ? this.state.startTime.unix() : 0,
                        start: (this.state.startTime && !showAll) ? this.state.startTime.unix() : 0,
                        end: (this.state.endTime && !showAll) ? this.state.endTime.unix() : 2147483647,
                    }],
                    pagination: {
                        pageSize: this.limit+1,
                        options: [{
                            filter: "gt",
                            sort: "descend"
                        }]
                    }
                }
            ])
            .then(res => {

                var archive = res["archive"].params;
                var notifications = res["notifications"].params;
                var total = {};
                var lowestStartTime = 0;
                var highestEndTime = 0;
                var startTimeRecord = 0;
                var endTimeRecord = 0;
 
                archive.forEach(elem => {

                    const records = elem.records;

                    this.debug && console.log("raw recordings", records)
                            
                    records.forEach(elem => {

                        if (!elem.event) 
                            elem["event"] = "Recording";

                        if (elem.readOnly) 
                            elem["event"] = "Recording (locked)";

                        if (showAll && elem.start > 0 && elem.end > 0) {

                            startTimeRecord = elem.start;
                            endTimeRecord = elem.end;
    
                            if (!lowestStartTime || !highestEndTime) {
    
                                lowestStartTime = elem.start;
                                highestEndTime = elem.end;
    
                            } else if (startTimeRecord < lowestStartTime) {
    
                                lowestStartTime = startTimeRecord;
    
                            } else if (highestEndTime < endTimeRecord) {
    
                                highestEndTime = endTimeRecord;
                            }
                        }
                    })

                    if (showAll) {
                        this.resetSelection()
                        this.resetStartEnd(lowestStartTime, highestEndTime, showAll)
                    }

                    total[elem.device.name] = elem;
                })

                this.debug && console.log("raw notifications", notifications)

                notifications.forEach(elem => {

                    if (!elem.start) 
                        elem["start"] = elem.triggered;

                    if (!elem.end) 
                        elem["end"] = elem.triggered;

                    if (!(elem.device in total)) {
                        total[elem.device] = { 
                            device: { 
                                id: elem.device_id ? elem.device_id : 0, 
                                name: elem.device ? elem.device : "General Notifications"
                            }, 
                            records: [] 
                        };
                    }

                    total[elem.device].records.push(elem);
                })

                archive = Object.values(total);

                this.debug && console.log("merged data", archive)

                if (JSON.stringify(this.state.data) !== JSON.stringify(archive)) {
                    this.setState({ data: archive });
                }
            })
            .catch(error => {
                console.error(error)
                this.setState({ error })
            })
            .finally(() => this.setState({ isLoaded: true, isLoadedShow: true }))
        });
    }

    play(record) {
        this.setState({ isLoaded: false }, () => { 
            this.props.processMessage([{
                request: "replay",
                method: "get",
                params: [{
                    device_id: record.device.id,
                    start: this.props.record.newStart ? this.props.record.newStart.unix() : 0,
                    end: this.props.record.newEnd ? this.props.record.newEnd.unix() : 0
                }] 
            }])
            .then(res => {
                const { location, config: { BUILD, HTTPS, HTTPPORT, SERVER } } = window
                const { unit: { proxyKey} } = this.props
        
                const protocol = BUILD ? (location.protocol + "//") : (HTTPS ? "https://" : "http://");
                const server = BUILD ? location.hostname : SERVER;
                const port = BUILD ? (location.port ? (":" + location.port) : "") : (HTTPPORT ? (":" + HTTPPORT) : "");
                const path = proxyKey ? ("/unit/file/" + proxyKey) : "";
                const relFilepath = "/" + res["replay"].params[0].playlistPath; // absolute path to m3u8 playlist from db

                const hlsConfig = protocol + server + port + path + relFilepath;

                this.debug && console.log("hlsConfig", hlsConfig);

                this.setState({
                    src: hlsConfig,
                    title: record.device.name
                }, () => this.setState({ isVisible: true }));
            })
            .catch(error => {
                console.error(error);
                this.setState({ error });
            })
            .finally(() => this.setState({ isLoaded: true }));
        });
    }

    stop() {
        this.setState({ 
            isVisible: false, 
            isLoaded: false 
        }, () => { 
            this.props.processMessage([{ 
                request: "replay",
                method: "set",
                params: []
            }])
            .catch(error => {
                console.error(error);
                this.setState({ error });
            })
            .finally(() => this.setState({ 
                src: null, 
                title: "", 
                isLoaded: true 
            }));
        });
    }

    lock(record) {

        this.setState({ isLoaded: false }, () => { 
            this.props.processMessage([{
                request: "archive",
                method: "set",
                params: [{
                    device_id: record.device.id,
                    start: this.props.record.newStart ? this.props.record.newStart.unix() : 0,
                    end: this.props.record.newEnd ? this.props.record.newEnd.unix() : 0
                }] 
            }])
            .then(() => this.show())
            .catch(error => {
                console.error(error);
                this.setState({ 
                    error,
                    isLoaded: true
                })
            })
        });
    }

    upload(record) {

        this.setState({ isLoaded: false }, () => { 
            this.props.processMessage([{
                request: "upload",
                method: "set",
                params: [{
                    device_id: record.device.id,
                    start: this.props.record.newStart ? this.props.record.newStart.unix() : 0,
                    end: this.props.record.newEnd ? this.props.record.newEnd.unix() : 0
                }] 
            }])
            .then(() => this.show())
            .catch(error => {
                console.error(error);
                this.setState({ 
                    error, 
                    isLoaded: true })
            })
        });
    }
    
    async download(fileName, playlistFolder, exportFilename, chunkNext = null) {

        try {
            // wait for the fin flag
            let finFetchReq;
            do {
                // check if fin file exists on the nvr
                finFetchReq = await fetch(playlistFolder + "/fin");
                await sleep(1000);
            } while (!finFetchReq.ok); // export is ready for download

            if (isMobileApp) {
                sendDataToMobileApp(playlistFolder + "/" + fileName);
            } else {
                saveFile(playlistFolder + "/" + fileName, exportFilename);
            }

            // export completed
            if (chunkNext) {
                // export next chunk
                this.export_chunked(chunkNext);
            } else {
                this.stop();
            }
        } catch (ex) {
            var error = {};
            error.msg = ex.message;
            error.type = "error";
            console.error(ex.message, ex.name); 
            this.setState({ error })          
        } finally {
            this.setState({
                isLoaded: true,
                loadingExport: false
            })
        }
    }

    /*
    - check the requested duration if it is longer than MaxChunkLen_s
    - if so, split the requested duration into chunks of equal length 
      - chunkNumber = Math.trunc( (dur + MaxChunkLen_s - 1) / MaxChunkLen_s )
      - chunkDuration = Math.trunc( dur / chunkNumber )
    - export each chunk separately by calling export_chunked() recursively with chunkNext describing the remaining duration
    - preparation of chunks is done step by step, but download is done in parallel
    - example for a duration of 30 with MaxChunkLen = 10
        - export_chunked( number: 1, start: 0, end: 30 )
            - download( number: 2 , start: 10, end: 30 )
                - downloadUrlToFile( export_NVR1_0_10.mp4 )
                - export_chunked( number: 2, start: 10, end: 30 )
                    - download( number: 3, start: 20, end: 30 )
                        - downloadUrlToFile( export_NVR1_10_20.mp4 )
                        - export_chunked( number: 3, start: 20, end: 30 )
                            - downloadUrlToFile( export_NVR1_20_30.mp4 )
    */
    export_chunked(chunk) {
        const MaxChunkLen_s = 30 * 60; // 30 minutes
        
        let dur = chunk.end - chunk.start;

        if (dur > MaxChunkLen_s ) {
            const chunkNumber = Math.trunc( (dur + MaxChunkLen_s - 1) / MaxChunkLen_s );
            if (chunkNumber > 1) {
                dur = Math.trunc( dur / chunkNumber );
            }
        }

        const tsStart = chunk.start;
        const tsEnd = chunk.start + dur;
        const chunkNext = (tsEnd !== chunk.end) ? { start : tsEnd, end : chunk.end,  device_id: chunk.device_id, device_name: chunk.device_name, number: chunk.number + 1 } : null;
        const sDur = Math.trunc(dur/60) + "." + ("" + (100 + dur%60)).substring(1,3);
        this.debug && console.log("export_chunked: chunk:" + chunk.number + " [" + tsStart + " - " + tsEnd + "] (" + sDur + ")" );

        this.props.processMessage([{
            request: "replay",
            method: "get",
            params: [{
                device_id: chunk.device_id,
                start: chunk.start,
                end: chunk.start + dur,
                output_type: "MP4"
            }] 
        }])
        .then(res => {
            const { location, config: { BUILD, HTTPS, HTTPPORT, SERVER } } = window
            const { unit: { proxyKey} } = this.props

            const playlistPath = res["replay"].params[0].playlistPath; // relative path to output folder + optional export-filename (e.g. export.mp4)
            const fileNameList = playlistPath.match(/[^/]+\.[^./]+$/);
            const fileName = fileNameList ? fileNameList[0] : "export.mp4";
            
            const protocol = BUILD ? (location.protocol + "//") : (HTTPS ? "https://" : "http://");
            const server = BUILD ? location.hostname : SERVER;
            const port = BUILD ? (location.port ? (":" + location.port) : "") : (HTTPPORT ? (":" + HTTPPORT) : "");
            const path = proxyKey ? ("/unit/file/" + proxyKey) : "";
            const playlistPathWithoutFileName = playlistPath.replace(/\/?[^/]+$/, '');
            const playlistFolder = protocol + server + port + path + playlistPathWithoutFileName;

            this.debug && console.log("playlistFolder", playlistFolder)

            const dd = new Date(tsStart * 1000);
            const year = dd.getYear() + 1900;
            const mon = ("" + (dd.getMonth() + 100)).substring(1,3);
            const day = ("" + (dd.getDay() + 100)).substring(1,3);
            const hh = ("" + (dd.getHours() + 100)).substring(1,3);
            const mm = ("" + (dd.getMinutes() + 100)).substring(1,3);
            const ss = ("" + (dd.getSeconds() + 100)).substring(1,3);
            const exportFilename = "export_" + chunk.device_name + "_" + year + mon + day + "_" + hh + mm + ss + ".mp4"

            this.download(fileName, playlistFolder, exportFilename, chunkNext);
        })
        .catch(error => {
            if (chunkNext) {
                // try to export next chunk
                this.export_chunked(chunkNext);
            } else {
                console.error(error);
                this.setState({ 
                    error, 
                    isLoaded: true,
                    loadingExport: false
                })
            }
        })
    }

    export(record) {

        this.setState({ isLoaded: false, loadingExport: true }, () => 
            this.export_chunked({ 
                number : 1,
                start : this.props.record.newStart ? this.props.record.newStart.unix() : 0,
                end : this.props.record.newEnd ? this.props.record.newEnd.unix() : 0,
                device_id: record.device.id,
                device_name: record.device.name, 
            }))
    }

    // delete(record) {
    //     this.setState({ isLoaded: false }, () => {
    //         this.props.processMessage([{
    //             request: "archive",
    //             method: "del",
    //             params: [{
    //                 device_id: record.device.id,
    //                 start: (this.props.record && this.props.record.newStart) ? this.props.record.newStart.unix() : 0,
    //                 end: (this.props.record && this.props.record.newEnd) ? this.props.record.newEnd.unix() : this.currentTime.unix()
    //             }] 
    //         }])
    //         .then(() => this.show())
    //         .catch(error => {
    //             console.error(error);
    //             this.setState({ 
    //                 error,
    //                 isLoaded: true
    //             })
    //         })
    //     })
    // }

    render() {
        
        const { getFieldDecorator } = this.props.form
        const { record, access } = this.props
        const { error, data, isLoaded, startTime, interval, endTime, title, isVisible, src, loadingExport, minStartTime, maxEndTime } = this.state
        const isUser = access && access === "user"
        const isZoomInDisabled = record ? !record.enableZoomIn : true
        const isPlayDisabled = isLoaded && record && record.newEnd && record.newStart ? ((record.newEnd.unix() - record.newStart.unix()) > (40 * 60) || (record.newEnd.unix() - record.newStart.unix()) < 10) : true // min. 10s max. 40min
        const isExportDisabled = isLoaded && record && record.newEnd && record.newStart && !isUser ? ((record.newEnd.unix() - record.newStart.unix()) > (24 * 60 * 60) || (record.newEnd.unix() - record.newStart.unix()) < 10) : true // min. 10s max. 24h
        const isTagDisabled = isLoaded && record && record.newEnd && record.newStart ? ((record.newEnd.unix() - record.newStart.unix()) > (24 * 60 * 60) || (record.newEnd.unix() - record.newStart.unix()) < 10) : true // min. 10s max. 24h
        const isUnlockDisabled = !isLoaded || !record || !record.newStart || !record.newEnd || isUser
        const isZoomOutDisabled = startTime < minStartTime || endTime > maxEndTime

        const LargeHeader = (
            <Form 
                layout="inline"
                style={{ 
                    marginTop: "4px", 
                    marginBottom: "4px", 
                    whiteSpace: "nowrap", 
                    height: "32px" // fixed height to prevent linebreak
                }} 
            >

                <Button 
                    icon="left"
                    onClick={this.stepLeft}
                    disabled={!isLoaded}
                    style={{ marginRight: 4 }}
                />
                
                <Form.Item style={{ marginRight: 0 }}>
                    {getFieldDecorator('startTime', {
                        onChange: this.startChange,
                        initialValue: startTime
                    })(
                        <DatePicker
                            showTime
                            format="YYYY-MM-DD HH:mm:ss"
                            disabledDate={this.disabledStart}
                            allowClear={false}
                            placeholder="From"
                            suffixIcon
                            style={{ 
                                minWidth: 165,
                                width: 165
                            }}
                            disabled={!isLoaded}
                            // onOpenChange={open => !open && this.show()}
                        />
                    )}
                </Form.Item>

                <Form.Item 
                    style={{ 
                        minWidth: 'calc(100% - 2*(165px) - 2*(4px) - 2*(32px))',
                        marginRight: 0,
                    }}
                >
                    <Form.Item style={{ marginRight: 4 }}>
                        {getFieldDecorator('interval', {
                            onChange: this.intervalChange,
                            initialValue: interval
                        })(
                            <Radio.Group 
                                buttonStyle="solid" 
                                style={{ textAlign: "center" }}
                                disabled={!isLoaded}
                            >
                                <Radio.Button value={"1h"}>Hour</Radio.Button>
                                <Radio.Button value={"1d"}>Day</Radio.Button>
                                <Radio.Button value={"7d"}>Week</Radio.Button>
                                <Radio.Button value={"1m"}>Month</Radio.Button>
                                <Radio.Button value={"all"}>All</Radio.Button>
                            </Radio.Group>                
                        )}
                    </Form.Item>

                    <Button 
                        icon="reload"
                        onClick={() => this.show()}
                        disabled={!isLoaded}
                    />
                </Form.Item>

                <Form.Item style={{ marginRight: 0 }}>
                    {getFieldDecorator('endTime', {
                        onChange: this.endChange,
                        initialValue: endTime
                    })(
                        <DatePicker
                            showTime
                            format="YYYY-MM-DD HH:mm:ss"
                            disabledDate={this.disabledEnd}
                            allowClear={false}
                            placeholder="To"
                            suffixIcon
                            style={{ 
                                minWidth: 165,
                                width: 165
                            }}
                            disabled={!isLoaded}
                            // onOpenChange={open => !open && this.show()}
                        />
                    )}
                </Form.Item>

                <Button 
                    icon="right"
                    onClick={this.stepRight}
                    disabled={!isLoaded}
                    style={{ marginLeft: 4 }}
                />

            </Form>
        )

        const MediumHeader = (
            <Form 
                layout="inline"
                style={{ 
                    marginTop: "4px", 
                    marginBottom: "4px", 
                    whiteSpace: "nowrap", 
                    height: "32px" // fixed height to prevent linebreak
                }} 
            >
                
                <Form.Item style={{ marginRight: 4 }}>
                    {getFieldDecorator('startTime', {
                        onChange: this.startChange,
                        initialValue: startTime
                    })(
                        <DatePicker
                            showTime
                            format="YYYY-MM-DD HH:mm:ss"
                            disabledDate={this.disabledStart}
                            allowClear={false}
                            placeholder="From"
                            suffixIcon
                            style={{ 
                                minWidth: 165,
                                width: 165
                            }}
                            disabled={!isLoaded}
                            // onOpenChange={open => !open && this.show()}
                        />
                    )}
                </Form.Item>

                <Form.Item 
                    style={{ 
                        minWidth: 'calc(100% - 2*(165px) - 2*(4px))',
                        marginRight: 4 
                    }}
                >
                    <Form.Item style={{ marginRight: 2 }}>
                        {getFieldDecorator('interval', {
                            onChange: this.intervalChange,
                            initialValue: interval
                        })(              
                            <Select 
                                style={{ textAlign: "center", width: 84 }}
                                disabled={!isLoaded}
                            >
                                <Select.Option value={"1h"}>Hour</Select.Option>
                                <Select.Option value={"1d"}>Day</Select.Option>
                                <Select.Option value={"7d"}>Week</Select.Option>
                                <Select.Option value={"1m"}>Month</Select.Option>
                                <Select.Option value={"all"}>All</Select.Option>
                            </Select>
                        )}
                    </Form.Item>

                    <Button 
                        icon="reload"
                        onClick={() => this.show()}
                        disabled={!isLoaded}
                    />
                </Form.Item>

                <Form.Item style={{ marginRight: 0 }}>
                    {getFieldDecorator('endTime', {
                        onChange: this.endChange,
                        initialValue: endTime
                    })(
                        <DatePicker
                            showTime
                            format="YYYY-MM-DD HH:mm:ss"
                            disabledDate={this.disabledEnd}
                            allowClear={false}
                            placeholder="To"
                            suffixIcon
                            style={{ 
                                minWidth: 165,
                                width: 165
                            }}
                            disabled={!isLoaded}
                            // onOpenChange={open => !open && this.show()}
                        />
                    )}
                </Form.Item>

            </Form>
        )

        const SmallHeader = (
            <Form layout="inline">
                <Form.Item style={{ marginLeft: 4, marginRight: 0 }}>
                    {getFieldDecorator('startTime', {
                        onChange: this.startChange,
                        initialValue: startTime
                    })(
                        <DatePicker
                            showTime
                            format="YY-MM-DD"
                            disabledDate={this.disabledStart}
                            allowClear={false}
                            placeholder="From"
                            style={{ 
                                minWidth: 110,
                                width: 110
                            }}
                            disabled={!isLoaded}
                            // onOpenChange={open => !open && this.show()}
                        />
                    )}
                </Form.Item>

                <Form.Item 
                    style={{ 
                        minWidth: 'calc(100% - 2*(4px) - 2*(110px) - 2*(4px))',
                        marginLeft: 4,
                        marginRight: 0,
                        textAlign: "center"
                    }}
                >
                    <Form.Item style={{ marginRight: 2 }}>
                        {getFieldDecorator('interval', {
                            onChange: this.intervalChange,
                            initialValue: interval
                        })(              
                            <Select 
                                style={{ textAlign: "center", width: 84 }}
                                disabled={!isLoaded}
                            >
                                <Select.Option value={"1h"}>Hour</Select.Option>
                                <Select.Option value={"1d"}>Day</Select.Option>
                                <Select.Option value={"7d"}>Week</Select.Option>
                                <Select.Option value={"1m"}>Month</Select.Option>
                                <Select.Option value={"all"}>All</Select.Option>
                            </Select>
                        )}
                    </Form.Item>

                    <Button 
                        icon="reload"
                        onClick={() => this.show()}
                        style={{ lineHeight: 1.2 }}
                        disabled={!isLoaded}
                    />
                </Form.Item>

                <Form.Item style={{ marginLeft: 4, marginRight: 4 }}>
                    {getFieldDecorator('endTime', {
                        onChange: this.endChange,
                        initialValue: endTime
                    })(
                        <DatePicker
                            showTime
                            format="YY-MM-DD"
                            disabledDate={this.disabledEnd}
                            allowClear={false}
                            placeholder="To"
                            style={{ 
                                minWidth: 110,
                                width: 110
                            }}
                            disabled={!isLoaded}
                            // onOpenChange={open => !open && this.show()}
                        />
                    )}
                </Form.Item>
            </Form>
        )

        const MediumArchive = (
            <Table
                style={{ height: data.length ? "100%" : "unset" }}
                className='archive-table'
                rowKey={record => record.device ? record.device.id : null}
                dataSource={data}
                loading={false}
                pagination={false}
                size="small"
                columns={[
                    {
                        dataIndex: 'device.name',
                        render: elem => elem ? elem : "None"
                    },
                    {
                        dataIndex: "records",
                        align: "center",
                        title: () => (
                            <>
                                <MediaQuery maxWidth={1230}>
                                    {MediumHeader}
                                </MediaQuery>
                                <MediaQuery minWidth={1231}>
                                    {LargeHeader}
                                </MediaQuery>
                            </>
                        ),
                        render: records => true ? 
                            <Timeline data={[startTime.unix(), endTime.unix(), records]} /> : 
                            <Spin indicator={<Icon type="loading" style={{ fontSize: 48 }} spin />} />
                    },
                    {
                        render: elem => elem.device?.id ? <>
                            <Button 
                                onClick={this.play.bind(this, elem)}
                                type="link" 
                                disabled={isPlayDisabled}
                            >
                                <Icon type="caret-right" />Play
                            </Button>

                            <Divider style={{ margin: 0 }} />

                            <Button 
                                type="link"
                                onClick={this.lock.bind(this, elem)} 
                                disabled={isUnlockDisabled}
                            >
                                <Icon type="lock" />Un-/Lock
                            </Button>
                            
                            <Divider style={{ margin: 0 }} />

                            <Button 
                                type="link"
                                onClick={this.upload.bind(this, elem)} 
                                disabled={isTagDisabled}>
                                <Icon type="tag" />Tag
                            </Button>

                            <Divider style={{ margin: 0 }} />

                            <Button 
                                type="link"
                                onClick={this.export.bind(this, elem)}
                                loading={loadingExport}
                                icon='export'
                                disabled={isExportDisabled}
                            >
                                Export
                            </Button>

                            {/* <Divider style={{ margin: 0 }} />

                            <Popconfirm 
                                title="Are you sure?"
                                okText='Yes'
                                okType='danger'
                                cancelText='No'
                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                onConfirm={this.delete.bind(this, elem)}
                            >
                                <Button 
                                    type="link" 
                                    disabled={isUser || !isLoaded}
                                >
                                    <Icon type="delete" />Delete
                                </Button>
                            </Popconfirm> */}
                        </> : null
                    },
                ]}
            />
        )

        const SmallArchive = (
            <List 
                header={SmallHeader}
                rowKey={record => record.device ? record.device.id : null}
                dataSource={data}
                loading={false}
                pagination={false}
                size="small"
                renderItem={elem => (
                    <List.Item style={{ paddingLeft: 4, paddingRight: 4, paddingTop: 12, paddingBottom: 16 }}>
                        {elem.device?.name ? 
                        <div style={{ textAlign: "center", width: "100%", fontWeight: "bold", marginBottom: 6 }}>
                            {elem.device.name}
                        </div> : null}
                        
                        {true ? 
                        <Timeline data={[startTime.unix(), endTime.unix(), elem.records]} /> :
                        <Spin style={{ width: "100%", textAlign: "center" }} indicator={<Icon type="loading" style={{ fontSize: 36 }} spin />} />}
                        
                        {elem.device?.id ?
                        <Button.Group style={{ width: "100%", textAlign: "center", marginTop: 6 }}>
                            <Button
                                size='small'
                                onClick={this.play.bind(this, elem)}
                                disabled={isPlayDisabled}
                            >
                                <Icon type="caret-right" />Play
                            </Button>

                            <Button
                                size='small'
                                onClick={this.lock.bind(this, elem)} 
                                disabled={isUnlockDisabled}
                            >
                                <Icon type="lock" />Un-/Lock
                            </Button>

                            <Button
                                size='small'
                                onClick={this.upload.bind(this, elem)} 
                                disabled={isTagDisabled}>
                                <Icon type="tag" />Tag
                            </Button>

                            <Button
                                size='small'
                                onClick={this.export.bind(this, elem)}
                                loading={loadingExport}
                                icon='export'
                                disabled={isExportDisabled}
                            >
                                Export
                            </Button>
                            
                            {/* <Popconfirm 
                                title="Are you sure?"
                                okText='Yes'
                                okType='danger'
                                cancelText='No'
                                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                onConfirm={this.delete.bind(this, elem)}
                            >
                                <Button
                                    size='small'
                                    disabled={!isLoaded || isUser}
                                    icon='delete'
                                >
                                    Delete
                                </Button>
                            </Popconfirm> */}

                        </Button.Group> : null}
                    </List.Item>
                )}
            />
        )

        return (
            <Layout>
                <Layout.Content style={{
                    marginTop: "48px",
                    marginBottom: "32px",
                    height: 'calc(100% - 80px)'
                }}>
                    {!error ?
                    <Fragment>
                        <MediaQuery minWidth={593} onChange={active => active && this.show()}>
                            {MediumArchive}
                        </MediaQuery>
                    
                        <MediaQuery maxWidth={592} onChange={active => active && this.show()}>
                            {SmallArchive}
                        </MediaQuery>

                        <Modal
                            title={title}
                            centered
                            visible={isVisible}
                            footer={null}
                            onCancel={this.stop.bind(this)}
                            destroyOnClose // unmount child components on onClose, doubly secured by reset src state
                            bodyStyle={{ padding: 0 }}
                        >
                            <Player src={src} />
                        </Modal>

                        <Affix offsetBottom="0" className="affix">

                            <Button.Group style={{ width: "100%" }}>

                                <Button 
                                    type="primary" 
                                    onClick={this.zoomOut} 
                                    disabled={!isLoaded || isZoomOutDisabled} 
                                    style={{ width: "50%", borderBottomLeftRadius: 0, marginLeft: 0 }}
                                    icon='zoom-out'
                                >
                                    Zoom Out
                                </Button>

                                <Button 
                                    type="primary" 
                                    onClick={this.zoomIn} 
                                    disabled={!isLoaded || isZoomInDisabled} 
                                    style={{ width: "50%", borderBottomRightRadius: 0, marginLeft: 0 }}
                                    icon='zoom-in'
                                >
                                    {isZoomInDisabled ? "To zoom in, first mark a time range" : "Zoom In"}
                                </Button>

                            </Button.Group>

                        </Affix>
                    </Fragment> :
                    <Status is={error} back={ARCHIVE} />}
                </Layout.Content>
            </Layout>
        )
    }
}

export default Form.create() (connect(mapStateToProps,mapDispatchToProps)(Archive));