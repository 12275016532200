import React from 'react';
import Sider from './Sider';
import MediaQuery from 'react-responsive'
import Content from './Content';
import { withRouter } from "react-router-dom";
import { Layout } from 'antd';
import { connect } from "react-redux";
import { top } from "../../api/actions";

// dispatch action top to Redux store to set currentTop
function mapDispatchToProps(dispatch) {
    return {
        top: currentTop => dispatch(top(currentTop)),
    };
}

class Setup extends React.PureComponent {

    componentDidMount() {
        this.props.top("setup")
    }

    render() {

        return (
            <>
                <MediaQuery minWidth={593}>
                    <Layout>
                        <div className='mediumLayout'>
                            <Layout.Sider 
                                theme="light" 
                                width="160"
                                style={{
                                    top: "44px",
                                    overflow: 'auto',
                                    height: 'calc(100% - 44px)',
                                    position: 'fixed'
                                }}
                            >
                                <Sider />
                            </Layout.Sider>
        
                            <Content />
                        </div>
                    </Layout>
                </MediaQuery>
                <MediaQuery maxWidth={592}>
                    <Layout>
                        <div className='smallLayout'>
                            <Content isSmallLayout />
                        </div>
                    </Layout>
                </MediaQuery>
            </>
        )
    }
}

export default connect(null, mapDispatchToProps)(withRouter(Setup));