import React from 'react';
import Status from '../../Status';
import Highlighter from 'react-highlight-words';
import CancelButton from '../../controls/CancelButton';
import { withRouter } from "react-router-dom";
import { Table, Button, Icon, BackTop, Input } from 'antd';
import { connect } from "react-redux";
import { onvif } from "../../../api/actions";
import { DEVICEFORM } from '../../../constants/routes';

// dispatch action onvif to Redux store to set device
function mapDispatchToProps(dispatch) {
    return {
        onvif: device => dispatch(onvif(device))
    };
}

// map socket state from Redux store to props
function mapStateToProps(state) {
    return { 
        sortMessage: state.sortMessage,
        unit: state.unit
    };
}

class DeviceDiscover extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false
        }

        this.show = this.show.bind(this);
    }

    componentDidMount() {

        this.props.onvif(null); // reset device props in redux store
        this.show(); // show devices table
    }

    show() {

        const { location, config: { BUILD, HTTPS, HTTPPORT, SERVER, ONVIF, DEBUG } } = window
        const { unit: { proxyKey} } = this.props

        const protocol = BUILD ? (location.protocol + "//") : (HTTPS ? "https://" : "http://");
        const server = BUILD ? location.hostname : SERVER;
        const port = BUILD ? (location.port ? (":" + location.port) : "") : (HTTPPORT ? (":" + HTTPPORT) : "");
        const path = proxyKey ? ("/unit/file/" + proxyKey) : "";
        const onvif = ONVIF ?? "/discovery";
        
        const onvifConfig = protocol + server + port + path + onvif;

        DEBUG && console.log("onvifConfig", onvifConfig)

        this.setState({ isLoaded: false }, () => {
            fetch(onvifConfig)
            .then(res => {
                if (res.ok) {
                    return res.json() 
                } else {
                    var error = {};
                    error.msg = res.statusText;
                    error.type = "error";
                    console.error(res.status, res.statusText); 
                    this.setState({ error })
                }
            })  
            // .then(data => this.setState({ data: data.filter(elem => !elem.types.includes("dn:NVR_WCCTV")) })) // filter NVR itself if types including dn:NVR_WCCTV
            .then(data => this.setState({ data: data.filter(elem => elem.hostname && (elem.hostname !== location.hostname))})) // filter NVR itself by hostname
            .finally(() => this.setState({ isLoaded: true }))
        });
    }

    add(device) {
        
        this.props.onvif(device); // set device in redux store by selected row
        this.props.history.push(DEVICEFORM); // push Devices path to history props to call Devices
    }

    cancel() {
        
        this.props.onvif(null); // reset device props in redux store
        this.props.history.push(DEVICEFORM); // push Devices path to history props to call Devices
    }

    getColumnSearchProps = dataIndex => ({
        
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => { this.searchInput = node; }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />

                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>

                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),

        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#EE3350' : undefined }} />
        ),

        onFilter: (value, record) => {
            if (record[dataIndex])
                return record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
        },

        onFilterDropdownVisibleChange: visible => {
            if (visible) setTimeout(() => this.searchInput.select());
        },

        render: text => {
            if (text) { 
                if (this.state.searchedColumn === dataIndex) { 
                    return <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[this.state.searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                } else {
                    return text; 
                }
            } else { 
                return "-" 
            }
        }
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    render() {

        const { error, data, isLoaded } = this.state;
    
        /*
            {
                "hostname": "IPCAM",
                "urn": "um:uuid:5f5a69c2-e0ae-504f-829b-00DE0B33F8B0",
                "hardware": "IPCAM",
                "uri": "http://192.168.188.176:8080/onvif/device_service",
                "proto": "http",
                "ipaddr": "192.168.188.176:8080",
                "ipv6": false
            },
        */

        // show table when isLoaded is true or show status when error
        if (!error) {
            return <>
                <Table 
                    size="small"
                    rowKey={device => device.hostname}
                    dataSource={data}
                    loading={!isLoaded}
                    pagination={false} 
                    columns={[
                        {
                            title: 'Name',
                            dataIndex: 'hostname',
                            sorter: (a, b) => this.props.sortMessage({ a, b, value: "hostname" }),
                            ...this.getColumnSearchProps('hostname')
                        },
                        {
                            title: 'Address',
                            dataIndex: 'ipaddr',
                            sorter: (a, b) => this.props.sortMessage({ a, b, value: "ipaddr" }),
                            ...this.getColumnSearchProps('ipaddr')        
                        },
                        {
                             title: 'Model',
                             dataIndex: 'hardware',
                             sorter: (a, b) => this.props.sortMessage({ a, b, value: "hardware" }),
                             ...this.getColumnSearchProps('hardware')
                        },
                        {
                            title: 'Control',
                            // width: 130,
                            render: device => 
                                <Button type="link" onClick={this.add.bind(this, device)}>
                                    <Icon type="plus" />Add
                                </Button>
                        },
                    ]}
                />

                <CancelButton onCancel={this.cancel.bind(this)} />

                <BackTop style={{
                    right: "5px",
                    bottom: "unset",
                    top: "52px",
                }}/>
            </>
        } else {
            return <Status is={error} back={DEVICEFORM} />
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(DeviceDiscover));