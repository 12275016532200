import React from 'react';
import Status from '../../Status';
import ConfirmCancelButtons from '../../controls/ConfirmCancelButtons';
import { NAME, NAME_MSG, NUMBER, NUMBER_MSG } from "../../../constants/regex";
import { ACTIONS } from '../../../constants/routes';
import { Form, Button, Select, Input, Upload, Tooltip, Radio, message } from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { left, select } from "../../../api/actions";

function hasErrors(fieldsError, type) {
    if (type === "PlayAudioFile" || type === "SendMail" || type === "Heitel") {
        fieldsError.device = undefined
    }

    return Object.keys(fieldsError).some(field => fieldsError[field])
}

function mapDispatchToProps(dispatch) {
    return {
        select: record => dispatch(select(record)),
        left: currentLeft => dispatch(left(currentLeft))
    }
}
function mapStateToProps(state) {
    return { 
        record: state.record,
        processMessage: state.processMessage,
        unit: state.unit
    };
}

class ActionForm extends React.PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            devices: [],
            deviceOptions: [],
            presetOptions: [],
            portOptions: [],
            audioFileOptions: [],
            error: null,
            isLoaded: false,
            loadingPtzPresets: false,
            loadingAudioFiles: false,
            mail: "custom",
            preTagSecs: 10,
            postTagSecs: 50
        };

        this.show = this.show.bind(this)
        this.nvrLabel = "NVR-Device"
        this.debug = window.config.DEBUG
    }

    componentDidMount() {
        this.props.left("actions")
        this.props.form.validateFields()
        this.setState({ isLoaded: false }, () => this.show())
    }

    stateChange = value => this.setState({ state: value })
    nameChange = value => this.setState({ name: value.target.value })
    filenameChange = value => this.setState({ filename: value })
    presetChange = value => this.setState({ preset: value })
    portChange = value => this.setState({ port: value })
    recipientChange = value => this.setState({ recipient: value.target.value })
    mailChange = value => this.setState({ mail: value.target.value })
    preTagSecsChange = value => this.setState({ preTagSecs: value.target.value })
    postTagSecsChange = value => this.setState({ postTagSecs: value.target.value })

    deviceChange = value => {
        if (value) {
            this.setState({ device: value }, () => {
                if (this.state.type === "RelayOutput") {     
                    this.resetPortState()           
                    this.initPortOptions()
                }
        
                if (this.state.type === "ControlPTZ") {
                    this.resetPresetState()
                    this.initPresetOptions()
                }
            })
        }
    }

    typeChange = value => {
        if (value) {
            this.setState({ type: value }, () => {
                if (value === "RelayOutput") {
                    this.resetPortState()
                    this.initPortOptions()
                    this.initDeviceOptions(true)
                }
            
                if (value === "ControlPTZ") {
                    this.resetPresetState()
                    this.initPresetOptions()
                    this.initDeviceOptions()
                }

                if (value === "PlayAudioFile") {
                    this.initAudioFileOptions()
                }

                if (value === "Record" || 
                    value === "SendMail" ||
                    value === "Heitel" ||
                    value === "TagRecord") {
                    this.initDeviceOptions()
                } 
            })
        }
    }   

    initPortOptions() {
        this.setState({ portOptions: [] }, () => {
            const relay_outputs = this.state.devices?.find(elem => elem.id === this.state.device?.key)?.relay_outputs;
        
            if (relay_outputs?.length) {

                var portOptions = [];

                for (let i=0; i<relay_outputs.length; i++) 
                    portOptions.push(<Select.Option key={i} value={relay_outputs[i]}>{relay_outputs[i]}</Select.Option>);

                this.setState({ portOptions })
        }})
    }

    resetPortState() {
        this.setState({ port: undefined })
        this.props.form.setFieldsValue({ port: undefined })
    }

    initPresetOptions() {
        this.setState({ presetOptions: [] }, () => {
            const deviceId = this.state.device?.key;
            if (deviceId) {
                this.setState({ loadingPtzPresets: true }, () => {
                    this.props.processMessage([
                        {
                            request: "ptz_presets",
                            method: "set",
                            params: [{ 
                                id: deviceId,
                                value: ""
                            }]
                        },
                        {
                            request: "ptz_presets",
                            method: "get",
                            params: [{ id: deviceId }]
                        }
                    ])
                    .then(res => { 
                        const presets = res["ptz_presets"]?.params;
                        var presetOptions = [];
        
                        for (let i=0; i<presets?.length; i++) {
                            presetOptions.push(<Select.Option key={i} value={presets[i].token}>{presets[i].name}</Select.Option>)
                        }
                        
                        this.setState({ presetOptions });
                    })
                    .catch(error => {
                        console.error(error);
                        this.setState({ error });
                    })
                    .finally(() => this.setState({ loadingPtzPresets: false }))
                })
            } 
        })
    }

    resetPresetState() {
        this.setState({ preset: undefined })
        this.props.form.setFieldsValue({ preset: undefined })
    }

    initDeviceOptions(withNvr = false) {

        let devices = [...this.state.devices]
        let deviceOptions = []
        let elem = null
        
        if (withNvr) {
            for (let i=0; i<devices.length; i++) {
                elem = devices[i]
                deviceOptions.push(<Select.Option key={elem.id} value={elem.id}>{!elem.type ? this.nvrLabel : elem.name}</Select.Option>) // rename 1337_N3TC0_NVR to nvrLabel
            }
        } else {
            var devicesWithoutNvr = devices.filter(device => device.type)

            var selectedDeviceIsNvr = 
                this.state.device?.label === this.nvrLabel || 
                this.state.device?.label === "1337_N3TC0_NVR"

            for (let i=0; i<devicesWithoutNvr.length; i++) {
                elem = devicesWithoutNvr[i]
                deviceOptions.push(<Select.Option key={elem.id} value={elem.id}>{elem.name}</Select.Option>)
            }

            if (selectedDeviceIsNvr) {
                this.setState({ device: undefined })
                this.props.form.setFieldsValue({ device: undefined })
            }
        }

        this.setState({ deviceOptions })
    }

    initAudioFileOptions(afterDelete = false, newFilename = "") {
        this.setState({ 
            audioFileOptions: [],
            loadingAudioFiles: true 
        }, () => {
            this.props.processMessage([{
                request: "files",
                method: "get",
                params: [{ type: "audio" }] 
            }])
            .then(res => { 
                const audioFiles = res["files"]?.params || []
                const audioFileOptions = audioFiles.map(elem => <Select.Option key={elem.filename} value={elem.filename}>{elem.filename}</Select.Option>)
                const hasSelectedFilename = audioFiles.some(elem => elem.filename === this.state.filename)

                this.setState({ audioFileOptions })

                if (!hasSelectedFilename) {
                    this.updateAudioFilename(undefined)
                }

                if (afterDelete) {
                    this.updateAudioFilename(audioFileOptions.length ? audioFileOptions[0].key : undefined)
                }

                if (newFilename) {
                    this.updateAudioFilename(newFilename)
                }
            })
            .catch(error => {
                console.error(error)
                this.setState({ error })
            })
            .finally(() => this.setState({ loadingAudioFiles: false }))
        })
    }

    updateAudioFilename(filename) {
        this.setState({ filename })
        this.props.form.setFieldsValue({ filename })
    }

    uploadAudioFiles = (info) => {
        this.setState({ loadingAudioFiles: true }, () => {
            if (info.file.status === 'done') {
                message.success(`${info.file.name} uploaded successfully.`)
                this.initAudioFileOptions(false, info.file.name)
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} upload failed.`)
                this.setState({ loadingAudioFiles: false })
            }
        })
    }

    deleteAudioFiles = (name) => {
        this.setState({ loadingAudioFiles: true }, () => {
            this.props.processMessage([{
                request: "files",
                method: "del",
                params: [{ 
                    type: "audio",
                    filename: name
                }] 
            }])
            .then(() => this.initAudioFileOptions(true))
            .catch(error => {
                console.error(error)
                this.setState({ 
                    error,
                    loadingAudioFiles: false
                })
            })
        })
    }

    show() {

        const { record } = this.props

        var req = [{
            request: "devicesnosecrets",
            method: "get",
            params: [{ id: 0 }] 
        }]

        if (record?.device && record.type === "ControlPTZ") {
            req.push({
                request: "ptz_presets",
                method: "get",
                params: [{ id: record.device.id }]
            })
        }

        if (record?.type === "PlayAudioFile") {
            req.push({
                request: "files",
                method: "get",
                params: [{ type: "audio" }] 
            })
        }

        this.props.processMessage(req)
        .then(res => { 
            const devices = res["devicesnosecrets"]?.params
            let device = null
            this.setState({ devices }, () => this.initDeviceOptions(true))

            if (record) {

                this.setState({ 
                    name: record.name,
                    type: record.type
                })
    
                this.props.form.setFieldsValue({ 
                    name: record.name,
                    type: record.type
                })
    
                if (record.device) {

                    device = { key: record.device.id, label: record.device.name }
                    this.setState({ device })
                    this.props.form.setFieldsValue({ device })

                } else if (record.type === "RelayOutput") {

                    var nvr = devices.filter(elem => !elem.type)?.[0]
                    device = { key: nvr.id, label: this.nvrLabel }
                    this.setState({ device })
                    this.props.form.setFieldsValue({ device })

                }
    
                if (record.params) { 
    
                    const params = record.params

                    switch (record.type) {
    
                        case "ControlPTZ":
                            if (params.token) 
                                this.setState({ preset: params.token });
                            this.initPresetOptions()
                            this.initDeviceOptions()
                            break;
                        
                        case "Heitel":
                            break;
                        
                        case "PlayAudioFile":
                            if (params.filename) 
                                this.setState({ filename: params.filename });
                            this.initAudioFileOptions()
                            break;
    
                        case "Record":
                            this.initDeviceOptions()
                            break;

                        case "RelayOutput":
                            if (params.port) 
                                this.setState({ port: params.port });
                            if (params.state)
                                this.setState({ state: params.state });
                            this.initPortOptions()
                            break;
                        
                        case "SendMail": 
                            if (params.type) 
                                this.setState({ mail: params.type });
                            if (params.recipient) 
                                this.setState({ recipient: params.recipient });
                            this.initDeviceOptions()
                            break;

                        case "TagRecord":
                            this.setState({ 
                                preTagSecs: params.preTagSecs ?? 0,
                                postTagSecs: params.postTagSecs ?? 0
                            });
                            this.initDeviceOptions()
                            break;
    
                        default: 
                            break;
                    } 
                } 
            } 
        })
        .catch(error => {
            console.error(error);
            this.setState({ error });
        })
        .finally(() => this.setState({ isLoaded: true }));
    }

    confirm() {

        const { device, type, name, filename, preset, port, state, mail, recipient, preTagSecs, postTagSecs } = this.state;
        const { record } = this.props;

        let req = [{   
            id: record ? record.id : 0, // id of edited record or 0 for new record
            name: name ? name : null, // null instead of empty string e.g. if input was cleared
            device:
                device 
                && type !== "Heitel" 
                && type !== "PlayAudioFile" 
                    ? device.key 
                    : null,
            type: type ? type : null,
            params: {
                filename: (type === "PlayAudioFile" && filename) ? filename : null,
                token: (type === "ControlPTZ" && preset) ? preset : null,
                port: (type === "RelayOutput" && port) ? port : null,
                state: (type === "RelayOutput" && state) ? state : null,
                type: (type === "SendMail" && mail) ? mail : null,
                recipient: (type === "SendMail" && recipient) ? recipient : null,
                preTagSecs: (type === "TagRecord" && preTagSecs) ? parseInt(preTagSecs) : 0,
                postTagSecs: (type === "TagRecord" && postTagSecs) ? parseInt(postTagSecs) : 0
            }
        }]

        this.debug && console.log("set simpleactions", req)

        this.setState({ isLoaded: false }, () => {
            this.props.processMessage([{
                request: "simpleactions",
                method: "set",
                params: req
            }])
            .then(() => { 
                this.props.history.push(ACTIONS); // push Devices path to history props to call Devices
                this.props.select(null); // reset record props in redux store
            })
            .catch(error => {
                console.error(error);
                this.setState({ error });
            })
        });
    }

    cancel() {
        this.props.select(null)
        this.props.history.push(ACTIONS)
    }

    componentWillUnmount() {
        this.props.select(null)
    }

    validateTagSecs = (rule, value, callback) => {
        const { getFieldValue } = this.props.form

        var sum = parseInt(getFieldValue("preTagSecs")) + parseInt(getFieldValue("postTagSecs"))

        if (sum >= 10 && sum <= 1200) {
            callback()
        } else {
            callback("The sum of pre- and post-tagging must be at least 10 and may not exceed 1200.")
        }
    }

    render() {
        const { location, config: { BUILD, HTTPS, HTTPPORT, SERVER, PROCESS_UPLOAD, DEBUG } } = window
        const { unit: { proxyKey}, form: { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched, getFieldValue }  } = this.props
        const { loadingAudioFiles, audioFileOptions, recipient, name, state, portOptions, preset, presetOptions, loadingPtzPresets, isLoaded, error, device, deviceOptions, type, filename, mail, preTagSecs, postTagSecs } = this.state;

        const nameError = isFieldTouched('name') && getFieldError('name');
        const typeError = isFieldTouched('type') && getFieldError('type');
        const deviceError = isFieldTouched('device') && getFieldError('device');

        const protocol = BUILD ? (location.protocol + "//") : (HTTPS ? "https://" : "http://");
        const server = BUILD ? location.hostname : SERVER;
        const port = BUILD ? (location.port ? (":" + location.port) : "") : (HTTPPORT ? (":" + HTTPPORT) : "");
        const path = proxyKey ? ("/unit/file/" + proxyKey) : "";
        const upload = PROCESS_UPLOAD ?? "/process-upload";

        const uploadConfig = protocol + server + port + path + upload;

        DEBUG && console.log("uploadConfig", uploadConfig)

        if (!error) {
            return <>
                <Form labelCol={{ xs: { span: 24 }, sm: { span: 5 } }} wrapperCol={{ xs: { span: 24 }, sm: { span: 15 } }} style={{ margin: "1.5em" }}>

                    <Form.Item label="Name" style={{ marginBottom: 0 }} validateStatus={nameError ? 'error' : ''} help={nameError || ''}>
                        {getFieldDecorator('name', {
                            rules: [
                                { required: true, message: "Please enter a name!" }, 
                                { pattern: NAME, message: NAME_MSG }
                            ],
                            onChange: this.nameChange,
                            initialValue: name
                        })(
                            <Input placeholder="Please enter an action name" disabled={!isLoaded} />           
                        )}
                    </Form.Item>

                    <Form.Item 
                        label="Device" 
                        style={{ marginBottom: 0 }} 
                        validateStatus={deviceError ? 'error' : ''} 
                        help={device && type === "SendMail"
                            ? "A snapshot of this device will be attached to the email."
                            : (deviceError || '')
                        }
                    >
                        {getFieldDecorator('device', {
                            rules: [{ 
                                required: (type === "PlayAudioFile" || type === "SendMail" || type === "Heitel" ) ? false : true, 
                                message: "Please select a device!"
                            }],
                            onChange: this.deviceChange
                        })(
                            <Select 
                                labelInValue 
                                placeholder={type === "SendMail" ? "Select a device to attach a snapshot of it to the email" : "Please select a device"} 
                                disabled={!isLoaded || type === "PlayAudioFile" || type === "Heitel"}
                            >
                                {deviceOptions}
                            </Select>               
                        )}
                    </Form.Item>

                    <Form.Item label="Type" style={{ marginBottom: 0 }} validateStatus={typeError ? 'error' : ''} help={typeError || ''}>
                        {getFieldDecorator('type', {
                            rules: [{ required: true, message: "Please select a type!" }],
                            onChange: this.typeChange,
                            initialValue: type
                        })(
                            <Select placeholder="Please select an action" disabled={!isLoaded}>
                                <Select.Option value="ControlPTZ">Control PTZ</Select.Option>
                                <Select.Option value="Heitel">Heitel Alarm</Select.Option>
                                <Select.Option value="PlayAudioFile">Play Audio</Select.Option>
                                <Select.Option value="Record">Record Video</Select.Option>
                                <Select.Option value="RelayOutput">Relay Output</Select.Option>
                                <Select.Option value="SendMail">Send Mail</Select.Option>
                                <Select.Option value="TagRecord">Tag Record</Select.Option>
                            </Select>               
                        )}
                    </Form.Item>

                    {(() => {
                        switch (type) {

                            case 'ControlPTZ':
                                return (
                                    <Form.Item label={<span className="ant-form-item-required">Preset</span>} style={{ marginBottom: 0 }}>
                                        <Form.Item style={{ marginBottom: 0, display: 'inline-block', width: 'calc(100% - 38px)' }}>
                                            {getFieldDecorator('preset', {
                                                rules: [{ required: true, message: "Please select a preset!" }],
                                                onChange: this.presetChange,
                                                initialValue: preset
                                            })(
                                                <Select 
                                                    placeholder={device ? (presetOptions.length ? "Please select a preset" : "Device has no presets") : "Please select a device"}
                                                    disabled={!isLoaded || loadingPtzPresets || !device || !presetOptions.length} 
                                                    loading={loadingPtzPresets}
                                                >
                                                    {presetOptions}
                                                </Select>      
                                            )}
                                        </Form.Item>

                                        <span style={{ display: 'inline-block', width: '6px'}}></span>

                                        <Form.Item style={{ marginBottom: 0, marginTop: "1px", display: 'inline-block', width: '32px' }}>
                                            <Tooltip title="Reload">
                                                <Button 
                                                    icon="reload" 
                                                    onClick={() => this.initPresetOptions()} 
                                                    disabled={!isLoaded || loadingPtzPresets || !device}
                                                    loading={loadingPtzPresets} 
                                                />
                                            </Tooltip>
                                        </Form.Item>

                                    </Form.Item>
                                )

                            case 'RelayOutput':
                                return <>
                                    <Form.Item label="Port" style={{ marginBottom: 0 }}>
                                        {getFieldDecorator('port', {
                                            rules: [{ required: true, message: "Please select a port!" }],
                                            onChange: this.portChange,
                                            initialValue: this.state.port
                                        })(
                                            <Select 
                                                placeholder={device ? (portOptions.length ? "Please select a port" : "Device has no outputs") : "Please select a device"} 
                                                disabled={!isLoaded || !device || !portOptions.length}
                                            >
                                                {portOptions}
                                            </Select>                                         
                                        )}
                                    </Form.Item>
                                    <Form.Item label="State" style={{ marginBottom: 0 }}>
                                        {getFieldDecorator('state', {
                                            onChange: this.stateChange,
                                            rules: [{ required: true, message: "Please select a state!" }],
                                            initialValue: state
                                        })(
                                            <Select 
                                                placeholder={device ? (this.state.port ? "Please select a state" : (portOptions.length ? "Please select a port" : "Device has no outputs")) : "Please select a device"} 
                                                disabled={!isLoaded || !device || !portOptions.length || !this.state.port}
                                            >
                                                <Select.Option key={"active"} value={"active"}>Active</Select.Option>
                                                <Select.Option key={"inactive"} value={"inactive"}>Inactive</Select.Option>
                                            </Select>    
                                        )}
                                    </Form.Item>
                                </>
                                
                            case 'PlayAudioFile':
                                return (
                                    <Form.Item label={<span className="ant-form-item-required">Filename</span>} style={{ marginBottom: 0 }}>
                                        <Form.Item style={{ marginBottom: 0, display: 'inline-block', width: 'calc(100% - 38px - 38px)' }}>
                                            {getFieldDecorator('filename', {
                                                rules: [{ required: true, message: 'Please select a file!' }],
                                                onChange: this.filenameChange,
                                                initialValue: filename
                                            })(
                                                <Select 
                                                    placeholder={audioFileOptions.length ? "Please select a file" : "No file available"}
                                                    disabled={!isLoaded || loadingAudioFiles || !audioFileOptions.length}
                                                    loading={loadingAudioFiles}
                                                >
                                                    {audioFileOptions}
                                                </Select> 
                                            )}
                                        </Form.Item>
                                        
                                        <span style={{ display: 'inline-block', width: '6px'}}></span>
                
                                        <Form.Item style={{ marginBottom: 0, marginTop: "1px", display: 'inline-block', width: '32px' }}>
                                            <Button 
                                                icon="delete" 
                                                disabled={!isLoaded || loadingAudioFiles || !filename}
                                                loading={loadingAudioFiles}
                                                onClick={() => this.deleteAudioFiles(filename)}
                                            />
                                        </Form.Item>
                
                                        <span style={{ display: 'inline-block', width: '6px'}}></span>
                
                                        <Form.Item style={{ marginBottom: 0, marginTop: "1px", display: 'inline-block', width: '32px' }}>
                                            <Upload
                                                showUploadList={false}
                                                action={uploadConfig}
                                                onChange={this.uploadAudioFiles}
                                                accept='audio/mp3, audio/wav, audio/ogg, audio/aac, audio/mpeg'
                                            >
                                                <Button 
                                                    icon="upload" 
                                                    disabled={!isLoaded || loadingAudioFiles}
                                                    loading={loadingAudioFiles}
                                                />
                                            </Upload>
                                        </Form.Item>
                
                                    </Form.Item>
                                )
                                
                            case 'SendMail':
                                return <>
                                    <Form.Item label="Mail" style={{ marginBottom: 0 }}>
                                        {getFieldDecorator('mail', {
                                            onChange: this.mailChange,
                                            initialValue: mail
                                        })(
                                            <Radio.Group disabled={!isLoaded} style={{ width: "100%" }} buttonStyle="solid">
                                                <Radio.Button value="custom" style={{ width: "calc(100% / 3)", textAlign: "center" }}>Custom</Radio.Button>
                                                <Radio.Button value="sentinel" style={{ width: "calc(100% / 3)", textAlign: "center" }}>Sentinel</Radio.Button>
                                                <Radio.Button value="sureview" style={{ width: "calc(100% / 3)", textAlign: "center" }}>SureView</Radio.Button>
                                            </Radio.Group>                
                                        )}
                                    </Form.Item>

                                    <Form.Item label="Recipient" style={{ marginBottom: 0 }}>
                                        {getFieldDecorator('recipient', {
                                            onChange: this.recipientChange,
                                            rules: [
                                                { required: true, message: "Please enter a recipient address!" },
                                                { type: "email", message: "Please enter a valid Email!" },
                                                { pattern: NAME, message: NAME_MSG }
                                            ],
                                            initialValue: recipient,
                                        })(
                                            <Input placeholder="Please enter a recipient address" disabled={!isLoaded} />              
                                        )}
                                    </Form.Item>
                                </>

                            case 'TagRecord':
                                return (
                                    <>
                                        <Form.Item 
                                            label="Pre-Tagging" 
                                            style={{ marginBottom: 0 }}
                                        >
                                            {getFieldDecorator('preTagSecs', {
                                                onChange: this.preTagSecsChange,
                                                initialValue: preTagSecs,
                                                rules: [
                                                    { pattern: NUMBER, message: NUMBER_MSG },
                                                    { required: true, message: "Please enter a duration!" },
                                                    { validator: this.validateTagSecs }
                                                ]
                                            })(
                                                <Input 
                                                    placeholder="0" 
                                                    style={{ width: "100%" }} 
                                                    disabled={!isLoaded}
                                                    addonAfter="seconds"
                                                />
                                            )}
                                        </Form.Item>

                                        <Form.Item 
                                            label="Post-Tagging" 
                                            style={{ marginBottom: 0 }}
                                        >
                                            {getFieldDecorator('postTagSecs', {
                                                onChange: this.postTagSecsChange,
                                                initialValue: postTagSecs,
                                                rules: [
                                                    { pattern: NUMBER, message: NUMBER_MSG },
                                                    { required: true, message: "Please enter a duration!" },
                                                    { validator: this.validateTagSecs }
                                                ]
                                            })(
                                                <Input 
                                                    placeholder="0" 
                                                    style={{ width: "100%" }} 
                                                    disabled={!isLoaded}
                                                    addonAfter="seconds"
                                                />
                                            )}
                                        </Form.Item>
                                    </>
                                )

                            default: 
                                return null
                        }
                    })()}

                </Form>
            
                <ConfirmCancelButtons 
                    loading={!isLoaded} 
                    disabled={hasErrors(getFieldsError(),type) 
                        || (type === "PlayAudioFile" && !getFieldValue("filename")) 
                        || (type === "SendMail" && !getFieldValue("recipient"))
                        || (type === "ControlPTZ" && !getFieldValue("preset"))
                        || (type === "RelayOutput" && !getFieldValue("port"))
                        || (type === "RelayOutput" && !getFieldValue("state"))
                    }
                    onConfirm={this.confirm.bind(this)} 
                    onCancel={this.cancel.bind(this)}
                />
            </>
        } else {
            return <Status is={error} back={ACTIONS} /> 
        }
    }
}

export default Form.create() (connect(mapStateToProps,mapDispatchToProps)(withRouter(ActionForm)));