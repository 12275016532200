// https://stackoverflow.com/questions/52482865/how-to-create-ant-design-group-of-dynamic-fields
// https://github.com/ant-design/ant-design/issues/16404

import React from 'react';
import PropTypes from 'prop-types';

import { Form, Icon, Button } from 'antd';
import { connect } from "react-redux";

function mapStateToProps(state) {
    return { 
        record: state.record
    };
}

class ConditionlistDynamic extends React.PureComponent {

	id = 1;

	componentDidMount() {

		// show conditionlists depending on condition array length
        if (this.props.record)
            if (this.props.record.conditions)  
                for (let i=1; i<this.props.record.conditions.length; i++) 
                    this.add();
	}

	add = () => {

		const { getFieldValue, setFieldsValue, name } = 
			this.props,
			keys = getFieldValue(`${name}List`),
			nextKeys = keys.concat(this.id++);

		setFieldsValue({ [`${name}List`]: nextKeys });
	};

	remove = k => () => {

		const { getFieldValue, setFieldsValue, name } = 
			this.props,
			keys = getFieldValue(`${name}List`);

		if (keys.length === 1) return;

		setFieldsValue({ [`${name}List`]: keys.filter(key => key !== k) });
	};

	// validation handled in main component
	defaultValidation = (name) => (
		{
			// validateTrigger: ['onChange', 'onBlur'],
			// rules: [{
			// 	required: true,
			// 	message: `${name} is required`,
			// 	// message: `Please select a ${name}!`,
			// }],
			
			// onChange: this.change,
		}
	);

	addSingleField = () => {

		const { getFieldDecorator, getFieldValue, fields: obj, name } = this.props;

		getFieldDecorator(`${name}List`, { initialValue: [0] });

		const fieldCounter = getFieldValue(`${name}List`);

		if (fieldCounter.length > 1) {

			return fieldCounter.map((k) => (
				<div key={k}>
					<Form.Item key={k} style={{ marginBottom: 0, display: 'inline-block', width: 'calc(100% - 38px)' }}>
						{
							getFieldDecorator(`${name}[${k}]`, obj.validation || this.defaultValidation(name))(obj.field())
						}
						{
							// fieldCounter.length > 1 ? (
							// 	<Icon
							// 		className="dynamic-delete-button"
							// 		type="minus-circle-o"
							// 		onClick={this.remove(k)}
							// 	/>
							// ) : null
						}
					</Form.Item>

					<span style={{ display: 'inline-block', width: '6px'}}></span>

					<Form.Item style={{ marginBottom: 0, display: 'inline-block', width: '32px' }}>
						<Button onClick={this.remove(k)} icon="delete" />
					</Form.Item>
				</div>
			));

		} else {

			return fieldCounter.map((k) => (
				<Form.Item key={k} style={{ marginBottom: 0 }}>
					{
						getFieldDecorator(`${name}[${k}]`,
							obj.validation || this.defaultValidation(name))(obj.field())
					}
					{
						// fieldCounter.length > 1 ? (
						// 	<Icon
						// 		className="dynamic-delete-button"
						// 		type="minus-circle-o"
						// 		onClick={this.remove(k)}
						// 	/>
						// ) : null		
					}
				</Form.Item>
			));
		}
	};

	render() {
		return <>
			{this.addSingleField()}

			<Form.Item style={{ margin: 0 }}>

				<Button type="dashed" style={{ width: "100%" }} onClick={this.add} disabled={false}>
					<Icon type="plus" /> Add condition list
				</Button>

			</Form.Item>
		</>
	}
}

ConditionlistDynamic.propTypes = {
	name: PropTypes.string.isRequired,
	fields: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.arrayOf(PropTypes.object)
	]).isRequired,
	getFieldValue: PropTypes.func.isRequired,
	setFieldsValue: PropTypes.func.isRequired
};

export default connect(mapStateToProps,null)(ConditionlistDynamic);