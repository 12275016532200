import React from 'react';
import { withRouter } from "react-router-dom";
import { Result, Icon, Button, Affix, Row } from 'antd';
import { ARCHIVE, LOGIN, NOTIFICATIONS, MAINTENANCE } from '../constants/routes';

var message = null
var type = "error"

class Status extends React.PureComponent {

    // show warning, error or success and set route back from props
    render() {

        const { is, back, history } = this.props

        if (is) {

            if (typeof is.type !== "undefined") // if type in error object
                type = is.type

            if (typeof is.msg === "undefined") { // if no msg in error object
                message = is.toString() // convert error object to string
            } else { // if msg in error object
                if (typeof is.msg === "object") { // msg can either be an object or an string
                    message = is.msg.message
                } else {
                    message = is.msg
                }
            }
        }

        const controls = (
            <Affix 
                offsetBottom="0" 
                className={(
                    back === ARCHIVE 
                    || back === LOGIN 
                    || back === NOTIFICATIONS 
                    || back === MAINTENANCE) 
                    ? "affix" 
                    : "affixWithNav"
                }>
                <Button 
                    block
                    type="primary" 
                    onClick={() => {
                        history.replace(back + "/reload")
                        setTimeout(() => history.replace(back), 0)
                    }}
                >
                    <Icon type="close" />Cancel
                </Button>
            </Affix>
        )

        switch (type) {
            case "error": 
                return (
                    <Row 
                        type="flex"
                        style={{
                            alignItems: "center", 
                            justifyContent: "center", 
                            display: !!is ? "flex" : "none",
                            height: "100%", 
                            overflowY: "hidden",
                            width: "100%" 
                        }}
                    >
                        <Result 
                            status="error" 
                            icon={<Icon type="frown" theme="twoTone" twoToneColor="#EE3350" />}
                            title="Sorry, something went wrong." 
                            subTitle={message}
                            style={{ marginBottom: back ? "32px" : 0 }}
                        />

                        {back && controls}
                    </Row>
                )
            case "warning":
                return (
                    <Row 
                        type="flex"
                        style={{
                            alignItems: "center", 
                            justifyContent: "center", 
                            display: !!is ? "flex" : "none",
                            height: "100%", 
                            overflowY: "hidden",
                            width: "100%" 
                        }}
                    >
                        <Result 
                            status="warning" 
                            icon={<Icon type="meh" theme="twoTone" twoToneColor="#EE3350" />}
                            title="There are some problems with your operation."
                            subTitle={message}
                            style={{ marginBottom: back ? "32px" : 0 }}
                        />
    
                        {back && controls}
                    </Row>
                )     
            case "errorLive":
                return (
                    <Row 
                        type="flex"
                        style={{ 
                            alignItems: "center", 
                            justifyContent: "center", 
                            height: "100%", 
                            backgroundColor: "black",
                            display: !!is ? "flex" : "none"
                        }}
                    >
                        <Result 
                            status="error"
                            title={<div style={{ color: "white" }}>{message}</div>}
                            style={{ padding: 0 }}
                        />
                    </Row>
                )       
            case "infoLive":
                return (
                    <Row 
                        type="flex"
                        style={{ 
                            alignItems: "center", 
                            justifyContent: "center", 
                            height: "100%", 
                            backgroundColor: "black",
                            display: !!is ? "flex" : "none"
                        }}
                    >
                        <Result 
                            status="info"
                            title={<div style={{ color: "white" }}>{message}</div>}
                            style={{ padding: 0 }}
                        />
                    </Row>
                )       
            default: 
                return (
                    <Row 
                        type="flex"
                        style={{
                            alignItems: "center", 
                            justifyContent: "center", 
                            display: !!is ? "flex" : "none",
                            height: "100%", 
                            overflowY: "hidden",
                            width: "100%" 
                        }}
                    >
                        <Result 
                            status="success"
                            icon={<Icon type="smile" theme="twoTone" twoToneColor="#EE3350" />} 
                            title="Great, we have done all the operations!"
                            subTitle={message}
                        />
                    </Row>
                )
            }
        }
    }

export default withRouter(Status);