import React from 'react';
import { Icon, Menu, Button, Dropdown, Switch } from 'antd';

class RelayIcon extends React.PureComponent {

    render() {
        const { disabled, data, setData } = this.props

        const relayItems = data.map(elem => {
            return (
                <Menu.Item 
                    key={elem.key} 
                    style={{ cursor: "default" }}
                    disabled
                >
                    <Switch
                        checked={elem.value ? true : false}
                        checkedChildren={"Relay " + elem.key + " active"} 
                        unCheckedChildren={"Relay " + elem.key + " inactive"}
                        onClick={(checked) => setData(elem.key, checked)}
                    />
                </Menu.Item>
            )}
        )

        return (
            <Dropdown
                disabled={disabled || !data.length}
                overlay={<Menu>{relayItems}</Menu>}
            >
                <Button shape="circle" size="small">                
                    <Icon type="control" style={{ marginRight: 0 }} /> 
                </Button>
            </Dropdown>
        )
    }
}

export default RelayIcon;