import React from 'react';
import Status from '../../Status';
import AddButton from '../../controls/AddButton';
import { Table, Button, Icon, Divider, Badge, Tag, List, Popconfirm, BackTop } from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { EVENTFORM, EVENTS } from '../../../constants/routes';
import { select, left } from "../../../api/actions";

// dispatch actions select and left to Redux store to set record and currentLeft
function mapDispatchToProps(dispatch) {
    return {
        select: record => dispatch(select(record)),
        left: currentLeft => dispatch(left(currentLeft))
    };
}

// map socket state from Redux store to props
function mapStateToProps(state) {
    return { 
        processMessage: state.processMessage,
        sortMessage: state.sortMessage
    };
}

class Events extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            conditions: [],
            actions: [],
            error: null,
            isLoaded: false
        };

        this.show = this.show.bind(this);
    }

    componentDidMount() {
        this.props.select(null)
        this.props.left("events"); // set initials in redux store
        this.setState({ isLoaded: false }, () => this.show()); // show eventtable
    }

    show() {
        
        this.props.processMessage([{ 
            request: "events",
            method: "get",
            params: [{ id: 0 }]
        }])
        .then(res => {
            const events = res["events"].params;
            const eventsWithoutDi = events.filter(event => event.conditions.some(condition => condition.some(elem => !elem.includes("nvrdi"))));
            this.setState({ data: eventsWithoutDi });
        })
        .catch(error => {
            console.error(error);
            this.setState({ error });
        })
        .finally(() => this.setState({ isLoaded: true }));
    }

    edit(record) {
        this.props.select(record); // set record object in redux store by selected row
    }

    delete(id) {

        this.setState({ isLoaded: false }, () => {
            this.props.processMessage([
                { 
                    request: "events",
                    method: "del",
                    params: [{ id: id }]
                }
            ])
            .then(() => this.show())
            .catch(error => {
                console.error(error);
                this.setState({ 
                    error,
                    isLoaded: true
                });
            })
        });
    }

    render() {
        
        const { error, data, isLoaded } = this.state;

        // show table when isLoaded is true or show status when error
        if (!error) {
            return <>
                <Table
                    style={{ height: data.length ? "100%" : "unset" }}
                    rowKey={record => record.id} 
                    dataSource={data}
                    loading={!isLoaded}
                    pagination={false}
                    size="small"
                    scroll={{ x: 'max-content' }}
                    columns={[
                        {
                            title: 'State',
                            dataIndex: 'state',
                            sorter: (a, b) => this.props.sortMessage({ a, b, value: "state", numeric: true }),
                            render: state => <Badge status={state ? "success" : "error"} key={state ? "Active" : "Inactive"} text={state ? "Active" : "Inactive"} />
                        },
                        {
                            title: 'Name',
                            dataIndex: 'name',
                            sorter: (a, b) => this.props.sortMessage({ a, b, value: "name" }),
                            render: name => name ? name : "-"
                        },
                        {
                            title: 'Conditions',
                            dataIndex: 'conditions',
                            render: conditions => conditions
                                ? conditions.map((condition, i) => {
                                    let color;
                                    
                                    switch (i) {
                                        case 0: color = "red"; break;
                                        case 1: color = "volcano"; break;
                                        case 2: color = "orange"; break;
                                        case 3: color = "gold"; break;
                                        default: break;
                                    }
            
                                    if (typeof condition === "object") {
            
                                        return (
                                            <List size="small" style={{ marginBottom: ".3em" }} key={i}>
                                                {condition.map(element => <Tag color={color} key={element}>{element}</Tag>)}
                                            </List>
                                        );

                                    } else {
            
                                        return <Tag color={color} key={condition}>{condition}</Tag>
                                    }
                                })   
                                : "-"
                        },
                        {
                            title: 'Actions',
                            dataIndex: 'actions',
                            render: actions => actions ? actions.map(action => <Tag key={action}>{action}</Tag>) : "-"
                        },
                        {
                            title: 'Controls',
                            render: record => <>
                                <Link to={EVENTFORM}>
                                    <Button type="link" onClick={this.edit.bind(this, record)}>
                                        <Icon type="edit" />Edit
                                    </Button>
                                </Link>

                                <Divider type="vertical" />

                                <Popconfirm 
                                    title="Are you sure?"
                                    okText='Yes'
                                    okType='danger'
                                    cancelText='No'
                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                    onConfirm={this.delete.bind(this, record.id)}
                                >
                                    <Button type="link">
                                        <Icon type="delete" />Delete
                                    </Button>
                                </Popconfirm>
                            </>
                        }
                    ]}
                />
                
                <AddButton 
                    linkTo={EVENTFORM} 
                    loading={!isLoaded}
                />

                <BackTop 
                    style={{
                        right: "5px",
                        bottom: "unset",
                        top: "52px",
                    }}
                />
            </>
        } else {
            return <Status is={error} back={EVENTS} />
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Events);