import React from 'react';
import Viewer from './Viewer';
import Controls from './Controls';
import { Layout } from 'antd';
import { connect } from "react-redux";
import { top, left, setSelectedDevice, select } from "../../api/actions";
import { loadScript, unloadScript } from '../Utilities';
import { SETUP } from '../../constants/routes';

function mapStateToProps(state) {
    return { 
        processMessage: state.processMessage,
        selectedDevice: state.selectedDevice,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        left: currentLeft => dispatch(left(currentLeft)),
        top: currentTop => dispatch(top(currentTop)),
        setSelectedDevice: selectedDevice => dispatch(setSelectedDevice(selectedDevice)),
        select: record => dispatch(select(record))
    }
}

class Live extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            selectableDevices: [],
            viewableDevices: [],
            error: null,
            loading: false
        }
    }

    componentDidMount() {
        this.props.select(null)
        this.props.top("live")
        this.props.left(null)
        this.getStreamingDevices()
        loadScript("rtc")
    }

    componentWillUnmount() {
        unloadScript("rtc")
    }

    getStreamingDevices = () => {
        this.setState({ loading: true }, () => {
            this.props.processMessage([
                { 
                    request: "devicesnosecrets",
                    method: "get",
                    params: [{ id: 0 }]
                },
                { 
                    request: "liveparams",
                    method: "get",
                    params: []
                }
            ])
            .then(res => { 
                const devices = res["devicesnosecrets"].params
                const devicesStreaming = devices.filter(elem => elem.stream_id)
                const devicesStreamingSorted = devicesStreaming.sort((a,b) => a.stream_id - b.stream_id)

                if (!devicesStreamingSorted.length) {
                    this.props.history.push(SETUP)
                } else {
                    const liveParams = res["liveparams"] ? JSON.parse(res["liveparams"].params[0]) : null

                    this.setState({
                        ...this.setControlsData(devicesStreamingSorted),
                        ...this.setViewerData(devicesStreamingSorted, liveParams),
                        loading: false
                    })
                }
            })
            .catch(error => {
                console.error(error)
                this.setState({ 
                    error,
                    loading: false
                })
            })
        })
    }

    setControlsData = (data) => {
        var tmp = new Array(4)

        for (let i=0; i<tmp.length; i++) {
            for (let j=0; j<data?.length; j++) {
                if ((data[j].stream_id - 1) === i) {
                    tmp[i] = data[j]
                }
            }
        }

        tmp.push({ stream_id: 5, name: "Split screen" })

        if (!tmp.filter(elem => elem.name === this.props.selectedDevice?.name).length) { // pre select only if no selected device in redux store
            this.props.setSelectedDevice(tmp.find(elem => elem?.id)) // select first elem with id (which is not the splitscreen because it has no id)
        }

        return { 
            selectableDevices: tmp
        }
    }

    setViewerData = (data, liveParams) => {

        var tmp = data.map((elem) => ({
            ...elem,
            streamId: elem.stream_id,
            hostName: liveParams?.host?.name,
            vpnAddress: liveParams?.vpn?.ipv4,
        }))

        return { 
            viewableDevices: tmp
        }
    }

    onChangeDevice = (e) => {
        this.props.setSelectedDevice(e.target.value)
    }

    render() {

        const { viewableDevices, selectableDevices, loading } = this.state
        const { selectedDevice } = this.props

        return (
            <Layout>
                <Layout.Content className="specialContent">
                    <Viewer 
                        data={viewableDevices}
                        loading={loading}
                        selected={selectedDevice}
                    />
                </Layout.Content>
                <Layout.Footer className='specialFooter'>
                    <Controls
                        data={selectableDevices}
                        loading={loading}
                        selected={selectedDevice}
                        onChange={this.onChangeDevice}
                    />
                </Layout.Footer>
            </Layout>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Live);