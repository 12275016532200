import 'video.js/dist/video-js.css';
import React from 'react';
import videojs from 'video.js'
import Status from '../Status';
import { Row, Spin, Icon } from 'antd';

class Player extends React.Component
{
    constructor(props) {

        super(props);

        this.state = {
            updateCount: 0,
            error: null,
            reloading: false
        }

        this.isErrorOccurred = false
        this.reloadDelay = 2000
        this.reloadTimeout = null
    }

    setup() {
        this.setState((prevState) => ({
            updateCount: prevState.updateCount + 1 // increment updateCount
        }))
    }

    componentDidMount() {
        this.setup()
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }

        if (this.reloadTimeout) {
            clearTimeout(this.reloadTimeout)
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        if (this.props.src !== nextProps.src) {
            this.setup()
        }

        return true
    }

    componentDidUpdate(prevProps, prevState) {

        if ((this.state.updateCount !== prevState.updateCount) && this.props.src) {
            
            if (this.player)
                this.player.dispose();

            if (this.videoNode) {
                this.player = videojs(
                    this.videoNode, 
                    {
                        ...this.props,
                        playbackRates: [0.5, 1, 1.5, 2, 4, 8, 16]                
                    }, 
                    () => {
                        this.player.removeChild("BigPlayButton")
                        this.player.getChild("controlBar").getChild("volumePanel").removeChild("volumeControl")
                    }
                )

                this.player.on("error", this.startReload)
                this.player.on("loadeddata", this.stopReload)
                this.player.on("play", () => this.setState({ reloading: false }))
                this.player.currentTime(0)
                this.player.volume(1)
            }
        }
    }

    startReload = (e) => {

        // If the error is not related to the m3u8 playlist
        if (e.target.player.error_?.message === "The media could not be loaded, either because the server or network failed or because the format is not supported.") {
            return
        }

        // If an error occurs and a timeout is not already running
        if (!this.isErrorOccurred && !this.reloadTimeout) {
            this.isErrorOccurred = true
            this.setState({ reloading: true })

            // Delayed reload of the m3u8 playlist after the specified delay
            this.reloadTimeout = setTimeout(() => {
                this.player?.load()
                this.isErrorOccurred = false // Resets the error status
                this.reloadTimeout = null // Resets the timeout
            }, this.reloadDelay)
        }
    }

    stopReload = () => {
        // Stops the timeout when no more errors occur
        if (!this.isErrorOccurred && this.reloadTimeout) {
            clearTimeout(this.reloadTimeout)
            this.reloadTimeout = null
        }

        this.setState({ reloading: false })
    }

    render() {

        const { error, updateCount, reloading } = this.state;
        const { id, src } = this.props;

        const playerProps = {
            autoPlay: true,
            muted: true,
            controls: true,
            playsInline: true,
            fluid: true,
            ref: node => this.videoNode = node // gives Video.js a reference to the video DOM element
        }

        if (!error) {
            return (
                <>
                    <Row style={{ 
                        display: reloading ? "flex" : "none",
                        alignItems: "center", 
                        justifyContent: "center", 
                        height: "292.5px", 
                        backgroundColor: "black" 
                    }}>
                        <Spin indicator={<Icon type="loading" style={{ fontSize: 48 }} spin />} />
                    </Row>

                    <video-js
                        {...playerProps} 
                        key={`${id || ''}-${updateCount}`} // force rerender
                        class="vjs-default-skin" 
                        netcoSeekToLive={false}
                        style={{ display: reloading ? "none" : "block" }}
                    >
                        <source
                            src={src}
                            type="application/x-mpegURL"
                        />
                    </video-js>
                </>
            )
        } else {
            return <Status is={error} />
        }
    }
}

export default Player;