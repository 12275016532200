import React from 'react';
import { connect } from "react-redux";
import { Button, Dropdown, Menu, Select, message } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';

function mapStateToProps(state) {
    return {
        processMessage: state.processMessage,
        access: state.access
    }
}

class PlayAudioComponent extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            files: [],
            filesOptions: [],
            selectedNvrAudioFile: null,
            error: null,
            isLoaded: true
        };
    }

    updateNvrAudioFiles = (data) => {

        let files = [...this.state.files]
        let filesOptions = []
        let elem = null
    
        if (files.length > 0) {
            files = []
            filesOptions = []
        }
    
        for (let i=0; i < data.length; i++) {
            elem = data[i]
            if (elem.type === 'PlayAudioFile') {
                files.push({
                    filename: elem.params.filename,
                    label: elem.name
                })
            }
        }
    
        for (let j=0; j<files.length; j++) {
            elem = files[j]
            filesOptions.push(
                <Select.Option key={elem.filename} value={elem.filename}>
                    {elem.label}
                </Select.Option>
            )
        }
    
        this.setState({
            files,
            filesOptions
        })
    }

    componentDidMount() {
        this.show();
    }

    onClickPlay = () => {
        this.props.processMessage([{
            request: "playaudiofile",
            method: "set",
            params: [{ filename: this.state.selectedNvrAudioFile }]
        }])
        .catch(error => {
            console.error(error);
            message.error("Cannot play audio file: " + (error 
                ? typeof error.msg === "undefined" 
                    ? error.toString() 
                    : typeof error.msg === "object" 
                        ? error.msg.message 
                        : error.msg
                : "Unknown Error. Please try again."));
        })
    };

    onChangeAudioFile = (value) => {
        this.setState(({ selectedNvrAudioFile: value }));
    };

    show() {
        if (this.props.access) {
            if (this.state.files.length !== 0) { // just in case it is not empty
                this.setState({ files: [] });
            }
                
            if (this.state.filesOptions.length !== 0) { // just in case it is not empty
                this.setState({ filesOptions: [] });
            }

            this.setState({ isLoaded: false })
                
            this.props.processMessage([{ 
                request: "simpleactions",
                method: "get",
                params: [{ id: 0 }]
            }])
            .then(res => this.updateNvrAudioFiles(res["simpleactions"].params))
            .catch(error => {
                console.error(error);
                message.error(error 
                    ? typeof error.msg === "undefined" 
                        ? error.toString() 
                        : typeof error.msg === "object" 
                            ? error.msg.message 
                            : error.msg
                    : "Unknown Error. Please try again.");
                this.setState({ error });
            })
            .finally(() => this.setState({ isLoaded: true }));
        }
    }

    render() {
        const { filesOptions, isLoaded, selectedNvrAudioFile } = this.state
        const { disabled } = this.props

        const dropDownContent = (
            <Menu>
                <Menu.Item 
                    key={1} 
                    style={{ cursor: "default" }}
                    disabled
                >
                    <Select 
                        style={{ width: 150 }} 
                        placeholder="No file selected" 
                        onChange={this.onChangeAudioFile} 
                        onDropdownVisibleChange={open => open && this.show()}
                        size="small"
                        loading={!isLoaded}
                    >
                        {filesOptions}
                    </Select>
                </Menu.Item>
                <Menu.Item 
                    key={2} 
                    style={{ cursor: "default" }}
                    disabled
                >
                    <Button 
                        size="small"
                        style={{ width: 150 }}
                        disabled={!selectedNvrAudioFile}
                        onClick={this.onClickPlay}
                    >                
                        {selectedNvrAudioFile ? "Play" : "Select audio file"}
                    </Button>
                </Menu.Item>
            </Menu>
        )

        return (
            <Dropdown
                disabled={disabled}
                overlay={dropDownContent}
            >
                <Button shape="circle" size="small">                
                    <PlayCircleOutlined style={{ marginRight: 0 }} /> 
                </Button>
            </Dropdown>
        )
    }
}

export default connect(mapStateToProps,null)(PlayAudioComponent);