import React from 'react';
import { connect } from "react-redux";
import { Button, message } from 'antd';
import { AlertOutlined, AlertFilled } from '@ant-design/icons';

function mapStateToProps(state) {
    return {
        processMessage: state.processMessage
    }
}

class ArmIcon extends React.PureComponent {

    constructor(props) {

        super(props)

        this.state = {
            isArmed: false
        }
    }

    onClick = () => {
        this.setState(prevState => ({ isArmed: !prevState.isArmed }) )
        message.info(this.state.isArmed ? "Disarmed" : "Armed")
        
        // this.props.processMessage([{
        //     request: "playaudiofile",
        //     method: "set",
        //     params: [{ filename: this.state.selectedNvrAudioFile }]
        // }])
        // .catch(error => {
        //     console.error(error);
        //     message.error("Device cannot be armed/disarmed: " + (error 
        //         ? typeof error.msg === "undefined" 
        //             ? error.toString() 
        //             : typeof error.msg === "object" 
        //                 ? error.msg.message 
        //                 : error.msg
        //         : "Unknown Error. Please try again."));
        // })
    }

    render() {

        const { isArmed } = this.state
        const { disabled } = this.props

        return (
            <Button
                disabled={disabled}
                onClick={this.onClick}
                shape="circle"
                size="small"
                type={isArmed ? "primary" : "default"}
            >
                {isArmed ? 
                <AlertFilled style={{ marginRight: 0 }} /> :
                <AlertOutlined style={{ marginRight: 0 }} />}
            </Button>
        )

    }
}

export default connect(mapStateToProps,null)(ArmIcon);