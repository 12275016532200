import React from 'react';
import { Button, Icon, Affix } from 'antd';

class ConfirmCancelButtons extends React.PureComponent {

    render() {

        const { loading, disabled, onConfirm, onCancel } = this.props

        return (
            <>
                <div className='mediumLayout'>
                    <Affix offsetBottom="0" className="affixWithNav">
                        <Button.Group style={{ width: "100%" }}>
                            <Button type="primary" style={{ width: "50%", borderBottomLeftRadius: 0 }} onClick={onCancel}>
                                <Icon type="close" />Cancel
                            </Button>
                            <Button loading={loading} type="primary" style={{ width: "50%", borderBottomRightRadius: 0, marginLeft: 0 }} disabled={disabled} onClick={onConfirm}>
                                <Icon type="check" />Confirm
                            </Button>
                        </Button.Group>
                    </Affix>
                </div>

                <div className='smallLayout'>
                    <Affix offsetBottom="0" className="affix">
                        <Button.Group style={{ width: "100%" }}>
                            <Button type="primary" style={{ width: "50%", borderBottomLeftRadius: 0 }} onClick={onCancel}>
                                <Icon type="close" />Cancel
                            </Button>
                            <Button loading={loading} type="primary" style={{ width: "50%", borderBottomRightRadius: 0, marginLeft: 0 }} disabled={disabled} onClick={onConfirm}>
                                <Icon type="check" />Confirm
                            </Button>
                        </Button.Group>
                    </Affix>
                </div>
            </>
        )
    }
}
 
export default ConfirmCancelButtons;