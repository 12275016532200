import React from 'react';
import Header from './header/Header';
import Setup from './setup/Setup';
import Live from './live/Live';
import Login from "./Login";
import jwt from 'jsonwebtoken';
import dayjs from 'dayjs';
import Maintenance from './Maintenance';
import Notifications from "./Notifications";
import Archive from './archive/Archive';
import * as ROUTES from '../constants/routes';
import { getCookie, removeCookie, setCookie } from './Utilities';
import { withRouter } from "react-router-dom";
import { Layout, Spin, Row } from 'antd';
import { Redirect, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { setProcess, set, setSort, setAccess, setLoggedOut } from "../api/actions";
import { Mutex } from 'async-mutex';
import { nanoid } from 'nanoid/async';

var isMounted = false;
var unit = {};
const pendingResponsesMutex = new Mutex();
const receivedMessagesMutex = new Mutex();
const sendTimeout = 5000
const sendRetries = 3
const responseTimeout = 60000
const debug = window.config.DEBUG

function mapDispatchToProps(dispatch) {
    return {
        setProcess: processMessage => dispatch(setProcess(processMessage)),
        set: unit => dispatch(set(unit)),
        setSort: sortMessage => dispatch(setSort(sortMessage)),
        setAccess: access => dispatch(setAccess(access)),
        setLoggedOut: loggedOut => dispatch(setLoggedOut(loggedOut))
    };
}

function mapStateToProps(state) {
    return { 
        processMessage: state.processMessage,
        sortMessage: state.sortMessage,
        access: state.access,
        currentTop: state.currentTop,
        loggedOut: state.loggedOut,
    };
}

function connectSocket(wrapper) {

    const { SOCKETPROTOCOL, BUILD, SERVER, SOCKET } = window.config
    const { location } = window

    const protocol = SOCKETPROTOCOL + "://";
    const port = BUILD ? (location.port ? (":" + location.port) : "") : "";
    const server = BUILD ? location.hostname : SERVER;
    const path = unit.proxyKey ? ("/unit/comm/" + unit.proxyKey + SOCKET) : SOCKET;
    const wsConfig = protocol + server + port + path;

    debug && console.log(`[WebSocket] Connecting to ${wsConfig}`);

    try {
        wrapper.socket = new WebSocket(wsConfig);

        var socket = wrapper.socket;

        socket.onopen = () => {

            console.info("[WebSocket] Connected.", socket)

            wrapper.timeout = 250; // reset timer

            clearTimeout(wrapper.connectInterval); // clear interval

            if (isMounted) {
                wrapper.setState({ isLoaded: true })
                wrapper.checkCookie()
            }
        }

        socket.onclose = event => {

            console.warn(`[WebSocket] Closed. Reconnect in ${Math.min(10000/1000, (wrapper.timeout + wrapper.timeout) / 1000)}s.`, event);
            
            wrapper.timeout = wrapper.timeout + wrapper.timeout; // increment retry interval

            wrapper.connectInterval = setTimeout(() => check(wrapper), Math.min(10000, wrapper.timeout)); // call check after timeout
        }

        socket.onerror = error => {

            console.error("[WebSocket] Failed.", error);

            socket.close(); // close socket
        }

        socket.onmessage = msg => {

            const message = JSON.parse(msg.data);

            if (message && message.ref) {
                lock(() => wrapper.pendingResponses.has(message.ref), pendingResponsesMutex)
                .then(() => lock(() => wrapper.pendingResponses.delete(message.ref), pendingResponsesMutex))    
                .then(() => lock(() => wrapper.receivedMessages[message.ref] = message, receivedMessagesMutex));
            }
        }

    } catch (error) {
        console.error("[WebSocket] Error.", error);
    }
}

function disconnectSocket(wrapper) {
    if (wrapper.socket)
        wrapper.socket.close();
}

// reconnect when connection closes
function check(wrapper) {
    const { socket, currentTop, location: { pathname } } = wrapper.props

    if (isMounted && (!socket || socket.readyState === WebSocket.CLOSED)) {

        debug && console.log("[WebSocket] Reconnect.")

        if (currentTop === "login" || 
            pathname === "/login") {
            connectSocket(wrapper)
        } else {
            wrapper.setState({ isLoaded: false }, () => connectSocket(wrapper))
        }
    }
}

export async function sleep(n) {
    return new Promise(resolve => setTimeout(resolve, n));
}

async function failAfter(n) {
    return new Promise((resolve, reject) => setTimeout(reject, n));
}

async function runFunction(func, timeout) {

    let timeoutPromise = new Promise((resolve, reject) => setTimeout(() => reject("rejected"), timeout));
    let actionPromise = new Promise(async(resolve, reject) => func().then(resolve).catch(reject));
    
    return await Promise.race([timeoutPromise, actionPromise]);
}

async function receive(wrapper, ref) {

    return lock(() => {

        if (ref in wrapper.receivedMessages) {
            var message = wrapper.receivedMessages[ref];
            delete wrapper.receivedMessages[ref];
            return message;
        } else {
            return null;
        }
    }, receivedMessagesMutex)
    .then(message => message ? Promise.resolve(message) : Promise.reject(null));
}

async function tryReceiveResponse(wrapper, ref, timeoutPromise, wait) {

    return Promise.race([ timeoutPromise, sleep(wait) ])
        .then(async() => 
            receive(wrapper, ref)
            .then(res => res)
            .catch(() => tryReceiveResponse(wrapper, ref, timeoutPromise, 0))
        )
        .catch(ref => Promise.reject(ref));
}

async function receiveResponse(wrapper, ref, forceRemoveRef) {

    return tryReceiveResponse(wrapper, ref, failAfter(responseTimeout))
        .then(message => Promise.resolve(message))
        .catch(async() => { 
            if (forceRemoveRef) {
                await lock(() => delete wrapper.receivedMessages[ref], receivedMessagesMutex);
                await lock(() => wrapper.pendingResponses.delete(ref), pendingResponsesMutex);
            }
            return Promise.reject(ref);
        });
}

async function send(wrapper, message) {

    return await runFunction(() => {

        return new Promise(async(resolve, reject) => { 
            try {
                message.ref = await nanoid(); // set universally unique identifier to ref
                debug && console.log(JSON.stringify(message));
                await (async() => wrapper.socket.send(JSON.stringify(message)))();
                resolve(message.ref);
            } catch(error) {
                reject(error);
            } 
        });
    }, sendTimeout);
}

async function trySendRequest(wrapper, message, errorCount) {

    var promise = null;

    if (errorCount < sendRetries) {
        promise = send(wrapper, message);
        return promise
            .then(ref => Promise.resolve(ref))
            .catch(() => trySendRequest(wrapper, message, errorCount + 1));
    } else {
        return Promise.reject();
    }
}

async function sendRequest(wrapper, message) {

    return trySendRequest(wrapper, message, 0)
        .then(ref => lock(() => {
            wrapper.pendingResponses.add(ref);
            return ref;
        }, pendingResponsesMutex));
}

async function lock(func, mutex) {

    const release = await mutex.acquire();

    var promise = (async() => Promise.resolve(func()))();

    await promise;

    release();

    return promise;
}

function sortMessage(req) {

    var a = req.a;
    var b = req.b;
    var value = req.value;
    var subvalue = req.subvalue;
    var numeric = req.numeric;

    if (!numeric) {
        if (a && b && value && a[value] && b[value]) {
            if (subvalue) {
                if (a[value][subvalue] && b[value][subvalue]) {
                    return a[value][subvalue].trim().localeCompare(b[value][subvalue].trim());
                } else {
                    return null;
                }
            }
            return a[value].trim().localeCompare(b[value].trim());
        } else {
            return null;
        }
    } else {
        return a[value] - b[value];
    }
}

async function processMessage(wrapper, req) {

    var obj = {};
    obj.error = {};
    obj.error.msg = "";
    obj.error.type = "";
    obj.req = {};
    obj.reqs = req;
    obj.res = {};
    obj.success = true;

    for (let i=0; i<obj.reqs.length; i++) {

        obj.req = obj.reqs[i];

        var request = {
            request: obj.req.request,
            method: obj.req.method,
            params: obj.req.params,
            pagination: obj.req.pagination,
            sessiontoken: wrapper.sessiontoken
        }

        await sendRequest(wrapper, request)
        .then(ref => receiveResponse(wrapper, ref, true))
        .then(res => {
            obj.res[obj.req.request] = res;        
            if (res.errorcode) {
                obj.error.type = "warning";
                obj.error.msg += res.errordesc;
                obj.success = false;
            }
        })
        .catch(error => {
            obj.error.type = "error";
            obj.error.msg += error;
            obj.success = false;
        });
    }

    return obj.success 
        ? Promise.resolve(obj.res) 
        : Promise.reject(obj.error);
}

class Wrapper extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = { 
            isLoaded: false 
        }

        this.socket = null;
        this.sessiontoken = null;
        this.pendingResponses = new Set();
        this.receivedMessages = {};
        this.timeout = 250;
        this.connectInterval = null;
        this.currentTime = dayjs();

        this.props.setProcess(req => processMessage(this, req));
        this.props.setSort(req => sortMessage(req));
        this.receiveDataFromMobileApp = this.receiveDataFromMobileApp.bind(this);
    }

    componentDidMount() {
        isMounted = true;
        connectSocket(this); // call connectSocket with wrapper object
        
        this.checkCookie(); // check cookie
        this.timerID = setInterval(() => this.checkCookie(), 10000); // recheck cookie every 60s
        window.addEventListener('message', this.receiveDataFromMobileApp);
    }

    componentWillUnmount() {
        isMounted = false;
        disconnectSocket(this);
        clearInterval(this.timerID);
        window.removeEventListener('message', this.receiveDataFromMobileApp);
    }

    receiveDataFromMobileApp(message) {
        debug && console.log("[Wrapper] Received data from mobile app.", message)
        if (message?.data?.token) {
            setCookie("__remotesession", message.data.token)
        }
    }

    checkCookie() {

        const session = getCookie("__session")
        const remoteSession = getCookie("__remotesession")

        if (session) {
            this.sessiontoken = session
        } else if (remoteSession) {
            this.sessiontoken = remoteSession
        }

        if (this.sessiontoken) {
            try {
                const { access, setAccess, currentTop, location: { pathname }, loggedOut, setLoggedOut, history } = this.props

                const now = this.currentTime.unix();
                const cookieDecoded = jwt.decode(this.sessiontoken); // decode cookie, needs exceptionhandling
                const cookieExpires = cookieDecoded.exp - 300; // 300s = 5m
                const cookieExpired = cookieDecoded.exp;
                const cookieRole = cookieDecoded.unit?.user?.role; // TODO: not available when coming from mobile app!
                const currentRole = access;

                if (now >= cookieExpired) {

                    removeCookie("__session"); // remove cookies

                    debug && console.log("[Wrapper] Cookie expired - now", now, dayjs.unix(now), "> expired", cookieExpired, dayjs.unix(cookieExpired));

                    this.removeAccessrights()
                    this.redirectToLogin()

                } else {
                    
                    if (now < cookieExpires) {

                        debug && console.log("[Wrapper] Cookie valid - now", now, dayjs.unix(now), "< expires", cookieExpires, dayjs.unix(cookieExpires));
                        
                        if (cookieRole && (JSON.stringify(currentRole) !== JSON.stringify(cookieRole))) {
                            setAccess(cookieRole); // set accessrights
                        }

                        if ((currentTop === "login" 
                        || pathname === "/login" 
                        || pathname.includes("id")) // remoteaccess via cloud 
                        && !loggedOut) { 
                            processMessage(this, [{ 
                                request: "sessionrefresh",
                                method: "get",
                                params: []
                            }])
                            .then(res => {
                                const session = res["sessionrefresh"]

                                if (!session.errorcode) { // token is valid (no invalid signature)           
    
                                    const token = session.params[0]
                                    const requestNewPassword = window.localStorage.getItem('requestNewPassword') === "true"

                                    if (token !== this.sessiontoken) {
                                         setCookie("__session", token)
                                        //setCookie(remoteSession ? "__remotesession" : "__session", token) // MHe: Session refresh created a session cookie even if it was called remote - which breaks further cloud access (TODO: test)
                                    }

                                    if (!requestNewPassword) {
                                        history.push(ROUTES.LIVE) // redirect to live
                                    }
                                }
                            })
                            .catch(error => console.error("[Wrapper] Error refreshing session.", error))
                            .finally(() => setLoggedOut(false))
                        }

                    } else {

                        debug && console.log("[Wrapper] Cookie expires soon - now", now, dayjs.unix(now), "< expired", dayjs.unix(cookieExpired));
                        
                        processMessage(this, [{
                            request: "sessionrefresh",
                            method: "get",
                            params: []
                        }])
                        .then(res => { 
                            const token = res["sessionrefresh"].params[0]

                            if (token !== this.sessiontoken) {
                                setCookie("__session", token)
                                //setCookie(remoteSession ? "__remotesession" : "__session", token) // MHe: Session refresh created a session cookie even if it was called remote - which breaks further cloud access (TODO: test)
                            }
                        })
                        .catch(error => console.error("[Wrapper] Error refreshing soon expiring session.", error))
                        .finally(() => this.setState({ isLoaded: true }))
                    }
                }
            } catch (error) {
                console.error("[Wrapper] Unknown error.", error);
            } 

        } else {

            debug && console.warn("[Wrapper] No cookie found.")
            this.removeAccessrights()
            this.redirectToLogin()
        }

        this.currentTime = dayjs();
    }

    redirectToLogin() {
        const { location: { pathname }, currentTop, history } = this.props

        if (currentTop !== "login" ||
            pathname !== "/login") {
            history.push(ROUTES.LOGIN)
        }
    }

    removeAccessrights() {
        const { access, setAccess } = this.props

        if (access) {
            setAccess(null)
        }
    }

    // set layout and switch between further components when socket connection established, wrap in BrowserRouter to navigate via Header and Sider
    render() {

        const { access } = this.props
        const { isLoaded } = this.state

        const isUser = access && access === "user"
        const ProtectedRoute = ({ disabled, ...props }) => disabled ? <Redirect to={ROUTES.LOGIN} /> : <Route {...props} />

        return (
            <Layout style={{ background: "none" }}>

                <Layout.Header style={{ 
                    height: "48px", 
                    padding: "0",
                    zIndex: "1000",
                    position: "fixed",
                    width:"100%",
                }}>
                    <Header />
                </Layout.Header>

                {isLoaded 
                ? <Switch>
                    <Route exact path={ROUTES.RELOAD} />
                    <Route exact path={ROUTES.LOGIN} component={Login} />
                    <ProtectedRoute exact path={ROUTES.ARCHIVE} component={Archive} disabled={!access} />
                    <ProtectedRoute exact path={ROUTES.LIVE} component={Live} disabled={!access}/>
                    <ProtectedRoute exact path={ROUTES.NOTIFICATIONS} component={Notifications} disabled={!access} />
                    <ProtectedRoute exact path={ROUTES.MAINTENANCE} component={Maintenance} disabled={!access} />
                    <ProtectedRoute path={ROUTES.SETUP} component={Setup} disabled={!access || isUser} />
                    <Route component={Login} />
                </Switch>
                : <>
                    <Route 
                        exact 
                        path={ROUTES.UNIT} 
                        render={(props) => {
                            let unitObj = { proxyKey: props.match.params.unit }
                            this.props.set(unitObj) // set to redux store cause need in other components
                            unit = unitObj // set also globally cause need faster for connectSocket()
                        }} 
                    />
                    <Row type="flex" style={{ alignItems: "center", justifyContent: "center", height: "100%" }}>
                        <Spin />
                    </Row>
                </>}

            </Layout>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Wrapper));