import React from 'react';
import { connect } from "react-redux";

// MME: can't manage to integrate Worker into bundle
// https://github.com/webpack-contrib/worker-loader
// import NVRAudioSrcWorker from 'worker-loader!./NVRAudioSrcNode.worker.js';
// import NVRAudioSinkWorker from 'worker-loader!./NVRAudioSinkNode.worker.js';
// import NVRAudioSrcWorker from './NVRAudioSrcNode.worker.js';
// import NVRAudioSinkWorker from './NVRAudioSinkNode.worker.js';

// map socket and currentTop states from Redux store to props
function mapStateToProps(state) {
    return { 
        processMessage: state.processMessage
    }
}

const SampleRate = 48000.0;

class AudioIcon extends React.PureComponent {

    constructor(props) {

        super(props);

        this.inputFile = [];
        this.outputFile = [];
        this.context = null;

        this.srcNode = null;
        this.sinkNode = null;

        this.tsStart = 0;
        this.srcSamplesSum = 0;
        this.cnt = 0;
    
        this.mediaStream = null;
        this.mediaStreamSource = null;
        this.processMessage = this.props.processMessage;
        this.debug = window.config.DEBUG;
    }

    componentDidMount() {
        if (!this.mediaStream) {
            this.startMicrophone();
        }
    }

    componentWillUnmount() {
        if (this.mediaStream) { 
            this.stopMicrophone();
        }
    }

    startMicrophone() {

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) { // check browser support
            this.context = new window.AudioContext({ sampleRate: SampleRate });

            try {
                this.context.audioWorklet.addModule("./static/js/NVRAudioSrcNode.worker.js").then(() => {
                    // srcNode - receives from Network and provides Samples to Audio Web API
                    this.srcNode = new AudioWorkletNode( this.context, "nvr-audio-src-processor" );
                    this.srcNode.connect(this.context.destination);

                    this.context.audioWorklet.addModule("./static/js/NVRAudioSinkNode.worker.js").then(() => {
                        // sinkNode - receives Samples from Audio Web API and sends to Network
                        this.sinkNode = new AudioWorkletNode( this.context, "nvr-audio-sink-processor" );

                        // Web Audio API has received Samples from Microphone and sends to sinkNode
                        //  SinkNode sends Samples per message and apear here
                        this.sinkNode.port.onmessage = (e) => {
                            // here we should receive a number of samples in e.data as well as a timestamp
                            // we should send the samples to the NVR
                            var inputChannelObject = e.data; // Float32Array(num): [0.0002395050396444276, 0.000702422228641808, 0.0004151133180130273, ...]
                            
                            //  S16LE
                            var inputBuffer = Int16Array.from(inputChannelObject.map(x => (x>0 ? x*0x7FFF : x*0x8000))); // Uint16Array(2048): [-10486, -17373, -10486, ...]
                            var inputBufferArray = new Uint8Array(inputBuffer.buffer); // Uint8Array(4096): [165, 35, 123, ...]
                        
                            // Buffer.from() is nodejs specific
                            var inputBufferArrayBase64 = Buffer.from(inputBufferArray).toString('base64');  // "CtcjvArXozyPwvW8CtcjPQA..."
                            // var inputBufferArrayBase64 = btoa(String.fromCharCode.apply(null, inputBufferArray));

                            this.props.processMessage([{ 
                                request: "audio",
                                method: "set",
                                params: [{ 
                                    format: "s16le", // 16-bit signed samples little endian
                                    channel: 1, // one input channel
                                    sampleRate: this.context.sampleRate, // 44100/48000 depending on output device
                                    bufferSize: inputBuffer.length,
                                    data: inputBufferArrayBase64,
                                    fin: 0
                                }]
                            }])
                            .then(res => {
                                this.sinkNode.port.postMessage("requestSamples");

                                var inputBufferArrayBase64 = res["audio"].params[0] ? res["audio"].params[0].data : null;

                                if (!!inputBufferArrayBase64) {

                                    var inputBufferArray = atob(inputBufferArrayBase64); // Array(4096): ['A', '_', '%', ...]
        
                                    if (!!inputBufferArray) {
        
                                        var inputBufferArrayDec = new Uint8Array(inputBufferArray.length);  // Array(4096): [165, 35, 123, ...]
        
                                        if (!!inputBufferArrayDec) {
        
                                            for (let i=0; i<inputBufferArray.length; ++i) inputBufferArrayDec[i] = inputBufferArray.charCodeAt(i); // Array(4096): [165, 35, 123, ...]
                                            
                                            // SI16LE
                                            var inputBuffer = new Int16Array(inputBufferArrayDec.buffer); // Array(2048): [-1165, +30011, -21212, ...]
                                            var inputChannelData = Float32Array.from(Float32Array.from(inputBuffer).map(x=>x/0x8000)) // Array(1024): [165, 35, 123, ...]

                                            this.srcNode.port.postMessage(inputChannelData);
                                        }
                                    }
                                }
                            })
                            .catch(error => console.error("[AudioIcon]", error));
                        }

                        navigator.mediaDevices
                            .getUserMedia({ audio: true, video: false })
                            .then((mediaStream) => {
                                this.mediaStreamSource = this.context.createMediaStreamSource(mediaStream);
                                this.mediaStreamSource.connect(this.sinkNode);
                                this.mediaStream = mediaStream;
                            });
                        
                    });
                })
                .catch(error => console.error("[AudioIcon]", error));

            } catch (error) { 
                console.error("[AudioIcon]", error); 
            }

            /*
            await this.context.audioWorklet.addModule("NVRAudioSrcNode-processor.js");
            await this.context.audioWorklet.addModule("NVRAudioSinkNode-processor.js");

            // srcNode - receives from Network and provides Samples to Audio Web API
            this.srcNode = new AudioWorkletNode( this.context, "nvr-audio-src-processor", );
            this.srcNode.connect(this.context.destination);

            // sinkNode - receives Samples from Audio Web API and sends to Network
            this.sinkNode = new AudioWorkletNode( this.context, "nvr-audio-sink-processor", );

            // Web Audio API has received Samples from Microphone and sends to sinkNode
            //  SinkNode sends Samples per message and apear here
            this.sinkNode.port.onmessage = (e) => {
                // here we should receive a number of samples in e.data as well as a timestamp
                // we should send the samples to the NVR
                this.srcNode.postMessage(e.data);
            }

            navigator.mediaDevices
                .getUserMedia({ audio: true, video: false })
                .then((mediaStream) => {
                    this.mediaStreamSource = this.context.createMediaStreamSource(mediaStream);
                    this.mediaStreamSource.connect(this.sinkNode);
                    this.mediaStream = mediaStream;
                });
            */
        }
    }

    // startMicrophone_old() {

    //     if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) { // check browser support
        
    //         // this.context = new (window.AudioContext || window.webkitAudioContext)(); // ensure browser compatibility
    //         this.context = new window.AudioContext({ sampleRate: SampleRate, });
    //         this.mediaStreamDestination = this.context.createMediaStreamDestination();

    //         navigator.mediaDevices.getUserMedia({ audio: true, video: false }) // request user microphone only
    //         .then(mediaStream => {

    //             var inputFile = [];
    //             var outputFile = [];
    //             var processMessage = this.props.processMessage;
    //             var scriptProcessor = this.context.createScriptProcessor(SamplesPerChunk, 1, 1);

    //             this.mediaStreamSource = this.context.createMediaStreamSource(mediaStream);
    //             this.mediaStream = mediaStream;

    //             this.mediaStreamSource.connect(scriptProcessor).connect(this.mediaStreamDestination);

    //             // MME:
    //             var sendSamplesCount = 0;
    //             var sendStartTS = 0;

    //             const outputBufferSize = BufferChunkNumber * SamplesPerChunk;
    //             var outputBuffer = this.context.createBuffer(1, outputBufferSize, this.context.sampleRate);
    //             var outputChannelObject = outputBuffer.getChannelData(0);
    //             var outputSource = this.context.createBufferSource();

    //             this.debug && console.log("SampleRate = " + this.context.sampleRate);

    //             var count = 0;
    //             var outputReadTS = 0;
    //             var outputReadIdx = 0;
    //             var outputWriteIdx = 0;
    //             var outputSourceStarted = false;

    //             var cntVal = 0;
    //             var cntStepNum = 24;
    //             var totalSendRecv = 0;

    //             outputSource.buffer = outputBuffer;
    //             outputSource.connect(this.context.destination);
    //             outputSource.loop = true;
    //             outputSource.loopStart = 0;
    //             outputSource.loopEnd = outputBuffer.duration;
    //             // outputSource.start();

    //             scriptProcessor.onaudioprocess = function(audioProcessingEvent) {

    //                 var inputChannelObject = audioProcessingEvent.inputBuffer.getChannelData(0); // Float32Array(1024): [0.0002395050396444276, 0.000702422228641808, 0.0004151133180130273, ...]
                   
    //                 // Testton - Sägezahn - Periode 48 Samples
    //                 // disabled! (currently no iteration) -> change from for(i=x;i<x;i++) to for(i=0;i<x;i++)
    //                 var i, ii = "inputBuf:";
    //                 for(i=inputChannelObject.length;i<inputChannelObject.length;i++)
    //                 {
    //                     var v = (cntVal < cntStepNum) ? cntVal : (2*cntStepNum) - cntVal; // [0, 1, ..., cntStepNum, cntStepNum-1, ..., 1]
    //                     v = v - cntStepNum/2;  // [-cntStepNum/2, ..., 0, ..., cntStepNum/2]
    //                     inputChannelObject[i] = v/12.1; // keep amplitudes in range { -1 < v_min <= v <= v_max < 1 }
    //                     ii = ii + inputChannelObject[i] + ",";
    //                     cntVal++;
    //                     if ( cntVal >= (2 * cntStepNum) ) { cntVal = 0; }
    //                 }
    //                 // console.log(ii);

    //                 totalSendRecv += SamplesPerChunk; // 4096

    //                 //  F32LE
    //                 // var inputBuffer = new Uint8Array(inputChannelObject.buffer); // Uint8Array(4096): [165, 35, 123, ...]
    //                 // var inputBufferArray = inputBuffer;
                    
    //                 //  S16LE
    //                 var inputBuffer = Int16Array.from(inputChannelObject.map(x => (x>0 ? x*0x7FFF : x*0x8000))); // Uint16Array(2048): [-10486, -17373, -10486, ...]
    //                 var inputBufferArray = new Uint8Array(inputBuffer.buffer); // Uint8Array(4096): [165, 35, 123, ...]
                    
    //                 // Buffer.from() is nodejs specific
    //                 var inputBufferArrayBase64 = Buffer.from(inputBufferArray).toString('base64');  // "CtcjvArXozyPwvW8CtcjPQA..."
    //                 // var inputBufferArrayBase64 = btoa(String.fromCharCode.apply(null, inputBufferArray));

    //                 this.debug && inputFile.push(inputChannelObject);

    //                 processMessage([{ 
    //                     request: "audio",
    //                     method: "set",
    //                     params: [{ 
    //                         format: "s16le", // 16-bit signed samples little endian
    //                         channel: 1, // one input channel
    //                         sampleRate: this.context.sampleRate, // 44100/48000 depending on output device
    //                         bufferSize: inputBuffer.length,
    //                         data: inputBufferArrayBase64,
    //                         fin: 0
    //                     }]
    //                 }])
    //                 .then(res => {
    //                     var inputBufferArrayBase64 = res["audio"].params[0] ? res["audio"].params[0].data : null;

    //                     if (!!inputBufferArrayBase64) {

    //                         var inputBufferArray = atob(inputBufferArrayBase64); // Array(4096): ['A', '_', '%', ...]

    //                         if (!!inputBufferArray) {

    //                             var inputBufferArrayDec = new Uint8Array(inputBufferArray.length);  // Array(4096): [165, 35, 123, ...]

    //                             if (!!inputBufferArrayDec) {

    //                                 for (let i=0; i<inputBufferArray.length; ++i) inputBufferArrayDec[i] = inputBufferArray.charCodeAt(i); // Array(4096): [165, 35, 123, ...]
                                    
    //                                 // SI16LE
    //                                 var inputBuffer = new Int16Array(inputBufferArrayDec.buffer); // Array(2048): [-1165, +30011, -21212, ...]
    //                                 var inputChannelData = Float32Array.from(Float32Array.from(inputBuffer).map(x=>x/0x8000)) // Array(1024): [165, 35, 123, ...]
    //                                 // F32LE
    //                                 // var inputBuffer = inputBufferArrayDec;
    //                                 // var inputChannelData = new Float32Array(inputBuffer.buffer); // Float32Array(1024): [0.0002395050396444276, 0.000702422228641808, 0.0004151133180130273, ...]
            
    //                                 totalSendRecv = totalSendRecv - inputChannelData.length;

    //                                 this.debug && console.log("SendRecv recv="+inputChannelData.length+" count=" + totalSendRecv);
            
    //                                 this.debug && outputFile.push(inputChannelData);
            
    //                                 // Leseposition updaten
                                    
    //                                 if ( outputSourceStarted == true )
    //                                 {
    //                                     var tsNow = Date.now();
    //                                     // var samplesPlayed = (tsNow - outputReadTS) * this.context.sampleRate / 1000;
    //                                     var samplesPlayed = SamplesPerChunk;
            
    //                                     var nextOutputReadIdx = (outputReadIdx + samplesPlayed) % outputBufferSize;
    //                                     // |-------rIdx++++++++wIdx------|
    //                                     if (outputWriteIdx >= outputReadIdx)
    //                                     {
    //                                         if ( (outputReadIdx + samplesPlayed) < outputBufferSize )
    //                                             if ( nextOutputReadIdx > outputWriteIdx )
    //                                             {
    //                                                 console.warn("AudioPlay: Read-Overflow: rIdx:" + outputReadIdx + " + played:" + samplesPlayed + " = " +  nextOutputReadIdx + " > wIdx:" + outputWriteIdx);
    //                                             }
    //                                     }
    //                                     // |+++++++wIdx--------rIdx++++++|
    //                                     else 
    //                                     {
    //                                         if ( (outputReadIdx + samplesPlayed) >= outputBufferSize )                                
    //                                             if ( nextOutputReadIdx > outputWriteIdx )
    //                                             {
    //                                                 console.warn("AudioPlay: Read-Overflow: rIdx:" + outputReadIdx + " + played:" + samplesPlayed + " = " +  nextOutputReadIdx + " > wIdx:" + outputWriteIdx);
    //                                             }
    //                                     }
    //                                     outputReadIdx = nextOutputReadIdx;
    //                                     outputReadTS = tsNow;
    //                                 }
            
    //                                 // copy into output buffer
    //                                 var srcToCopy = inputChannelData.length;
            
    //                                     if (outputWriteIdx >= outputReadIdx)
    //                                     {
    //                                         var free = outputBufferSize - outputWriteIdx;
    //                                         var copyInStep = (srcToCopy <= free) ? srcToCopy : free;
    //                                         outputBuffer.copyToChannel(inputChannelData, 0, outputWriteIdx);
    //                                         outputWriteIdx = (outputWriteIdx + copyInStep) % outputBufferSize;
    //                                         srcToCopy -= copyInStep;
    //                                         // remove copied data from input buffer
    //                                         inputChannelData = inputChannelData.subarray(copyInStep);
    //                                     }
            
    //                                     if (srcToCopy > 0)
    //                                     {
    //                                         var free = outputReadIdx - outputWriteIdx - 1;
    //                                         var copyInStep = (srcToCopy <= free) ? srcToCopy : free;
    //                                         outputBuffer.copyToChannel(inputChannelData, 0, outputWriteIdx);
    //                                         outputWriteIdx = (outputWriteIdx + copyInStep) % outputBufferSize;
    //                                         srcToCopy -= copyInStep;
    //                                     }
            
    //                                     if (srcToCopy > 0)
    //                                     {
    //                                         this.debug && console.warn("AudioPlay: Write-Overflow: wIdx:" + outputWriteIdx + " / rIdx:" + outputReadIdx + " samplesLost:" +  srcToCopy);
    //                                     }
    //                                     else
    //                                     {
    //                                         // console.log("AudioPlay: UI8BufLen="+inputBuffer.length+" F32Len="+inputChannelData.length+" wIdx:" + outputWriteIdx + " / rIdx:" + outputReadIdx);
    //                                         var samplesBuffered = (outputWriteIdx + outputBufferSize - outputReadIdx) % outputBufferSize;
    //                                         // var fillLevelPercent = (samplesBuffered * 100.0) / outputBufferSize;
    //                                         // console.log("AudioPlay: wIdx:" + outputWriteIdx + " / rIdx:" + outputReadIdx + " / fill-level: " + fillLevelPercent + "%" );
    //                                     }
            
    //                                     if ( outputSourceStarted == false )
    //                                     {
    //                                         var samplesBuffered = (outputWriteIdx + outputBufferSize - outputReadIdx) % outputBufferSize;
    //                                         if ( samplesBuffered >= (outputBufferSize/2) )
    //                                         {
    //                                             outputSourceStarted = true;
    //                                             outputSource.start();
    //                                             outputReadTS = Date.now();
    //                                             this.debug && console.log("AudioPlay: start rIdx:" + outputReadIdx + " / wIdx:" + outputWriteIdx);
    //                                         }
    //                                     }
            
    //                                 // Debug: send/receive rate calculation
    //                                 var tsNow = Date.now();
    //                                 if ( sendStartTS == 0 ) {
    //                                     sendStartTS = tsNow;
    //                                     sendSamplesCount = 0;
    //                                 }
    //                                 else
    //                                 {
    //                                     sendSamplesCount += SamplesPerChunk;
            
    //                                     // print every 5 seconds
    //                                     if ( (sendSamplesCount % ( Math.trunc(5 * SampleRate / SamplesPerChunk) * SamplesPerChunk) ) == 0 ) {
    //                                         var secDiff = (tsNow - sendStartTS) / 1000;
    //                                         var rate = sendSamplesCount / secDiff;
    //                                         this.debug && console.log("AudioSend: " + (sendSamplesCount+0) + " samples in " + (secDiff+0) + "s = " + (rate+0) + " samples/s");
    //                                     }
    //                                 }
    //                             }
    //                         }
    //                     }
    //                  })
    //                 .catch(error => console.error(error));
    //             }

    //             this.inputFile = inputFile;
    //             this.outputFile = outputFile;
    //         })
    //         .catch(error => console.error(error));
    //     }
    // }

    stopMicrophone() {

        /*
        if (this.mediaStream) {
            this.debug && console.log("mediaStream", this.mediaStream);
            this.mediaStream.getTracks().forEach(track => track.stop());
            this.mediaStream = null;
        }

        if (this.mediaStreamSource) {
            this.debug && console.log("mediaStreamSource", this.mediaStreamSource);
            this.mediaStreamSource.disconnect();
        }
        */

        if (this.context && this.context.state === "running") {
            this.debug && console.log("[AudioIcon] Context:", this.context);
            this.context.close();
        }

        this.processMessage([{
            request: "audio",
            method: "set",
            params: [{ 
                format: "f32le",
                channel: 1,
                sampleRate: 0,
                bufferSize: 0,
                data: "",
                fin: 1
            }]
        }])
        .catch(error => console.error("[AudioIcon]", error));
    }

    render() {
        return null
    }
}

export default connect(mapStateToProps, null)(AudioIcon);
