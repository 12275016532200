export const ALARMCLIENTS   = "/setup/alarmclients";
export const ALARMCLIENTFORM= "/setup/alarmclientform";
export const ALARMS         = "/setup/alarms";
export const ALARMFORM      = "/setup/alarmform";
export const ANALYTICS      = "/setup/analytics";
export const CONNECTIVITY   = "/setup/connectivity";
export const DEVICEACCESS   = "/setup/deviceaccess";
export const DEVICEDISCOVER = "/setup/devicediscover";
export const DEVICEFORM     = "/setup/deviceform";
export const DEVICES        = "/setup/devices";
export const ACTIONS        = "/setup/actions";
export const ACTIONFORM     = "/setup/actionform";
export const ARCHIVE        = "/archive";
export const CONDITIONS     = "/setup/conditions";
export const CONDITIONFORM  = "/setup/conditionform";
export const EVENTFORM      = "/setup/eventform";
export const EVENTS         = "/setup/events";
export const LIVE           = "/live";
export const LOGIN          = "/login";
export const MAINTENANCE    = "/setup/maintenance";
export const NOTIFICATIONS  = "/notifications";
export const RELOAD         = "/*/reload"
export const SETUP          = "/setup";
export const STORAGE        = "/setup/storage";
export const STORAGEFORM    = "/setup/storageform";
export const UNIT           = "/id/:unit"
export const USERFORM       = "/setup/userform";
export const USERS			= "/setup/users";