import React from 'react';
import { Button, Icon, Affix } from 'antd';

class CancelButton extends React.PureComponent {

    render() {

        const { onCancel } = this.props

        return (
            <>
                <div className='mediumLayout'>
                    <Affix offsetBottom="0" className="affixWithNav">
                        <Button type="primary" onClick={onCancel} style={{ width: "100%", borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                            <Icon type="close" />Cancel
                        </Button>
                    </Affix>
                </div>

                <div className='smallLayout'>
                    <Affix offsetBottom="0" className="affix">
                        <Button type="primary" onClick={onCancel} style={{ width: "100%", borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                            <Icon type="close" />Cancel
                        </Button>
                    </Affix>
                </div>
            </>
        )
    }
}
 
export default CancelButton;