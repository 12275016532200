import React from 'react';
import Status from '../../Status';
import ByteConverter from '@wtfcode/byte-converter'
import ConfirmCancelButtons from '../../controls/ConfirmCancelButtons';
import { Form, Input, Button, Select, Slider, Radio, Tooltip, Divider, Row, Col, Icon } from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { NAME, NAME_MSG, NUMBER, NUMBER_MSG } from "../../../constants/regex";
import { DEVICES, DEVICEDISCOVER } from '../../../constants/routes';
import { left, select, onvif } from "../../../api/actions";

const byteConverter = new ByteConverter()

// check if field has errors
function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field])
}

// dispatch action left to Redux store to set currentLeft
function mapDispatchToProps(dispatch) {
    return {
        select: record => dispatch(select(record)),
        left: currentLeft => dispatch(left(currentLeft)),
        onvif: device => dispatch(onvif(device))
    };
}

// map record, device and socket state from Redux store to props
function mapStateToProps(state) {
    return { 
        record: state.record,
        device: state.device,
        processMessage: state.processMessage
    };
}

class DeviceForm extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            freeStreamIds: [<Select.Option key={0} value="none">none</Select.Option>],
            error: null,
            isLoaded: false,
            isReloaded: true,
            stream: "none",
            name: null,
            address: null,
            username: null,
            password: null,
            serial: null,
            brand: null,
            model: null,
            used: 0,
            reserved: 0,
            reservedPercent: 0,
            available: 0,
            format: "MiB",
            state: 1,
            recordProfileOptions: [],
            streamingProfileOptions: [],
            analyticsProfileOptions: [],
            analytics: 0,
            prerecording: 20
        }

        this.show = this.show.bind(this);
        this.debug = window.config.debug
    }


    componentDidMount() {
        this.props.left("devices")
        this.props.form.validateFields()
        this.show()
    }

    componentWillUnmount() {
        this.props.select(null)
        this.props.onvif(null)
    }

    stateChange = value => this.setState({ state: value.target.value })
    nameChange = value => this.setState({ name: value.target.value })
    usernameChange = value => this.setState({ username: value.target.value })
    passwordChange = value => this.setState({ password: value.target.value })
    serialChange = value => this.setState({ serial: value.target.value })
    brandChange = value => this.setState({ brand: value.target.value })
    modelChange = value => this.setState({ model: value.target.value })
    addressChange = value => this.setState({ address: value.target.value })
    percentChange = value => this.setState({ reservedPercent: value })
    streamChange = value => this.setState({ stream: value })
    selectedRecordProfileChange = value => this.setState({ selectedRecordProfile: value })
    selectedStreamingProfileChange = value => this.setState({ selectedStreamingProfile: value })
    selectedAnalyticsProfileChange = value => this.setState({ selectedAnalyticsProfile: value })
    analyticsChange = value => this.setState({ analytics: value.target.value })
    prerecordingChange = value => this.setState({ prerecording: value.target.value })

    show() {

        var req = [{
            request: "devicesFreeStreamIds",
            method: "get",
            params: [{ id: 0 }] 
        }]

        // if no existing device is edited we need to know total available storage
        if (!this.props.record) {
            req.push({ 
                request: "storage",
                method: "get",
                params: [{ id: 0 }]
            })
        }

        this.setState({ isLoaded: false }, () => {
            this.props.processMessage(req)
            .then(res => { 

                const devicesFreeStreamIds = res["devicesFreeStreamIds"].params;

                for (let i=0; i<devicesFreeStreamIds?.length; i++) {
                    this.state.freeStreamIds.push(<Select.Option key={devicesFreeStreamIds[i]} value={devicesFreeStreamIds[i]}>{devicesFreeStreamIds[i]}</Select.Option>)
                }

                // modify hostname from discovery
                if (this.props.device) {
                    var deviceHost = new URL(this.props.device.uri)
                }

                // edited record, but no device from discovery
                if (this.props.record) {

                    const record = this.props.record
                    const recordProfiles = record.record_profiles
                    const streamingProfiles = record.streaming_profiles
                    const analyticsProfiles = record.analytics_profiles

                    this.initProfileOptions(recordProfiles, streamingProfiles, analyticsProfiles)

                    this.props.form.setFieldsValue({
                        state: record.state, 
                        stream: record.stream_id,
                        name: record.name,
                        address: record.address, 
                        username: record.username,
                        password: record.password,
                        serial: record.serial,
                        brand: record.brand,
                        model: record.model,
                        selectedRecordProfile: record.selected_record_profile,
                        selectedStreamingProfile: record.selected_streaming_profile,
                        selectedAnalyticsProfile: record.selected_analytics_profile,
                        analytics: record.analytics_enabled,
                        prerecording: record.pre_recording
                    })

                    this.setState({ 
                        state: record.state,
                        stream: record.stream_id,
                        name: record.name,
                        address: record.address, 
                        username: record.username,
                        password: record.password,
                        serial: record.serial,
                        brand: record.brand,
                        model: record.model,
                        used: record.used,
                        reserved: record.reserved,
                        reservedPercent: Math.round((record.reserved * 100) / (record.reserved + record.available)),
                        available: record.available,
                        format: record.format,
                        ptz: record.ptz,
                        selectedRecordProfile: record.selected_record_profile,
                        selectedStreamingProfile: record.selected_streaming_profile,
                        selectedAnalyticsProfile: record.selected_analytics_profile,
                        analytics: record.analytics_enabled,
                        prerecording: record.pre_recording
                    })

                    if (!record.reserved) {

                        const availableAutoScaled = byteConverter.autoScale((record.available*8), 'b') // byte to bit
                        const availableAutoScaledFormat = availableAutoScaled.dataFormat.replace('b', 'B') // bit to byte
                        const availableConvertedRounded = Math.round(((byteConverter.convert(availableAutoScaled.value, availableAutoScaled.dataFormat, availableAutoScaledFormat)) + Number.EPSILON) * 100) / 100

                        this.setState({ 
                            availableConverted: availableConvertedRounded,
                            format: availableAutoScaledFormat
                        })
                    }

                // no edited record, but device from discovery
                } else if (!this.props.record && this.props.device && res["storage"]) {

                    const storage = res["storage"]?.params[0]
                    const device = this.props.device
                    const availableAutoScaled = byteConverter.autoScale((storage.available*8), 'b') // byte to bit
                    const availableAutoScaledFormat = availableAutoScaled.dataFormat.replace('b', 'B') // bit to byte
                    const availableConvertedRounded = Math.round(((byteConverter.convert(availableAutoScaled.value, availableAutoScaled.dataFormat, availableAutoScaledFormat)) + Number.EPSILON) * 100) / 100

                    this.props.form.setFieldsValue({
                        name: device.hostname,
                        address: deviceHost.host,
                        model: device.hardware,
                    });

                    this.setState({ 
                        name: device.hostname,
                        address: deviceHost.host,
                        model: device.hardware,
                        available: storage.available,
                        availableConverted: availableConvertedRounded,
                        format: availableAutoScaledFormat
                    });

                // no edited record, no device from discovery
                } else if (!this.props.record && !this.props.device && res["storage"]) {

                    const storage = res["storage"].params[0];
                    const availableAutoScaled = byteConverter.autoScale((storage.available*8), 'b') // byte to bit
                    const availableAutoScaledFormat = availableAutoScaled.dataFormat.replace('b', 'B') // bit to byte
                    const availableConvertedRounded = Math.round(((byteConverter.convert(availableAutoScaled.value, availableAutoScaled.dataFormat, availableAutoScaledFormat)) + Number.EPSILON) * 100) / 100;

                    this.setState({
                        available: storage.available,
                        availableConverted: availableConvertedRounded,
                        format: availableAutoScaledFormat
                    })
                }

                if (this.debug) {
                    console.log("Showing storage available", this.state.available, "reserved", this.state.reserved, "reservedPercent", this.state.reservedPercent)
                }

            })
            .catch(error => {
                console.error(error)
                this.setState({ error })
            })
            .finally(() => this.setState({ isLoaded: true }))
        });
    }

    confirm() {

        const { reservedPercent, reserved, available, state, stream, name, address, username, password, brand, model, serial, ptz, selectedRecordProfile, selectedStreamingProfile, selectedAnalyticsProfile, analytics, prerecording } = this.state
        
        if (this.debug) {
            console.log("Confirming storage available", available, "reserved", reserved, "reservedPercent", reservedPercent)
        }

        this.setState({ isLoaded: false }, () => {
            this.props.processMessage([{
                request: "devices",
                method: "set",
                params: [{ 
                    id: this.props.record ? this.props.record.id : 0, // id of edited record or 0 for new record
                    state: state ? state : 0,
                    stream_id: (stream && state) ? parseInt(stream) : null, // int instead of string (sometimes "none")
                    name: name ? name : null, // null instead of empty string e.g. if input was cleared
                    address: address ? address : null,
                    username: username ? username : null,
                    password: password ? password : null,
                    brand: brand ? brand : null,
                    model: model ? model : null,
                    serial: serial ? serial : null,
                    reserved: reservedPercent ? reservedPercent : 0,
                    available: available ? available : 0,
                    ptz: ptz ? ptz : 0,
                    type: 1, // camera, 0 is NVR itself
                    selected_record_profile: selectedRecordProfile ? selectedRecordProfile : null,
                    selected_streaming_profile: selectedStreamingProfile ? selectedStreamingProfile : null,
                    selected_analytics_profile: selectedAnalyticsProfile ? selectedAnalyticsProfile : null,
                    analytics_enabled: analytics ? analytics : 0,
                    pre_recording: prerecording ? parseInt(prerecording) > 60 ? 60 : parseInt(prerecording) : 0 // min 0, max 60
                }]
            }])
            .then(() => { 
                this.props.history.push(DEVICES) // push Devices path to history props to call Devices
                this.props.select(null) // reset record props in redux store
            })
            .catch(error => {
                console.error(error)
                this.setState({ error })
            })
        })
    }

    cancel() {
        this.props.history.push(DEVICES) // link to Devices with Router history
    }

    onRefreshProfiles(record) {

        var recordId = record.id

        if (recordId) {
            this.setState({ isReloaded: false }, () => {    
                this.props.processMessage([
                    {
                        request: "onvif_profiles",
                        method: "set",
                        params: [{ id: recordId }]
                    },
                    { 
                        request: "devices",
                        method: "get",
                        params: [{ id: recordId }]
                    }
                ])
                .then(res => { 
                    var devParams = res["devices"].params[0]
                    var recordProfiles = devParams.record_profiles
                    var selectedRecordProfile = devParams.selected_record_profile
                    var streamingProfiles = devParams.streaming_profiles
                    var selectedStreamingProfile = devParams.selected_streaming_profile
                    var analyticsProfiles = devParams.analytics_profiles
                    var selectedAnalyticsProfile = devParams.selected_analytics_profile

                    this.initProfileOptions(recordProfiles, streamingProfiles, analyticsProfiles)
    
                    this.setState({ 
                        selectedRecordProfile,
                        selectedStreamingProfile,
                        selectedAnalyticsProfile
                    })

                    this.props.form.setFieldsValue({
                        selectedRecordProfile,
                        selectedStreamingProfile,
                        selectedAnalyticsProfile
                    })
                })
                .catch(error => {
                    console.error(error)
                    this.setState({ error })
                })
                .finally(() => this.setState({ isReloaded: true }))
            })
        }
    }

    parseProfile = (profile) => {

        const separatorEnabled = '/1/'
        const separatorDisabled = '/0/'

        var hasSeparatorEnabled = profile.indexOf(separatorEnabled) !== -1
        var hasSeparatorDisabled = profile.indexOf(separatorDisabled) !== -1
        var splittedProfile = profile.split(hasSeparatorEnabled ? separatorEnabled : separatorDisabled)
        var profileName = splittedProfile[0]

        if (splittedProfile.length > 1 && splittedProfile[1]) {
            profileName = `${splittedProfile[0]} ${splittedProfile[1]}`
        }

        return {
            value: splittedProfile[0],
            label: profileName,
            disabled: hasSeparatorDisabled
        }
    }

    initProfileOptions = (recordProfiles, streamingProfiles, analyticsProfiles) => {

        if (recordProfiles?.length) {
            this.setState({ recordProfileOptions: [] }, () => {
                var recordProfileOptions = []
                for (let i=0; i<recordProfiles?.length; i++) {
                    const { value, label, disabled } = this.parseProfile(recordProfiles[i])
                    recordProfileOptions.push(<Select.Option key={i} value={value} disabled={disabled}>{label}</Select.Option>)
                }
                this.setState({ recordProfileOptions })
            })
        }

        if (streamingProfiles?.length) {
            this.setState({ streamingProfileOptions: [] }, () => {
                var streamingProfileOptions = []
                for (let i=0; i<streamingProfiles?.length; i++) {
                    const { value, label, disabled } = this.parseProfile(streamingProfiles[i])
                    streamingProfileOptions.push(<Select.Option key={i} value={value} disabled={disabled}>{label}</Select.Option>)
                }
                this.setState({ streamingProfileOptions })
            })
        }

        if (analyticsProfiles?.length) {
            this.setState({ analyticsProfileOptions: [] }, () => {
                var analyticsProfileOptions = []
                for (let i=0; i<analyticsProfiles?.length; i++) {
                    const { value, label, disabled } = this.parseProfile(analyticsProfiles[i])
                    analyticsProfileOptions.push(<Select.Option key={i} value={value} disabled={disabled}>{label}</Select.Option>)
                }
                this.setState({ analyticsProfileOptions })
            })  
        }
    }

    render() {

        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form
        const { used, reserved, available, format, error, state, stream, freeStreamIds, isLoaded, isReloaded, recordProfileOptions, streamingProfileOptions, analyticsProfileOptions, selectedRecordProfile, selectedStreamingProfile, selectedAnalyticsProfile, analytics, prerecording } = this.state
        const { record } = this.props

        const marks = {}
        const usedPercent = (used * 100) / (reserved + available)
        const reservedPercent = (reserved * 100) / (reserved + available)
        const usedConvertedAndRouded = Math.round((byteConverter.convert(used, 'B', format) + Number.EPSILON) * 100) / 100
        const reservedAndAvailableConvertedAndRouded = Math.round((byteConverter.convert((reserved + available), 'B', format) + Number.EPSILON) * 100) / 100
        const nameError = isFieldTouched('name') && getFieldError('name')
        const addressError = isFieldTouched('address') && getFieldError('address')
        const isDisabled = !isLoaded || !record || !isReloaded
        const isSelectedRecordProfileDisabled = isDisabled || (!recordProfileOptions.length && !selectedRecordProfile)
        const isSelectedStreamingProfileDisabled = isDisabled || (!streamingProfileOptions.length && !selectedStreamingProfile)
        const isSelectedAnalyticsProfileDisabled = isDisabled || (!analyticsProfileOptions.length && !selectedAnalyticsProfile)
        const isSwitchAnalyticsDisabled = isSelectedAnalyticsProfileDisabled || !selectedAnalyticsProfile

        marks[usedPercent] = usedConvertedAndRouded + format
        marks[100] = reservedAndAvailableConvertedAndRouded + format

        if (!error) {
            return <>
                <Form 
                    labelCol={{ xs: { span: 24 }, sm: { span: 5 } }} 
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 15 } }} 
                    style={{ margin: "1.5em" }}
                >
                    
                    <Form.Item label="State" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('state', {
                            onChange: this.stateChange,
                            initialValue: state
                        })(
                            <Radio.Group style={{ width: "100%" }} buttonStyle="solid" disabled={!isLoaded}>
                                <Radio.Button value={1} style={{ width: "50%", textAlign: "center" }}>Active</Radio.Button>
                                <Radio.Button value={0} style={{ width: "50%", textAlign: "center" }}>Inactive</Radio.Button>
                            </Radio.Group>
                        )}
                    </Form.Item>

                    <Form.Item label="Name" style={{ marginBottom: 0 }} validateStatus={nameError ? 'error' : ''} help={nameError || ''}>
                        {getFieldDecorator('name', {
                            rules: [{ required: true, message: 'Please enter a name!' }, { pattern: NAME, message: NAME_MSG }],
                            onChange: this.nameChange,
                        })(
                            <Input placeholder="Please enter a device name" disabled={!isLoaded} />
                        )}
                    </Form.Item>

                    {record ? 
                    <Form.Item label="Address" style={{ marginBottom: 0 }} validateStatus={addressError ? 'error' : ''} help={addressError || ''}>
                        {getFieldDecorator('address', {
                            rules: [{ required: true, message: 'Please enter an address!' }, { pattern: NAME, message: NAME_MSG }],
                            onChange: this.addressChange,
                        })(
                            <Input placeholder="Please enter a device address" disabled={!isLoaded} />
                        )}
                    </Form.Item> :
                    <Form.Item label={<span className="ant-form-item-required">Address</span>} style={{ marginBottom: 0 }}>

                        <Form.Item style={{ marginBottom: 0, display: 'inline-block', width: 'calc(100% - 38px)' }} validateStatus={addressError ? 'error' : ''} help={addressError || ''}>
                            {getFieldDecorator('address', {
                                rules: [{ required: true, message: 'Please enter a valid address!' }, { pattern: NAME, message: NAME_MSG }],
                                onChange: this.addressChange,
                            })(
                                <Input placeholder="Please enter a device address" disabled={!isLoaded} />
                            )}
                        </Form.Item>
                        
                        <span style={{ display: 'inline-block', width: '6px'}}></span>

                        <Form.Item style={{ marginBottom: 0, marginTop: "1px", display: 'inline-block', width: '32px' }}>
                            <Tooltip title="Discover">
                                <Button icon="search" disabled={!isLoaded} onClick={() => this.props.history.push(DEVICEDISCOVER)} />
                            </Tooltip>
                        </Form.Item>

                    </Form.Item>}

                    <Form.Item label="Username" style={{ marginBottom: 0, marginTop: "-1px" }}>
                        {getFieldDecorator('username', {
                            rules: [{ pattern: NAME, message: NAME_MSG }],
                            onChange: this.usernameChange,
                        })(
                            <Input placeholder="anonymous" disabled={!isLoaded} />
                        )}
                    </Form.Item>

                    <Form.Item label="Password" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('password', {
                            rules: [{ pattern: NAME, message: NAME_MSG }],
                            onChange: this.passwordChange,
                        })(
                            <Input.Password placeholder="anonymous" disabled={!isLoaded} />
                        )}
                    </Form.Item>

                    <Form.Item label="Brand" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('brand', {
                            rules: [{ pattern: NAME, message: NAME_MSG }],
                            onChange: this.brandChange
                        })(
                            <Input placeholder="ONVIF" disabled={!isLoaded} />
                        )}
                    </Form.Item>

                    <Form.Item label="Model" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('model', {
                            rules: [{ pattern: NAME, message: NAME_MSG }],
                            onChange: this.modelChange
                        })(
                            <Input placeholder="Generic ONVIF" disabled={!isLoaded} />
                        )}
                    </Form.Item>

                    <Form.Item label="Serial" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('serial', {
                            rules: [{ pattern: NAME, message: NAME_MSG }],
                            onChange: this.serialChange,
                        })(
                            <Input placeholder="unknown" disabled={!isLoaded} />
                        )}
                    </Form.Item>

                    <Divider>Profiles & Abilities</Divider>

                    <Form.Item label="Recording Profile" style={{ marginBottom: 0 }}>

                        <Row gutter={[10,0]}>

                            <Col xs={24} sm={12}>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('selectedRecordProfile', {
                                        onChange: this.selectedRecordProfileChange,
                                        initialValue: selectedRecordProfile
                                    })(
                                        <Select 
                                            disabled={isSelectedRecordProfileDisabled} 
                                            loading={!isReloaded} 
                                            placeholder="unknown"
                                        >
                                            {recordProfileOptions}
                                        </Select> 
                                    )}
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12}>
                                <Form.Item 
                                    label="Pre-Recording" 
                                    style={{ marginBottom: 0 }} 
                                    labelCol={{ xs: 24, sm: 8 }} 
                                    wrapperCol={{ xs: 24, sm: 16 }}
                                >
                                    {getFieldDecorator('prerecording', {
                                        onChange: this.prerecordingChange,
                                        initialValue: prerecording,
                                        rules: [{ pattern: NUMBER, message: NUMBER_MSG }]
                                    })(
                                        <Input 
                                            placeholder="0" 
                                            style={{ width: "100%" }} 
                                            disabled={isSelectedRecordProfileDisabled}
                                            addonAfter="seconds"
                                            loading={!isReloaded}
                                        />
                                    )}
                                </Form.Item>
                            </Col>

                        </Row>

                    </Form.Item>

                    <Form.Item label="Recording Storage" style={{ marginBottom: 0 }}>
                        {getFieldDecorator('reserved', {
                            onChange: this.percentChange,
                            initialValue: reservedPercent
                        })(
                            <Slider
                                loading={!isReloaded}
                                disabled={isSelectedRecordProfileDisabled}
                                min={usedPercent}
                                marks={marks}
                                tipFormatter={value => (Math.round((byteConverter.convert((value * (available + reserved) / 100), 'B', format) + Number.EPSILON) * 100) / 100) + format + " reserved"}
                            />
                        )}
                    </Form.Item>

                    <Form.Item label="Streaming Profile" style={{ marginBottom: 0 }}>
                        <Row gutter={[10,0]}>
                        
                            <Col xs={24} sm={12}>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('selectedStreamingProfile', {
                                        onChange: this.selectedStreamingProfileChange,
                                        initialValue: selectedStreamingProfile
                                    })(
                                        <Select 
                                            disabled={isSelectedStreamingProfileDisabled} 
                                            loading={!isReloaded} 
                                            placeholder="unknown"
                                        >
                                            {streamingProfileOptions}
                                        </Select> 
                                    )}
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12}>
                                <Form.Item 
                                    label="Stream" 
                                    style={{ marginBottom: 0 }} 
                                    labelCol={{ xs: 24, sm: 8 }} 
                                    wrapperCol={{ xs: 24, sm: 16 }}
                                >
                                    {getFieldDecorator('stream', {
                                        onChange: this.streamChange,
                                        initialValue: stream
                                    })(
                                        <Select 
                                            placeholder="none"
                                            disabled={isSelectedStreamingProfileDisabled}
                                            loading={!isReloaded}
                                        >
                                            {freeStreamIds}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            
                        </Row>
                    </Form.Item>

                    <Form.Item label="Analytics Profile" style={{ marginBottom: 0 }}>
                        <Row gutter={[10,0]}>
                            
                            <Col xs={24} sm={12}>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    {getFieldDecorator('selectedAnalyticsProfile', {
                                        onChange: this.selectedAnalyticsProfileChange,
                                        initialValue: selectedAnalyticsProfile
                                    })(
                                        <Select 
                                            disabled={isSelectedAnalyticsProfileDisabled} 
                                            loading={!isReloaded} 
                                            placeholder="unknown"
                                        >
                                            {analyticsProfileOptions}
                                        </Select> 
                                    )}
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12}>
                                <Form.Item 
                                    label="Analytics" 
                                    style={{ marginBottom: 0 }} 
                                    labelCol={{ xs: 24, sm: 8 }} 
                                    wrapperCol={{ xs: 24, sm: 16 }}
                                >
                                    {getFieldDecorator('analytics', {
                                        onChange: this.analyticsChange,
                                        initialValue: analytics
                                    })(
                                        <Radio.Group 
                                            style={{ width: "100%" }} 
                                            buttonStyle="solid" 
                                            disabled={isSwitchAnalyticsDisabled}
                                            loading={!isReloaded}
                                        >
                                            <Radio.Button value={1} style={{ width: "50%", textAlign: "center" }}>Active</Radio.Button>
                                            <Radio.Button value={0} style={{ width: "50%", textAlign: "center" }}>Inactive</Radio.Button>
                                        </Radio.Group>
                                    )}
                                </Form.Item>
                            </Col>
                            
                        </Row>

                    </Form.Item>

                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 15, offset: 5 }}
                        style={{ height: "38px", display: "flex", alignItems: "center" }}
                    >
                        <Button 
                            type="primary" 
                            style={{ width: "100%", height: "30px" }}
                            onClick={record ? this.onRefreshProfiles.bind(this, record) : null} 
                            disabled={isDisabled || hasErrors(getFieldsError())}
                            loading={!isReloaded} 
                        >
                            <Icon type="reload" />Refresh Profiles
                        </Button>
                    </Col>

                </Form>

                <ConfirmCancelButtons 
                    loading={!isLoaded} 
                    disabled={hasErrors(getFieldsError())} 
                    onConfirm={this.confirm.bind(this)} 
                    onCancel={this.cancel.bind(this)}
                />
            </>
        } else {
            return <Status is={error} back={DEVICES} />
        }
    }
}

export default Form.create() (connect(mapStateToProps,mapDispatchToProps)(withRouter(DeviceForm)))