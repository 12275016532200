import React from 'react';
import Status from '../../Status';
import Highlighter from 'react-highlight-words';
import AddButton from '../../controls/AddButton';
import { Table, Button, Icon, Divider, Popconfirm, BackTop, Input } from 'antd';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { CONDITIONFORM, CONDITIONS } from '../../../constants/routes';
import { select, left } from "../../../api/actions";

// dispatch actions select and left to Redux store to set record and currentLeft
function mapDispatchToProps(dispatch) {
    return {
        select: record => dispatch(select(record)),
        left: currentLeft => dispatch(left(currentLeft))
    };
}

// map socket state from Redux store to props
function mapStateToProps(state) {
    return { 
        processMessage: state.processMessage,
        sortMessage: state.sortMessage
    };
}

class Conditions extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            error: null,
            isLoaded: false,
            searchText: "",
            searchedColumn: ""
        };

        this.show = this.show.bind(this);
        this.nvrLabel = "NVR-Device"
        this.debug = window.config.DEBUG
    }

    componentDidMount() {
        this.props.select(null)
        this.props.left("conditions")
        this.setState({ isLoaded: false }, () => this.show())
    }

    show() {
        
        this.props.processMessage([
            { 
                request: "simpleconditions",
                method: "get",
                params: [{ id: 0 }]
            }
        ])
        .then(res => {
            const conditions = res["simpleconditions"].params;
            this.debug && console.log("simpleconditions", conditions);
            const conditionsWithoutDi = conditions.filter(condition => !condition.name.includes("nvrdi"));
            this.setState({ data: conditionsWithoutDi })
        })
        .catch(error => {
            console.error(error);
            this.setState({ error });
        })
        .finally(() => this.setState({ isLoaded: true }));
    }

    edit(record) {
        this.props.select(record); // set record object in redux store by selected row
    }

    delete(id) {

        this.setState({ isLoaded: false }, () => {
            this.props.processMessage([{ 
                request: "simpleconditions",
                method: "del",
                params: [{ id: id }]
            }])
            .then(() => this.show())
            .catch(error => {
                console.error(error);
                this.setState({ 
                    error,
                    isLoaded: true
                });
            })
        });
    }

    getColumnSearchProps = dataIndex => ({
        
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => { this.searchInput = node; }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />

                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>

                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),

        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#EE3350' : undefined }} />
        ),

        onFilter: (value, record) => {
            if (dataIndex === "device.name") {
                if (record["device"])
                    if (record["device"]["name"])
                        return record["device"]["name"]
                            .toString()
                            .toLowerCase()
                            .includes(value.toLowerCase())
            } else if (record[dataIndex])
                return record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
        },

        onFilterDropdownVisibleChange: visible => {
            if (visible) setTimeout(() => this.searchInput.select());
        },

        render: (text, record) => {
            if (text) { 
                if (this.state.searchedColumn === dataIndex) { 
                    return <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[this.state.searchText]}
                        autoEscape
                        textToHighlight={text.toString()}
                    />
                 } else {
                    return text;
                }
            } else if (dataIndex === "device.name" && record.type === "DigitalInput" && !record.device) {
                return this.nvrLabel
            } else { 
                return "-" 
            }
        }
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: "" });
    };

    render() {

        const { error, data, isLoaded } = this.state;

        // show table when isLoaded is true or show status when error
        if (!error) {
            return <>
                <Table
                    style={{ height: data.length ? "100%" : "unset" }}
                    rowKey={record => record.id} 
                    dataSource={data}
                    loading={!isLoaded}
                    pagination={false}
                    size="small"
                    scroll={{ x: 'max-content' }}
                    columns={[
                        {
                            title: 'Name',
                            dataIndex: 'name',
                            sorter: (a, b) => this.props.sortMessage({ a, b, value: "name" }),
                            ...this.getColumnSearchProps('name')
                        },
                        {
                            title: 'Device',
                            dataIndex: 'device.name',
                            sorter: (a, b) => this.props.sortMessage({ a, b, value: "device", subvalue: "name" }),
                            ...this.getColumnSearchProps('device.name')
                        },
                        {
                            title: 'Type',
                            dataIndex: 'type',
                            sorter: (a, b) => this.props.sortMessage({ a, b, value: "type" }),
                            ...this.getColumnSearchProps('type')
                        },
                        {
                            title: 'Inverted',
                            dataIndex: 'neg',
                            sorter: (a, b) => this.props.sortMessage({ a, b, value: "neg", numeric: true }),
                            render: neg => neg ? "true" : "false"
                        },
                        {
                            title: 'Controls',
                            render: record => <>
                                <Link to={CONDITIONFORM}>
                                    <Button type="link" onClick={this.edit.bind(this, record)}>
                                        <Icon type="edit" />Edit
                                    </Button>
                                </Link>

                                <Divider type="vertical" />

                                <Popconfirm 
                                    title="Are you sure?"
                                    okText='Yes'
                                    okType='danger'
                                    cancelText='No'
                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                    onConfirm={this.delete.bind(this, record.id)}
                                >
                                    <Button type="link">
                                        <Icon type="delete" />Delete
                                    </Button>
                                </Popconfirm>
                            </>,
                        },
                    ]}
                />

                <AddButton
                    linkTo={CONDITIONFORM}
                    loading={!isLoaded}
                />

                <BackTop 
                    style={{
                        right: "5px",
                        bottom: "unset",
                        top: "52px",
                    }}
                />
            </>
        } else {
            return <Status is={error} back={CONDITIONS} />
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Conditions);