import React from 'react';
import CancelButton from '../../controls/CancelButton';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { DEVICES } from '../../../constants/routes';
import { Spin, Row } from 'antd';
import { select, left } from "../../../api/actions";

function mapDispatchToProps(dispatch) {
    return {
        select: record => dispatch(select(record)),
        left: currentLeft => dispatch(left(currentLeft))
    }
}

function mapStateToProps(state) {
    return {
        record: state.record
    }
}

class DeviceAccess extends React.PureComponent {

    componentDidMount() {
        this.props.left("devices")
    }

    cancel() {
        this.props.history.push(DEVICES)
    }

    componentWillUnmount() {
        //this.props.select(null) // reconnect wsock seems to lead to unmount and record is lost
    }

    render() {

        const record = this.props.record

        return <>
            {record ? (
                <div style={{
                    height: "100%",
                    width: "100%",
                    overflow: "hidden"
                }}>
                    <iframe
                        src={record.src}
                        style={{ 
                            width: '100%', 
                            height: '100%',
                            border: "none"
                        }}
                        title={record.title}
                        name="camhead"
                    ></iframe>
                </div>
            ) : (
                <Row type="flex" style={{ alignItems: "center", justifyContent: "center", height: "100%" }}>
                    <Spin size="large" />
                </Row>
            )}

            <CancelButton onCancel={this.cancel.bind(this)} />
        </>
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(DeviceAccess))