import React from 'react';
import { Button, Icon, Affix } from 'antd';

class ConfirmButton extends React.PureComponent {

    render() {

        const { onConfirm, loading } = this.props

        return (
            <>
                <div className='mediumLayout'>
                    <Affix offsetBottom="0" className="affixWithNav">
                        <Button 
                            type="primary" 
                            onClick={onConfirm} 
                            style={{ 
                                width: "100%", 
                                borderBottomLeftRadius: 0, 
                                borderBottomRightRadius: 0 
                            }}
                            loading={loading}
                        >
                        <Icon type="check" />Confirm
                        </Button>
                    </Affix>
                </div>

                <div className='smallLayout'>
                    <Affix offsetBottom="0" className="affix">
                        <Button 
                            type="primary" 
                            onClick={onConfirm} 
                            style={{ 
                                width: "100%", 
                                borderBottomLeftRadius: 0, 
                                borderBottomRightRadius: 0 
                            }}
                            loading={loading}
                        >
                        <Icon type="check" />Confirm
                        </Button>
                    </Affix>
                </div>
            </>
        )
    }
}
 
export default ConfirmButton;