import React, { createRef } from 'react';
import dayjs from 'dayjs';
import * as d3 from 'd3';
import { NOTIFICATIONS } from "../../constants/routes"
import { connect } from "react-redux";
import { select } from "../../api/actions";
import { Popover, Icon } from 'antd';
import { withRouter } from "react-router-dom";

function mapStateToProps(state) {
    return { 
        record: state.record,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        select: record => dispatch(select(record)),
    };
}

var startTime = 0;
var endTime = 0;

class Timeline extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,
            popoverPlacement: "top"
        };
        
        this.getPercentForOneHour = this.getPercentForOneHour.bind(this); 
        this.markWithMouse = this.markWithMouse.bind(this); 
        this.calcDiffStartEndTime = this.calcDiffStartEndTime.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.myRect = createRef();
        this.myCircle = createRef();
        this.debug = window.config.DEBUG
    }

    componentDidMount() {

        this.markWithMouse();
        this.updateWindowDimensions();

        if (this.props.record?.resetSelection) {
            this.props.select({ resetSelection: false });
        }

        window.addEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        
        const markStartText = d3.selectAll("text.markStartText");
        const markEndText = d3.selectAll("text.markEndText");
        const startText = d3.selectAll("text.startText");
        const middleText = d3.selectAll("text.middleText");
        const endText = d3.selectAll("text.endText");
        const durationText = d3.selectAll("text.durationText");

        if (this.state.width < 592 && this.state.width > 0) {
            markStartText.attr('font-size', "smaller");
            markEndText.attr('font-size', "smaller");
            startText.attr('font-size', "smaller");
            endText.attr('font-size', "smaller");
            middleText.attr('font-size', "smaller");
            durationText.attr('font-size', "smaller");
        } else {
            markStartText.attr('font-size', "medium");
            markEndText.attr('font-size', "medium");
            startText.attr('font-size', "medium");
            endText.attr('font-size', "medium");
            middleText.attr('font-size', "14px");
            durationText.attr('font-size', "14px");
        }

        if (!this.props.record?.resetSelection) {
            this.props.select({ resetSelection: true });
        }
        
        (this.state.width !== window.innerWidth) && this.setState({ width: window.innerWidth })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props.data[0] !== prevProps.data[0]) {
    //         startTime = this.props.data[0]
    //     }
    //     if (this.props.data[1] !== prevProps.data[1]) {
    //         endTime = this.props.data[1]
    //     }
    // } 

    // returns the difference between endTime and startTime of the timeline
    calcDiffStartEndTime() {
        var difEndStartTime = endTime - startTime;
        return difEndStartTime;
    }

    // this funktion make it possible to mark a area with the mouse in the timeline
    // the inner "selectionRect"-Class had the function to draw the marked area as an rect in the svg-DOM element in HTML
    markWithMouse() {

        const svgArea = d3.selectAll("svg.item");

        var selectionRect = {
            element			: null,
            previousElement : null,
            starttimeTextelement            : null,
            starttimePreviousTextElement    : null,
            endtimeTextelement              : null,
            endtimePreviousTextElement      : null,
            durationTextelement             : null,
            durationPreviousTextElement     : null,
            currentY		: 0,
            currentX		: 0,
            originX			: 0,
            originY			: 0,
            firstClickX     : 0,
            startTimeDate   : null,
            endTimeDate     : null,
            duration        : null,

            // set mark area as svg-rect Element
            setElement: function(ele) {
                this.previousElement = this.element;
                this.element = ele;
            },

            // set svg-Element to the upper left corner of the timeline
            setStarttimeElement: function(ele) {
                this.starttimePreviousTextElement = this.starttimeTextelement;
                this.starttimeTextelement = ele;
            },

            // set svg-Element to the upper right corner of the timeline
            setEndtimeElement: function(ele) {
                this.endtimePreviousTextElement = this.endtimeTextelement;
                this.endtimeTextelement = ele;
            },

            // set svg-Element to the upper middle of the timeline
            setDurationElement: function(ele) {
                this.durationPreviousTextElement = this.durationTextelement;
                this.durationTextelement = ele;
            },

            // set Time value as for the upper left svg-Text Element
            setStartTimeDate: function(date) {
                this.startTimeDate = date;
            },

            // set Time value as for the upper right svg-Text Element
            setEndTimeDate: function(date) {
                this.endTimeDate = date;
            },

            // set Duration value as for the middle svg-Text Element
            setDuration: function(elem) {
                this.duration = elem;
            },

            // return new Attributes of the mark area as dictionary
            getNewAttributes: function() {

                var x = this.currentX < this.originX ? this.currentX : this.originX;
                var width = Math.abs(this.currentX - this.originX);

                return { "x": x, "width": width };
            },

            // return current Attributes of the mark area as dictionary
            getCurrentAttributes: function() {
                // use plus sign to convert string into number
                var x =+ this.element.attr("x");
                var width =+ this.element.attr("width");

                return { startPointX: x, endPointX: x + width };
            },

            // initialized the text area in the upper left corner of the timeline as an svg-text with attributes and style 
            initStarttime: function(x, d3svg) {

                var text = d3svg
                    .append("text")
                    .attr("class", "markStartText")
                    .attr("x", 0 + "%")    
                    .attr("y", 25 + "%")          
                    .attr("id", "startTime")
                    .style("fill", "#EE3350")
                    .style("user-select", "none")
                    .style("cursor", "default")
                    .text(this.startTimeDate)

                this.setStarttimeElement(text);              
            },

            // initialized the text area in the upper right corner of the timeline as an svg-text with attributes and style 
            initEndtime: function(x, d3svg) {

                var text = d3svg
                    .append("text")
                    .attr("class", "markEndText")
                    .attr("x", 100 + "%")    
                    .attr("y", 25 + "%")           
                    .attr("id", "endTime")
                    .style("text-anchor", "end")
                    .style("fill", "#EE3350")
                    .style("position", "static")
                    .style("user-select", "none")
                    .style("cursor", "default")
                    .text(this.endTimeDate)

                this.setEndtimeElement(text);
            },

            // initialized the text area in the upper right corner of the timeline as an svg-text with attributes and style 
            initDuration: function(x, d3svg) {

                var text = d3svg
                    .append("text")
                    .attr("class", "durationText")
                    .attr("x", 50 + "%")    
                    .attr("y", 20 + "%")           
                    .attr("id", "duration")
                    .style("text-anchor", "middle")
                    .style("fill", "#EE3350")
                    .style("position", "static")
                    .style("user-select", "none")
                    .style("cursor", "default")
                    .text(this.duration)

                this.setDurationElement(text);
            },

            // change EndTime of svg-Text Element when the mark area ist changes his width
            updateEndTime: function(newX, d3svg) {

                var svgWidth = d3svg.select("rect").node().getBoundingClientRect().width || globalMyRect.getBoundingClientRect().width

                globalDebug && console.log("[updateEndTime] timeline width", svgWidth)

                if (!(newX < 0 || newX > svgWidth)) { 
                    this.starttimeTextelement.text(this.startTimeDate);
                    this.endtimeTextelement.text(this.endTimeDate);  
                    this.durationTextelement.text(getSelectionDuration);
                }
            },

            // initialized the mark area as an svg-rectangle with attributes and style
            init: function(newX, newY, d3svg) {

                var rectElement = d3svg
                    .append("rect")
                    .attr("x", 0)
                    .attr("y", 29 + "%")
                    .attr("rx", 3) 
                    .attr("ry", 3)
                    .attr("width", 0)
                    .attr("height", 70 + "%")
                    .style("fill", "red")
                    .attr("fill-opacity", 0.5)
                    .attr("id", 'markRect');
                
                this.setElement(rectElement);
                this.firstClickX = newX;
                this.originX = newX;
                this.originY = newY;
                this.update(newX, newY);
            },

            // update attribute of the current mark area element
            update: function(newX, newY) {
                this.currentX = newX;
                this.currentY = newY;
                var newAttributes = this.getNewAttributes();
                this.element.attr("x", newAttributes.x).attr("width", newAttributes.width);
            },

            // set border around mark area
            focus: function() {
                this.element
                    .style("stroke", "#DE695B")
                    .style("stroke-width", "1");
            },

            // delete mark rect and textElements from svg-timeline
            remove: function() {
                this.element.remove();
                this.element = null;
                this.starttimeTextelement.remove();
                this.starttimeTextelement = null;
                this.endtimeTextelement.remove();
                this.endtimeTextelement = null;
                this.durationTextelement.remove();
                this.durationTextelement = null;
            },

            // delete previous mark rect and textElements from svg-timeline
            removePrevious: function() {
                if (this.previousElement) 
                    this.previousElement.remove();
                if (this.starttimePreviousTextElement) 
                    this.starttimePreviousTextElement.remove();
                if (this.endtimePreviousTextElement) 
                    this.endtimePreviousTextElement.remove();
                if (this.durationPreviousTextElement) 
                    this.durationPreviousTextElement.remove();
            },

            // returns a percentage for a mark area on the timeline for one svgWitdh length
            onePercentSvgWidth: function(d3svg) {
                var svgWidth = d3svg.select("rect").node().getBoundingClientRect().width || globalMyRect.getBoundingClientRect().width
                globalDebug && console.log("[onePercentSvgWidth] timeline width", svgWidth)
                var onePercent = 100/svgWidth;
                return (isFinite(onePercent) && onePercent) || 0;
            },

            // return percentage value from the endpoint of the mark area on the x-axis
            getPercentEndpointX: function(d3svg) {
                var currentAttributes = this.getCurrentAttributes()
                var res = this.onePercentSvgWidth(d3svg)*currentAttributes.endPointX
                globalDebug && console.log("[getPercentEndpointX]", res)
                return res;
            },

            // return percentage value from the startpoint of the mark area on the x-axis
            getPercentStartpointX: function(d3svg) {
                var currentAttributes = this.getCurrentAttributes()
                var res = this.onePercentSvgWidth(d3svg)*currentAttributes.startPointX
                globalDebug && console.log("[getPercentStartpointX]", res)
                return res;
            },
        }

        function getSelectionDuration() {
            var start = dayjs(selectionRect.startTimeDate)
            var end = dayjs(selectionRect.endTimeDate)

            var remainder = end.diff(start, "second") // seconds
            const secs = remainder % 60
            remainder = Math.trunc((remainder - secs) / 60) // minutes
            const mins = remainder % 60
            remainder = Math.trunc((remainder - mins) / 60) // hours
            const hours = remainder
            
            return `${hours < 10 ? "0" : ""}${hours}:${mins < 10 ? "0" : ""}${mins}:${secs < 10 ? "0" : ""}${secs}`
        }

        // mouseclick -> starts marking in the timeline
        function dragStart() {

            var coords = d3.mouse(this);

            selectionRect.init(coords[0], coords[1], svgArea);        
            selectionRect.setStartTimeDate(getTimeMarckedAreaStartpoint_Dateformat());
            selectionRect.setEndTimeDate(null);
            selectionRect.setDuration(null);
            selectionRect.initStarttime(coords[0], svgArea);
            selectionRect.initEndtime(coords[0], svgArea);
            selectionRect.initDuration(coords[0], svgArea);
            selectionRect.removePrevious();
        }

        // mousedown -> when the mouse is moved, the marked area becomes larger or smaller, and the start- and endtime shows in the corner of the timeline
        function dragMove() {

            var coords = d3.mouse(this);

            selectionRect.update(coords[0], coords[1]);
            selectionRect.setEndTimeDate(getTimeMarckedAreaEndpoint_Dateformat());
            selectionRect.setStartTimeDate(getTimeMarckedAreaStartpoint_Dateformat());
            selectionRect.updateEndTime(coords[0], svgArea);
        }

        // mouseup -> stops marking the timeline and takes the start- and endtime of the mark area back to the Archive component
        function dragEnd() {
            
            var finalAttributes = selectionRect.getCurrentAttributes();

            if ((finalAttributes.endPointX - finalAttributes.startPointX) > 1) { // difference between start and end of selection
                d3.event.sourceEvent.preventDefault();
                globalReturnTimesToArchive(selectionRect.startTimeDate, selectionRect.endTimeDate);
            } else {
                selectionRect.remove();
                globalDisableZoomIn(); // disable zoom in button 
            }
        }  

        // return the time where the mark area begins in Unix
        function getTimeMarckedAreaStartpoint_Unix() {
            var timeStartx = (this.calcDiffStartEndTime()/100) * selectionRect.getPercentStartpointX(svgArea);
            return (timeStartx + startTime);
        }

        // return the time where the mark area end in Unix
        function getTimeMarckedAreaEndpoint_Unix() {
            var timeEndx = (this.calcDiffStartEndTime()/100) * selectionRect.getPercentEndpointX(svgArea);
            return (timeEndx + startTime);
        }

        // return the time where the mark area begins in a formated date string
        function getTimeMarckedAreaStartpoint_Dateformat() {
            return dayjs.unix(globalGetTimeMarckedAreaStartpoint_Unix()).format("YYYY-MM-DD HH:mm:ss");
        }

        // return the time where the mark area ends in a formated date string
        function getTimeMarckedAreaEndpoint_Dateformat() {
            return dayjs.unix(globalGetTimeMarckedAreaEndpoint_Unix()).format("YYYY-MM-DD HH:mm:ss");
        }

        // passes varibles to the Archive component with the react-redux library
        function returnTimesToArchive(start, end) {

            var resetSelection = false;
            var newStart = dayjs(start); // "YYYY-MM-DD HH:mm:ss" formatted string to dayjs object
            var newEnd = dayjs(end);
            var oldStart = dayjs.unix(startTime); // unix timestamp to dayjs object
            var oldEnd = dayjs.unix(endTime);
            var enableZoomIn = true;

            if (dayjs(end).unix() - dayjs(start).unix() <= 60) { // if selected area is <60s, the zoom in button is disabled
                enableZoomIn = false;
            }

            this.props.select({ 
                newStart, 
                newEnd, 
                resetSelection, 
                oldStart, 
                oldEnd, 
                enableZoomIn
            });
        }

        function disableZoomIn() {
            if (this.props.record?.enableZoomIn) {
                this.props.select({ enableZoomIn: false })
            }
        }

        const globalDisableZoomIn = disableZoomIn.bind(this);
        const globalReturnTimesToArchive = returnTimesToArchive.bind(this);
        const globalGetTimeMarckedAreaStartpoint_Unix = getTimeMarckedAreaStartpoint_Unix.bind(this);
        const globalGetTimeMarckedAreaEndpoint_Unix = getTimeMarckedAreaEndpoint_Unix.bind(this);
        const globalMyRect = this.myRect.current
        const globalDebug = this.debug

        var dragBehavior = d3.drag()
            .on("start", dragStart)
            .on("drag", dragMove)
            .on("end", dragEnd);
            
        svgArea.call(dragBehavior);
    }

    //returns a percentage for a recording area on the timeline for one hour
    getPercentForOneHour(startTimeRecords, endTimeRecords) {
        return 100 / (endTimeRecords - startTimeRecords);
    }

    // returns a percentage value for the starting point on the x-axis for a recording area
    getXStartValue(percentForOneHour, recordStartTime, timelineStartTime) {
        return percentForOneHour * (recordStartTime - timelineStartTime);
    }
    
    // returns a percentage value for the witdh on the x-axis for a recording area
    getWidthValue(percentForOneHour, recordEndTime, timelineStartTime) {
        return percentForOneHour * (recordEndTime - timelineStartTime);
    }

    onClickPopover(record) {
        this.props.select(record)
        this.props.currentTop !== "notifications" && this.props.history.push(NOTIFICATIONS) 
    }

    setPopoverPlacement = () => {

        if (this.myCircle?.current) {

            const myCircle = this.myCircle.current.getBoundingClientRect();
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
    
            const isLeftHalf = myCircle.left < windowWidth / 2;
            const isTopHalf = myCircle.top < windowHeight / 2;
    
            const isMiddleX = myCircle.left + myCircle.width / 2 >= windowWidth / 3 && myCircle.right - myCircle.width / 2 <= (windowWidth / 3) * 2;
            const isMiddleY = myCircle.top + myCircle.height / 2 >= windowHeight / 3 && myCircle.bottom - myCircle.height / 2 <= (windowHeight / 3) * 2;
    
            let placement = "topRight"
    
            if (isLeftHalf && isTopHalf) {
                placement = "bottomLeft";
            } else if (!isLeftHalf && isTopHalf) {
                placement = "bottomRight";
            } else if (isLeftHalf && !isTopHalf) {
                placement = "topLeft";
            }
    
            if (isLeftHalf && isMiddleY) {
                placement = "right";
            } else if (!isLeftHalf && isMiddleY) {
                placement = "left";
            } else if (isTopHalf && isMiddleX) {
                placement = "bottom";
            } else if (!isTopHalf && isMiddleX) {
                placement = "top";
            }
    
            this.debug && console.log("popoverPlacement", placement)
    
            this.setState({ popoverPlacement: placement });
        }
    
    };


    // shows svg-timeline and recorded video as an rectangle in svg, removes selected area when resetSelection is true
    render() {  
        
        if (!!this.props.record?.resetSelection) {
            const selectionElements = this.refs.mySvg?.children

            selectionElements?.markRect?.remove()
            selectionElements?.startTime?.remove()
            selectionElements?.endTime?.remove()
            selectionElements?.duration?.remove()
        }

        if (this.props.data) {
            
            var records = this.props.data[2]
            startTime = this.props.data[0]
            endTime = this.props.data[1]

            if (records?.length > 0) {

                this.debug && console.log("[render] records", records)

                return (
                    <svg width="100%" height="80px">  

                        <svg className="item" ref={"mySvg"} x="0" y="0" width="100%" height="80%">

                            <rect
                                ref={this.myRect}
                                rx="3" 
                                ry="3" 
                                x="0" 
                                y="29%" 
                                width="100%" 
                                height="70%" 
                                strokeWidth="1" 
                                style={{ 
                                    stroke: "rgba(0, 0, 0, .2)", 
                                    fill: "rgba(255, 255, 255, 1)" 
                                }} 
                            />
                            
                            {records.map((record, index) => {

                                var percentForOneHour = this.getPercentForOneHour(startTime, endTime);
                                var xStart = this.getXStartValue(percentForOneHour, record.start, startTime);
                                var widthEnd = this.getWidthValue(percentForOneHour, record.end, startTime);

                                var color;
                                var lineHeight;
                                var rectPos;
                                var rectHeight;

                                const isNotification = record.level !== undefined && record.level !== null

                                if (record.triggered) { // notification

                                    color = "#1AACED"; // lightblue, #1A77A1 darkblue
                                    lineHeight = "99%"; // full size
                                    rectHeight = "35%"; // half size
                                    rectPos = "29%"; // higher part

                                    if (record.triggered === record.start) { // no recording but maybe event group

                                        lineHeight = "99%"; // full size
                                        rectHeight = "70%"; // full size
                                        rectPos = "29%"; // higher part
                                        color = "mediumseagreen"; // info

                                        if (record.level === 1) { // warning
                                            color = "sandybrown";
                                        }

                                        if (record.level === 2) { // error
                                            color = "lightcoral";
                                        }
                                    } 

                                } else { // recording

                                    color = "grey"; // original was #EE3350
                                    lineHeight = "99%"; // full size
                                    rectHeight = "35%"; // half size
                                    rectPos = "64%"; // lower part
                                }

                                if (record.readOnly) {

                                    color = "black"; // black line with grey rectangle for locked recordings
                                }

                                if (isFinite(xStart) && isFinite(widthEnd)) {
                                    return (
                                        <g key={index + "_g"}>
                                            <Popover           
                                                overlayStyle={{ maxWidth: "80vw" }}
                                                placement={this.state.popoverPlacement}
                                                onMouseEnter={this.setPopoverPlacement}
                                                content={ 
                                                    <>
                                                        <div style={{ 
                                                            marginBottom: 8, 
                                                            display: 'block', 
                                                            userSelect: "none",
                                                            cursor: "default"
                                                        }}>
                                                            <Icon type="bell" theme="twoTone" twoToneColor={color} />
                                                            &nbsp;{record.event}
                                                        </div>
                                                        <div style={{ 
                                                            marginBottom: !!record.message ? 8 : 4, 
                                                            display: 'block', 
                                                            userSelect: "none", 
                                                            cursor: "default" 
                                                        }}>
                                                            <Icon type="clock-circle" theme="twoTone" twoToneColor={color} /> 
                                                            &nbsp;{dayjs.unix(record.start).format("YYYY-MM-DD HH:mm:ss")}
                                                        </div>
                                                        {!!record.message &&
                                                        <div style={{ 
                                                            marginBottom: 4, 
                                                            display: 'block', 
                                                            userSelect: "none", 
                                                            cursor: "default"
                                                        }}>
                                                            <Icon type="message" theme="twoTone" twoToneColor={color} /> 
                                                            &nbsp;{record.message.replace(/utc:(\d+)/g, (match, unixTimestamp) => dayjs.unix(unixTimestamp).format("YYYY-MM-DD HH:mm:ss"))}
                                                        </div>}
                                                    </>
                                                }
                                                onClick={() => isNotification && this.onClickPopover(record)}
                                            >
                                                <circle
                                                    ref={this.myCircle} // TODO: ref sometimes only works for last rendered timeline! (interval was changed to hour)
                                                    cx={xStart + "%"} // x-Koordinate des Mittelpunkts
                                                    cy="29%" // y-Koordinate des Mittelpunkts
                                                    r="4" // Radius
                                                    fill={color}
                                                    className={isNotification ? "svg-circle-mouseover" : undefined} // show cursor pointer only for notifications
                                                />
                                            </Popover>

                                            <line 
                                                x1={xStart + "%"} 
                                                y1={lineHeight} 
                                                x2={xStart + "%"} 
                                                y2="29%" 
                                                strokeWidth="1" 
                                                style={{ stroke: color }} />

                                            <rect 
                                                key={index + "_rect"}
                                                x={xStart + "%"} 
                                                y={rectPos} 
                                                rx="3" 
                                                ry="3" 
                                                width={(widthEnd - xStart) + "%"} 
                                                height={rectHeight} 
                                                style={{ fill: color, fillOpacity: "0.5" }} 
                                            />
                                        </g>
                                    )
                                } else {
                                    return null;
                                }
                            })}

                            {/* marks at the bottom border */}
                            <line x1="5%" y1="98%" x2="5%" y2="75%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="10%" y1="98%" x2="10%" y2="75%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="15%" y1="98%" x2="15%" y2="75%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="20%" y1="98%" x2="20%" y2="75%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="25%" y1="98%" x2="25%" y2="60%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="30%" y1="98%" x2="30%" y2="75%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="35%" y1="98%" x2="35%" y2="75%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="40%" y1="98%" x2="40%" y2="75%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="45%" y1="98%" x2="45%" y2="75%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="50%" y1="98%" x2="50%" y2="50%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="55%" y1="98%" x2="55%" y2="75%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="60%" y1="98%" x2="60%" y2="75%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="65%" y1="98%" x2="65%" y2="75%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="70%" y1="98%" x2="70%" y2="75%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="75%" y1="98%" x2="75%" y2="60%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="80%" y1="98%" x2="80%" y2="75%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="85%" y1="98%" x2="85%" y2="75%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="90%" y1="98%" x2="90%" y2="75%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            <line x1="95%" y1="98%" x2="95%" y2="75%" strokeWidth="1" style={{ stroke: "rgba(0, 0, 0, .2)" }} />
                            
                        </svg>

                        <text className="startText" x="0" y="95%" fill="rgba(0, 0, 0, 0.65)" style={{ userSelect: "none", cursor: "default" }}>
                            {dayjs.unix(startTime).format("YYYY-MM-DD HH:mm:ss")}
                        </text>

                        <text className="middleText" x="50%" textAnchor="middle" y="95%" fill="rgba(0, 0, 0, 0.65)" style={{ userSelect: "none", cursor: "default" }}>
                            {dayjs.unix((((endTime - startTime)/100) * 50) + startTime).format("YYYY-MM-DD HH:mm:ss")}
                        </text> 

                        <text className="endText" x="100%" textAnchor="end" y="95%" fill="rgba(0, 0, 0, 0.65)" style={{ userSelect: "none", cursor: "default" }}>
                            {dayjs.unix(endTime).format("YYYY-MM-DD HH:mm:ss")}
                        </text>
                    </svg>
                )
            }
        } 
    }
} 

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Timeline));